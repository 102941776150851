import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { gql, useMutation, Reference, StoreObject } from '@apollo/client';
import { useForm, SubmitHandler, Controller, DefaultValues } from 'react-hook-form';

import { ADD_FLATFEE, EDIT_FLATFEE, DEL_FLATFEE } from '../queries/resources';
import { Resources_resources_flatFees as FlatFee } from '../graph-types/Resources';
import { FlatFeeInput } from '../graph-types/globalTypes';
import { AddFlatFee, AddFlatFeeVariables } from '../graph-types/AddFlatFee';
import { UpdateFlatFee, UpdateFlatFeeVariables } from '../graph-types/UpdateFlatFee';
import { DeleteFlatFee, DeleteFlatFeeVariables } from '../graph-types/DeleteFlatFee';

import { Resources_resources as Resources } from '../graph-types/Resources';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: 'auto',
    },
  })
);

type FormValues = {
  name: string;
  fee: number;
  type: number;
};

const getFlatFeeData = (id: string, data: FormValues): FlatFeeInput => {
  return {
    id,
    name: data.name,
    fee: Number(data.fee),
    type: Number(data.type),
  };
};

type TypeOfSubmit = 'Save' | 'Delete';

const useFlatFeeForm = (flatFee: FlatFee | null, defaultValues: DefaultValues<FormValues>, resources: Resources) => {
  const form = useForm<FormValues>({ defaultValues });

  const [deleteFlatFee] = useMutation<DeleteFlatFee, DeleteFlatFeeVariables>(DEL_FLATFEE, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((resources as unknown) as StoreObject),
        fields: {
          flatFees(existingFlatFees = [], { readField }) {
            if (!data) {
              return existingFlatFees;
            }

            return existingFlatFees.filter(
              (flatFeeRef: Reference) => data.deleteFlatFee !== readField('id', flatFeeRef)
            );
          },
        },
      });
    },
  });

  const [addFlatFee] = useMutation<AddFlatFee, AddFlatFeeVariables>(ADD_FLATFEE, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((resources as unknown) as StoreObject),
        fields: {
          flatFees(existingFlatFees = []) {
            if (!data) {
              return existingFlatFees;
            }

            const flatFeeData = getFlatFeeData(data.addFlatFee.id, form.getValues());
            const newFlatFeeRef = cache.writeFragment({
              data: {
                ...flatFeeData,
                __typename: 'FlatFee',
              },
              fragment: gql`
                fragment NewFlatFee on FlatFee {
                  id
                  name
                  fee
                  type
                }
              `,
            });
            return [...existingFlatFees, newFlatFeeRef];
          },
        },
      });
    },
  });

  const [editFlatFee] = useMutation<UpdateFlatFee, UpdateFlatFeeVariables>(EDIT_FLATFEE);

  const onSubmit = (typeOfSubmit: TypeOfSubmit, cb: () => void): SubmitHandler<FormValues> => async (data) => {
    switch (typeOfSubmit) {
      case 'Save': {
        if (flatFee) {
          await editFlatFee({
            variables: {
              flatFee: getFlatFeeData(flatFee.id, data),
            },
          });
        } else {
          await addFlatFee({
            variables: {
              flatFee: getFlatFeeData('', data),
            },
          });
        }
        break;
      }
      case 'Delete': {
        if (flatFee) {
          await deleteFlatFee({ variables: { flatFeeId: flatFee.id } });
        }
        break;
      }
    }

    cb();
  };

  return {
    onSubmit: (typeOfSubmit: TypeOfSubmit, cb: () => void) => form.handleSubmit(onSubmit(typeOfSubmit, cb)),
    ...form,
  };
};

const getDefaultValues = (flatFee: FlatFee | null): DefaultValues<FormValues> => {
  const defaultValues: DefaultValues<FormValues> = {
    name: flatFee?.name ?? '',
    fee: flatFee?.fee ?? 0,
    type: flatFee?.type ?? 1,
  };

  return defaultValues;
};

export const FlatFeeDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  flatFee: FlatFee | null;
  resources: Resources;
}> = ({ handleClose, flatFee, open, resources }) => {
  const classes = useStyles();

  const { control, register, formState, onSubmit, reset } = useFlatFeeForm(
    flatFee,
    getDefaultValues(flatFee),
    resources
  );

  const { errors } = formState;

  useEffect(() => {
    reset(getDefaultValues(flatFee));
  }, [reset, flatFee, open]);

  const onClose = (): void => {
    handleClose();
    reset(getDefaultValues(null));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <form
        onSubmit={onSubmit('Save', () => {
          handleClose();
        })}
      >
        <DialogTitle id="form-dialog-title">Crear Item</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            id="name"
            label="Nombre"
            type="text"
            variant="outlined"
            inputProps={register('name', { required: true })}
            error={!!errors.name}
          />
          <TextField
            fullWidth
            margin="dense"
            id="fee"
            label="Valor"
            type="text"
            variant="outlined"
            inputProps={register('fee', { required: true })}
            error={!!errors.fee}
          />
          <FormControl margin="dense" variant="outlined" fullWidth>
            <InputLabel id="flatFee-label">Tipo</InputLabel>
            <Controller
              name="type"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  labelId="flatFee-label"
                  id="flatFee"
                  value={field.value}
                  onChange={field.onChange}
                  label="Tipo"
                  error={!!errors.type}
                >
                  <MenuItem value={1}>Horas</MenuItem>
                  <MenuItem value={2}>Gastos</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          {flatFee && (
            <Button
              classes={classes}
              onClick={() =>
                onSubmit('Delete', () => {
                  handleClose();
                })()
              }
              color="secondary"
              disabled={formState.isSubmitting}
            >
              Borrar
            </Button>
          )}
          <Button onClick={onClose} color="primary" disabled={formState.isSubmitting}>
            Cerrar
          </Button>
          <Button type="submit" color="primary" disabled={formState.isSubmitting}>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
