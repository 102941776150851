export const adjustForUTCOffset = (date: Date): Date => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const getDateInUTC = (date: string): Date => {
  const newDate = adjustForUTCOffset(new Date(Number(date)));
  newDate.setHours(12, 0, 0, 0);
  return newDate;
};
