import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { gql, useMutation, Reference, StoreObject } from '@apollo/client';
import { useForm, SubmitHandler, DefaultValues } from 'react-hook-form';

import { ADD_ADMINISTRATOR, EDIT_ADMINISTRATOR, DEL_ADMINISTRATOR } from '../queries/resources';
import { Resources_resources_administrators as Administrator } from '../graph-types/Resources';
import { AdministratorInput } from '../graph-types/globalTypes';
import { AddAdministrator, AddAdministratorVariables } from '../graph-types/AddAdministrator';
import { UpdateAdministrator, UpdateAdministratorVariables } from '../graph-types/UpdateAdministrator';
import { DeleteAdministrator, DeleteAdministratorVariables } from '../graph-types/DeleteAdministrator';

import { Resources_resources as Resources } from '../graph-types/Resources';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: 'auto',
    },
  })
);

type FormValues = {
  companyName: string;
  agente: string;
  postalAddress1: string;
  postalAddress2: string;
  town: string;
  country: string;
  zipcode: string;
};

const getAdministratorData = (id: string, data: FormValues): AdministratorInput => {
  return {
    id,
    companyName: data.companyName,
    agente: data.agente,
    postalAddress1: data.postalAddress1,
    postalAddress2: data.postalAddress2,
    town: data.town,
    country: data.country,
    zipcode: data.zipcode,
  };
};

type TypeOfSubmit = 'Save' | 'Delete';

const useAdministratorForm = (
  administrator: Administrator | null,
  defaultValues: DefaultValues<FormValues>,
  resources: Resources
) => {
  const form = useForm<FormValues>({ defaultValues });

  const [deleteAdministrator] = useMutation<DeleteAdministrator, DeleteAdministratorVariables>(DEL_ADMINISTRATOR, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((resources as unknown) as StoreObject),
        fields: {
          administrators(existingAdministrators = [], { readField }) {
            if (!data) {
              return existingAdministrators;
            }

            return existingAdministrators.filter(
              (administratorRef: Reference) => data.deleteAdministrator !== readField('id', administratorRef)
            );
          },
        },
      });
    },
  });

  const [addAdministrator] = useMutation<AddAdministrator, AddAdministratorVariables>(ADD_ADMINISTRATOR, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((resources as unknown) as StoreObject),
        fields: {
          administrators(existingAdministrators = []) {
            if (!data) {
              return existingAdministrators;
            }

            const administratorData = getAdministratorData(data.addAdministrator.id, form.getValues());
            const newAdministratorRef = cache.writeFragment({
              data: {
                ...administratorData,
                __typename: 'Administrator',
              },
              fragment: gql`
                fragment NewAdmin on Administrator {
                  id
                  companyName
                  agente
                  postalAddress1
                  postalAddress2
                  town
                  country
                  zipcode
                }
              `,
            });
            return [...existingAdministrators, newAdministratorRef];
          },
        },
      });
    },
  });

  const [editAdministrator] = useMutation<UpdateAdministrator, UpdateAdministratorVariables>(EDIT_ADMINISTRATOR);

  const onSubmit = (typeOfSubmit: TypeOfSubmit, cb: () => void): SubmitHandler<FormValues> => async (data) => {
    switch (typeOfSubmit) {
      case 'Save': {
        if (administrator) {
          await editAdministrator({
            variables: {
              administrator: getAdministratorData(administrator.id, data),
            },
          });
        } else {
          await addAdministrator({
            variables: {
              administrator: getAdministratorData('', data),
            },
          });
        }
        break;
      }
      case 'Delete': {
        if (administrator) {
          await deleteAdministrator({ variables: { administratorId: administrator.id } });
        }
        break;
      }
    }

    cb();
  };

  return {
    onSubmit: (typeOfSubmit: TypeOfSubmit, cb: () => void) => form.handleSubmit(onSubmit(typeOfSubmit, cb)),
    ...form,
  };
};

const getDefaultValues = (administrator: Administrator | null): DefaultValues<FormValues> => {
  const defaultValues: DefaultValues<FormValues> = {
    companyName: administrator?.companyName ?? '',
    agente: administrator?.agente ?? '',
    postalAddress1: administrator?.postalAddress1 ?? '',
    postalAddress2: administrator?.postalAddress2 ?? '',
    town: administrator?.town ?? '',
    country: administrator?.country ?? '',
    zipcode: administrator?.zipcode ?? '',
  };

  return defaultValues;
};

export const AdministratorDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  administrator: Administrator | null;
  resources: Resources;
}> = ({ handleClose, administrator, open, resources }) => {
  const classes = useStyles();

  const { register, formState, onSubmit, reset } = useAdministratorForm(
    administrator,
    getDefaultValues(administrator),
    resources
  );

  const { errors } = formState;

  useEffect(() => {
    reset(getDefaultValues(administrator));
  }, [reset, administrator, open]);

  const onClose = (): void => {
    handleClose();
    reset(getDefaultValues(null));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <form
        onSubmit={onSubmit('Save', () => {
          handleClose();
        })}
      >
        <DialogTitle id="form-dialog-title">Crear Administrador</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            id="administrator"
            label="Nombre"
            type="text"
            variant="outlined"
            inputProps={register('companyName', { required: true })}
            error={!!errors.companyName}
          />
          <TextField
            fullWidth
            margin="dense"
            id="agente"
            label="Agente"
            type="text"
            variant="outlined"
            inputProps={register('agente')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="postalAddress1"
            label="Dirección Postal 1"
            type="text"
            variant="outlined"
            inputProps={register('postalAddress1')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="postalAddress2"
            label="Dirección Postal 2"
            type="text"
            variant="outlined"
            inputProps={register('postalAddress2')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="town"
            label="Pueblo"
            type="text"
            variant="outlined"
            inputProps={register('town')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="country"
            label="País"
            type="text"
            variant="outlined"
            inputProps={register('country')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="zipcode"
            label="Zipcode"
            type="text"
            variant="outlined"
            inputProps={register('zipcode')}
          />
        </DialogContent>
        <DialogActions>
          {administrator && (
            <Button
              classes={classes}
              onClick={() =>
                onSubmit('Delete', () => {
                  handleClose();
                })()
              }
              color="secondary"
              disabled={formState.isSubmitting}
            >
              Borrar
            </Button>
          )}
          <Button onClick={onClose} color="primary" disabled={formState.isSubmitting}>
            Cerrar
          </Button>
          <Button type="submit" color="primary" disabled={formState.isSubmitting}>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
