import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { gql, useMutation, Reference, StoreObject } from '@apollo/client';
import { useForm, SubmitHandler, Controller, DefaultValues } from 'react-hook-form';

import { AdministratorAutocomplete } from './administrator-autocomplete';

import { ADD_RESIDENTIAL, EDIT_RESIDENTIAL, DEL_RESIDENTIAL } from '../queries/resources';
import {
  Resources_resources_residentials as Residential,
  Resources_resources_residentials_administrator as Administrator,
} from '../graph-types/Resources';
import { ResidentialInput } from '../graph-types/globalTypes';
import { AddResidential, AddResidentialVariables } from '../graph-types/AddResidential';
import { UpdateResidential, UpdateResidentialVariables } from '../graph-types/UpdateResidential';
import { DeleteResidential, DeleteResidentialVariables } from '../graph-types/DeleteResidential';

import { Resources_resources as Resources } from '../graph-types/Resources';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: 'auto',
    },
  })
);

type FormValues = {
  administrator: Administrator | null;
  residential: string;
  zipcode: string;
  tribunal: string;
  telContacto2: string;
  telContacto1: string;
  nombreAdministrador: string;
  municipio: string;
  email: string;
  direccionPostalResidencial: string;
  direccionFisicaResidencial: string;
};

const getResidentialData = (id: string, data: FormValues): ResidentialInput => {
  return {
    id,
    administratorId: data.administrator?.id ?? '-1',
    residential: data.residential,
    zipcode: data.zipcode,
    tribunal: data.tribunal,
    telContacto1: data.telContacto1,
    telContacto2: data.telContacto2,
    nombreAdministrador: data.nombreAdministrador,
    municipio: data.municipio,
    email: data.email,
    direccionPostalResidencial: data.direccionPostalResidencial,
    direccionFisicaResidencial: data.direccionFisicaResidencial,
  };
};

type TypeOfSubmit = 'Save' | 'Delete';

const useResidentialForm = (
  residential: Residential | null,
  defaultValues: DefaultValues<FormValues>,
  resources: Resources
) => {
  const form = useForm<FormValues>({ defaultValues });

  const [deleteResidential] = useMutation<DeleteResidential, DeleteResidentialVariables>(DEL_RESIDENTIAL, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((resources as unknown) as StoreObject),
        fields: {
          residentials(existingResidentials = [], { readField }) {
            if (!data) {
              return existingResidentials;
            }

            return existingResidentials.filter(
              (residentialRef: Reference) => data.deleteResidential !== readField('id', residentialRef)
            );
          },
        },
      });
    },
  });

  const [addResidential] = useMutation<AddResidential, AddResidentialVariables>(ADD_RESIDENTIAL, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((resources as unknown) as StoreObject),
        fields: {
          residentials(existingResidentials = []) {
            if (!data) {
              return existingResidentials;
            }

            const residentialData = getResidentialData(data.addResidential.id, form.getValues());
            const newResidentialRef = cache.writeFragment({
              data: {
                ...residentialData,
                __typename: 'Residential',
              },
              fragment: gql`
                fragment NewRes on Residential {
                  id
                  administrator {
                    id
                  }
                  residential
                  zipcode
                  tribunal
                  telContacto2
                  telContacto1
                  nombreAdministrador
                  municipio
                  email
                  direccionPostalResidencial
                  direccionFisicaResidencial
                }
              `,
            });
            return [...existingResidentials, newResidentialRef];
          },
        },
      });
    },
  });

  const [editResidential] = useMutation<UpdateResidential, UpdateResidentialVariables>(EDIT_RESIDENTIAL);

  const onSubmit = (typeOfSubmit: TypeOfSubmit, cb: () => void): SubmitHandler<FormValues> => async (data) => {
    switch (typeOfSubmit) {
      case 'Save': {
        if (residential) {
          await editResidential({
            variables: {
              residential: getResidentialData(residential.id, data),
            },
          });
        } else {
          await addResidential({
            variables: {
              residential: getResidentialData('', data),
            },
          });
        }
        break;
      }
      case 'Delete': {
        if (residential) {
          await deleteResidential({ variables: { residentialId: residential.id } });
        }
        break;
      }
    }

    cb();
  };

  return {
    onSubmit: (typeOfSubmit: TypeOfSubmit, cb: () => void) => form.handleSubmit(onSubmit(typeOfSubmit, cb)),
    ...form,
  };
};

const getDefaultValues = (residential: Residential | null): DefaultValues<FormValues> => {
  const defaultValues: DefaultValues<FormValues> = {
    residential: residential?.residential,
    zipcode: residential?.zipcode ?? '',
    tribunal: residential?.tribunal ?? '',
    telContacto1: residential?.telContacto1 ?? '',
    telContacto2: residential?.telContacto2 ?? '',
    nombreAdministrador: residential?.nombreAdministrador ?? '',
    municipio: residential?.municipio ?? '',
    email: residential?.email ?? '',
    direccionPostalResidencial: residential?.direccionPostalResidencial ?? '',
    direccionFisicaResidencial: residential?.direccionFisicaResidencial ?? '',
    administrator: residential?.administrator,
  };

  return defaultValues;
};

export const ResidentialDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  residential: Residential | null;
  resources: Resources;
}> = ({ handleClose, residential, open, resources }) => {
  const classes = useStyles();

  const { control, register, formState, onSubmit, reset } = useResidentialForm(
    residential,
    getDefaultValues(residential),
    resources
  );

  const { errors } = formState;

  useEffect(() => {
    reset(getDefaultValues(residential));
  }, [reset, residential, open]);

  const onClose = (): void => {
    handleClose();
    reset(getDefaultValues(null));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <form
        onSubmit={onSubmit('Save', () => {
          handleClose();
        })}
      >
        <DialogTitle id="form-dialog-title">Crear Residencial</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            id="residential"
            label="Nombre"
            type="text"
            variant="outlined"
            inputProps={register('residential', { required: true })}
            error={!!errors.residential}
          />
          <TextField
            fullWidth
            margin="dense"
            id="zipcode"
            label="Zipcode"
            type="text"
            variant="outlined"
            inputProps={register('zipcode')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="tribunal"
            label="Tribunal"
            type="text"
            variant="outlined"
            inputProps={register('tribunal')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="telContacto1"
            label="Teléfono Conctacto 1"
            type="text"
            variant="outlined"
            inputProps={register('telContacto1')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="telContacto2"
            label="Teléfono Conctacto 2"
            type="text"
            variant="outlined"
            inputProps={register('telContacto2')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="nombreAdministrador"
            label="Nombre de Administrador"
            type="text"
            variant="outlined"
            inputProps={register('nombreAdministrador')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="municipio"
            label="Municipio"
            type="text"
            variant="outlined"
            inputProps={register('municipio')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="email"
            label="Email"
            type="text"
            variant="outlined"
            inputProps={register('email')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="direccionPostalResidencial"
            label="Dirección Postal Residencial"
            type="text"
            variant="outlined"
            inputProps={register('direccionPostalResidencial')}
          />
          <TextField
            fullWidth
            margin="dense"
            id="direccionFisicaResidencial"
            label="Dirección Física Residencial"
            type="text"
            variant="outlined"
            inputProps={register('direccionFisicaResidencial')}
          />
          <Controller
            name="administrator"
            control={control}
            render={({ field }) => (
              <AdministratorAutocomplete
                value={field.value ?? null}
                onSelect={(value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          {residential && (
            <Button
              classes={classes}
              onClick={() =>
                onSubmit('Delete', () => {
                  handleClose();
                })()
              }
              color="secondary"
              disabled={formState.isSubmitting}
            >
              Borrar
            </Button>
          )}
          <Button onClick={onClose} color="primary" disabled={formState.isSubmitting}>
            Cerrar
          </Button>
          <Button type="submit" color="primary" disabled={formState.isSubmitting}>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
