import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useFormContext, useWatch, Controller } from 'react-hook-form';

import { AdministratorAutocomplete } from './administrator-autocomplete';
import { ResidentialAutocomplete } from './residential-autocomplete';
import { CausalAutocomplete } from './causal-autocomplete';

export const CaseEvictionForm: React.FC = () => {
  const { register, control, clearErrors, setError, formState } = useFormContext();
  const administrator = useWatch({
    control,
    name: 'administrator',
  });

  const { errors } = formState;

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="administrator"
              control={control}
              render={({ field }) => (
                <AdministratorAutocomplete
                  value={field.value ?? null}
                  onSelect={(value) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="residential"
              control={control}
              render={({ field }) => (
                <ResidentialAutocomplete
                  administratorId={administrator?.id ?? ''}
                  value={field.value ?? null}
                  onSelect={(value) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="causal"
              control={control}
              render={({ field }) => (
                <CausalAutocomplete
                  value={field.value ?? null}
                  onSelect={(value) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField inputProps={register('edificio')} label="Edificio" margin="dense" variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={register('apartamento')}
              label="Apartamento"
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={register('rentaMensual')}
              label="Renta Mensual"
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={register('mesesAdeudados')}
              label="Meses Adeusados"
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              inputProps={register('deudaRenta')}
              label="Deuda Renta"
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              inputProps={register('deudaRentaNegativa')}
              label="Deuda EIV"
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              inputProps={register('deudaRecargo')}
              label="Deuda Recargo"
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              inputProps={register('deudaTotal')}
              label="Deuda Total"
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="ultimoReexamen"
              control={control}
              render={({ field, fieldState: { isDirty } }) => (
                <KeyboardDatePicker
                  fullWidth
                  margin="dense"
                  id="ultimoReexamen"
                  label="Último Re-examen"
                  inputVariant="outlined"
                  name="ultimoReexamen"
                  format="dd/MM/yyyy"
                  InputLabelProps={{ shrink: true }}
                  onChange={(value) => {
                    field.onChange(value);
                    clearErrors('ultimoReexamen');
                  }}
                  value={field.value ?? null}
                  invalidDateMessage="Formato de fecha incorrecto"
                  error={(formState.isSubmitted || isDirty) && !!errors.ultimoReexamen}
                  onError={(error) => {
                    if (Boolean(error) && (!errors.ultimoReexamen || errors.ultimoReexamen.type !== 'datepicker')) {
                      setError('ultimoReexamen', { type: 'datepicker' });
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="lanzamiento"
              control={control}
              render={({ field, fieldState: { isDirty } }) => (
                <KeyboardDatePicker
                  fullWidth
                  margin="dense"
                  id="lanzamiento"
                  label="Lanzamiento"
                  inputVariant="outlined"
                  name="lanzamiento"
                  format="dd/MM/yyyy"
                  InputLabelProps={{ shrink: true }}
                  onChange={(value) => {
                    field.onChange(value);
                    clearErrors('lanzamiento');
                  }}
                  value={field.value ?? null}
                  invalidDateMessage="Formato de fecha incorrecto"
                  error={(formState.isSubmitted || isDirty) && !!errors.lanzamiento}
                  onError={(error) => {
                    if (Boolean(error) && (!errors.lanzamiento || errors.lanzamiento.type !== 'datepicker')) {
                      setError('lanzamiento', { type: 'datepicker' });
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputProps={register('incumplimiento')}
              label="Incumplimiento"
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};
