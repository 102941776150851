import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { gql, useMutation, Reference, StoreObject, Cache } from '@apollo/client';
import { useForm, SubmitHandler, DefaultValues } from 'react-hook-form';

import { ClientInput } from '../graph-types/globalTypes';
import { AddClient, AddClientVariables, AddClient_addClient as Client } from '../graph-types/AddClient';
import { DeleteClient, DeleteClientVariables } from '../graph-types/DeleteClient';
import { Case_case as Case } from '../graph-types/Case';
import { UpdateClient, UpdateClientVariables } from '../graph-types/UpdateClient';

const ADD_CLIENT = gql`
  mutation AddClient($client: ClientInput!) {
    addClient(client: $client) {
      id
      name
      nombreContacto
      telefonoContacto
      emailContacto
      direccionFisicaContacto
      direccionPostalContacto
      nombreContactoCuentasAPagar
      telefonoContactoCuentasAPagar
      emailContactoCuentasAPagar
      qbId
      qbUpdate
    }
  }
`;

const EDIT_CLIENT = gql`
  mutation UpdateClient($client: ClientInput!) {
    updateClient(client: $client) {
      id
      name
      nombreContacto
      telefonoContacto
      emailContacto
      direccionFisicaContacto
      direccionPostalContacto
      nombreContactoCuentasAPagar
      telefonoContactoCuentasAPagar
      emailContactoCuentasAPagar
      qbId
      qbUpdate
    }
  }
`;

const DEL_CLIENT = gql`
  mutation DeleteClient($clientId: String!) {
    deleteClient(id: $clientId)
  }
`;

type FormValues = {
  name: string;
  nombreContacto: string;
  telefonoContacto: string;
  emailContacto: string;
  direccionFisicaContacto: string;
  direccionPostalContacto: string;
  nombreContactoCuentasAPagar: string;
  telefonoContactoCuentasAPagar: string;
  emailContactoCuentasAPagar: string;
};

const getClientInput = (id: string, data: FormValues): ClientInput => {
  return {
    id,
    ...data,
  };
};

type TypeOfSubmit = 'Save' | 'Delete';

const useClientForm = (client: Client | null, caso: Case | null, defaultValues: DefaultValues<FormValues>) => {
  const form = useForm<FormValues>({ defaultValues });

  const [deleteClient] = useMutation<DeleteClient, DeleteClientVariables>(DEL_CLIENT, {
    update: (cache, { data }) => {
      const modifier: Cache.ModifyOptions = {
        fields: {
          clients(existingClients = [], { readField }) {
            if (!data) {
              return existingClients;
            }

            return existingClients.filter((clientRef: Reference) => data.deleteClient !== readField('id', clientRef));
          },
        },
      };

      cache.modify(modifier);
    },
  });

  const [addClient] = useMutation<AddClient, AddClientVariables>(ADD_CLIENT, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          clients(existingClients = []) {
            if (!data) {
              return existingClients;
            }

            const clientData = getClientInput(data.addClient.id, form.getValues());
            const newClientRef = cache.writeFragment({
              data: {
                ...clientData,
                __typename: 'Client',
              },
              fragment: gql`
                fragment NewClient on Client {
                  id
                  name
                  nombreContacto
                  telefonoContacto
                  emailContacto
                  direccionFisicaContacto
                  direccionPostalContacto
                  nombreContactoCuentasAPagar
                  telefonoContactoCuentasAPagar
                  emailContactoCuentasAPagar
                }
              `,
            });
            return [...existingClients, newClientRef];
          },
        },
      });
    },
  });

  const [editClient] = useMutation<UpdateClient, UpdateClientVariables>(EDIT_CLIENT, {
    update: (cache, { data }) => {
      if (!caso) {
        return;
      }
      cache.modify({
        id: cache.identify((caso as unknown) as StoreObject),
        fields: {
          invoices(existingClients = []) {
            if (!data) {
              return existingClients;
            }

            const clientData = getClientInput(data.updateClient.id, form.getValues());
            const newClientRef = cache.writeFragment({
              data: {
                ...clientData,
                __typename: 'Client',
              },
              fragment: gql`
                fragment NewClient on Client {
                  id
                  name
                  nombreContacto
                  telefonoContacto
                  emailContacto
                  direccionFisicaContacto
                  direccionPostalContacto
                  nombreContactoCuentasAPagar
                  telefonoContactoCuentasAPagar
                  emailContactoCuentasAPagar
                }
              `,
            });
            return [...existingClients, newClientRef];
          },
        },
      });
    },
  });

  const onSubmit = (
    typeOfSubmit: TypeOfSubmit,
    success: () => void,
    error: (e: unknown) => void
  ): SubmitHandler<FormValues> => async (data) => {
    try {
      switch (typeOfSubmit) {
        case 'Save': {
          if (client) {
            await editClient({
              variables: {
                client: getClientInput(client.id, data),
              },
            });
          } else {
            await addClient({
              variables: {
                client: getClientInput('', data),
              },
            });
          }
          break;
        }
        case 'Delete': {
          if (client) {
            await deleteClient({ variables: { clientId: client.id } });
          }
          break;
        }
      }
      success();
    } catch (e) {
      error(e);
    }
  };

  return {
    onSubmit: (typeOfSubmit: TypeOfSubmit, success: () => void, error: (e: unknown) => void) =>
      form.handleSubmit(onSubmit(typeOfSubmit, success, error)),
    ...form,
  };
};

const getDefaultValues = (client: Client | null): DefaultValues<FormValues> => {
  const defaultValues: DefaultValues<FormValues> = {
    name: client?.name ?? '',
    nombreContacto: client?.nombreContacto ?? '',
    telefonoContacto: client?.telefonoContacto ?? '',
    emailContacto: client?.emailContacto ?? '',
    direccionFisicaContacto: client?.direccionFisicaContacto ?? '',
    direccionPostalContacto: client?.direccionPostalContacto ?? '',
    nombreContactoCuentasAPagar: client?.nombreContactoCuentasAPagar ?? '',
    telefonoContactoCuentasAPagar: client?.telefonoContactoCuentasAPagar ?? '',
    emailContactoCuentasAPagar: client?.emailContactoCuentasAPagar ?? '',
  };

  return defaultValues;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: 'auto',
    },
  })
);

export const ClientDialog: React.FC<{
  open: boolean;
  handleClose: (msg: string | null) => void;
  client: Client | null;
  caso: Case | null;
}> = ({ open, handleClose, client, caso }) => {
  const classes = useStyles();

  const { register, formState, onSubmit, reset } = useClientForm(client, caso, getDefaultValues(client));

  const { errors } = formState;

  useEffect(() => {
    reset(getDefaultValues(client));
  }, [reset, client, open]);

  const onClose = (): void => {
    handleClose(null);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <form
        onSubmit={onSubmit(
          'Save',
          () => {
            handleClose('Cliente guardado!');
            reset(getDefaultValues(null));
          },
          (e) => {
            console.log(e);
          }
        )}
      >
        <DialogTitle id="form-dialog-title">Cliente</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="Nombre de Cliente"
            type="text"
            variant="outlined"
            inputProps={register('name', { required: true })}
            error={!!errors.name}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Contacto"
            type="text"
            variant="outlined"
            inputProps={register('nombreContacto')}
          />
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="dense"
                label="Teléfono de Contacto"
                type="text"
                variant="outlined"
                inputProps={register('telefonoContacto')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="dense"
                label="Email de Contacto"
                type="text"
                variant="outlined"
                inputProps={register('emailContacto')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <TextField
            fullWidth
            multiline
            margin="dense"
            label="Dirección Fisica Contacto"
            type="text"
            variant="outlined"
            inputProps={register('direccionFisicaContacto')}
          />
          <TextField
            fullWidth
            multiline
            margin="dense"
            label="Dirección Postal Contacto"
            type="text"
            variant="outlined"
            inputProps={register('direccionPostalContacto')}
          />
          <TextField
            fullWidth
            multiline
            margin="dense"
            label="Contacto de Cuentas a Pagar"
            type="text"
            variant="outlined"
            inputProps={register('nombreContactoCuentasAPagar')}
          />
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                multiline
                margin="dense"
                label="Teléfono de Cuentas a Pagar"
                type="text"
                variant="outlined"
                inputProps={register('telefonoContactoCuentasAPagar')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                multiline
                margin="dense"
                label="Email de Cuentas a Pagar"
                type="text"
                variant="outlined"
                inputProps={register('emailContactoCuentasAPagar')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {client && (
            <Button
              classes={classes}
              onClick={() =>
                onSubmit(
                  'Delete',
                  () => {
                    handleClose('Cliente borrado!');
                  },
                  (e) => {
                    console.log(e);
                  }
                )()
              }
              color="secondary"
              disabled={formState.isSubmitting}
            >
              Borrar
            </Button>
          )}
          <Button onClick={onClose} color="primary" disabled={formState.isSubmitting}>
            Cerrar
          </Button>
          <Button type="submit" color="primary" disabled={formState.isSubmitting}>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
