function onNewServiceWorker(registration, callback) {
  if (registration.waiting) {
    // SW is waiting to activate. Can occur if multiple clients open and
    // one of the clients is refreshed.
    return callback();
  }

  function listenInstalledStateChange() {
    registration.installing.addEventListener('statechange', (event) => {
      if (event.target.state === 'installed') {
        // A new service worker is available, inform the user
        callback();
      }
    });
  }

  if (registration.installing) {
    return listenInstalledStateChange();
  }

  // We are currently controlled so a new SW may be found...
  // Add a listener in case a new SW is found,
  registration.addEventListener('updatefound', listenInstalledStateChange);
  return null;
}

// Checks for updates, and also updates page
const registerSuccess = (registration) =>
  new Promise((resolve) => {
    setInterval(() => {
      // Check for updates every hour
      console.log('Checking for update');
      registration.update();
    }, 60 * 60 * 1000);

    document.addEventListener(
      'visibilitychange',
      () => {
        if (!document.hidden) {
          registration.update();
        }
      },
      false
    );

    if (!navigator.serviceWorker.controller) {
      // The window client isn't currently controlled so it's a new service
      // worker that will activate immediately
      return;
    }

    let refreshing;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // This fires when the service worker controlling this page
      // changes, eg a new worker has skipped waiting and become
      // the new active worker.
      if (refreshing) return;
      refreshing = true;
      window.location.reload();
    });

    onNewServiceWorker(registration, () => {
      resolve(registration);
    });

    registration.update();
  });

const registerFail = (event) => {
  console.error('Error registrating:', event);
};

const register = () =>
  navigator.serviceWorker.register(new URL('/sw.js', import.meta.url), { scope: '/' }).then(registerSuccess).catch(registerFail);

export const showRefreshUI = (registration) => {
  if (window.location.host === 'localhost:8000') {
    return;
  }

  const button = document.createElement('div');
  button.style.position = 'fixed';
  button.style.bottom = '24px';
  button.style.left = '24px';
  button.style.zIndex = '100';
  button.style.backgroundColor = '#0F5971';
  button.style.padding = '15px';
  button.style.color = 'white';
  button.style.borderRadius = '4px';
  button.style.maxWidth = '100vw';
  button.textContent = 'Presione aquí para actualizar a la última version de JurisOpus';

  button.addEventListener('click', () => {
    if (!registration.waiting) {
      // Just to ensure registration.waiting is available before
      // calling postMessage()
      return;
    }

    console.log('send skip waiting');
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  });

  const root = document.getElementById('banner-root') || document.body;
  root.appendChild(button);
};

export const registerSW = () => {
  if ('serviceWorker' in navigator) {
    return register();
  }

  // Never settle
  return new Promise(() => {
    //
  });
};
