import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useFormContext, Controller } from 'react-hook-form';

export const CaseCobroForm: React.FC = () => {
  const { register, control, clearErrors, setError, formState } = useFormContext();

  const { errors } = formState;

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="ultimoPago"
              control={control}
              render={({ field, fieldState: { isDirty } }) => (
                <KeyboardDatePicker
                  fullWidth
                  margin="dense"
                  id="ultimoPago"
                  label="Último Pago"
                  inputVariant="outlined"
                  name="ultimoPago"
                  format="dd/MM/yyyy"
                  InputLabelProps={{ shrink: true }}
                  onChange={(value) => {
                    value?.setHours(0, 0, 0, 0);
                    field.onChange(value);
                    clearErrors('ultimoPago');
                  }}
                  value={field.value ?? null}
                  invalidDateMessage="Formato de fecha incorrecto"
                  error={(formState.isSubmitted || isDirty) && !!errors.ultimoPago}
                  onError={(error) => {
                    if (Boolean(error) && (!errors.ultimoPago || errors.ultimoPago.type !== 'datepicker')) {
                      setError('ultimoPago', { type: 'datepicker' });
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="contrato"
              control={control}
              render={({ field, fieldState: { isDirty } }) => (
                <KeyboardDatePicker
                  fullWidth
                  margin="dense"
                  id="Contrato"
                  label="Contrato"
                  inputVariant="outlined"
                  name="contrato"
                  format="dd/MM/yyyy"
                  InputLabelProps={{ shrink: true }}
                  onChange={(value) => {
                    value?.setHours(0, 0, 0, 0);
                    field.onChange(value);
                    clearErrors('Contrato');
                  }}
                  value={field.value ?? null}
                  invalidDateMessage="Formato de fecha incorrecto"
                  error={(formState.isSubmitted || isDirty) && !!errors.Contrato}
                  onError={(error) => {
                    if (Boolean(error) && (!errors.Contrato || errors.Contrato.type !== 'datepicker')) {
                      setError('Contrato', { type: 'datepicker' });
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              inputProps={register('montoAdeudado')}
              label="Monto Adeudado"
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              inputProps={register('interesesAcumulados')}
              label="Intereses Acumulados"
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              inputProps={register('penalidadesAcumuladas')}
              label="Penalidades Acumuladas"
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              inputProps={register('otrasCantidades')}
              label="Otras Cantidades"
              margin="dense"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};
