import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DataGrid, GridRowsProp, GridColDef, GridColTypeDef, GridValueFormatterParams } from '@material-ui/data-grid';
import Linkify from 'linkifyjs/react';

import { Case_case as Case, Case_case_notes as CaseNote } from '../graph-types/Case';
import { CaseNoteDialog } from './case-note-dialog';

type Props = {
  caso: Case;
  notes: CaseNote[];
};

const useStyles = makeStyles((theme) =>
  createStyles({
    tooltipLink: {
      color: '#62d3ff',
    },
  })
);

const spDateTime: GridColTypeDef = {
  type: 'string',
  valueFormatter: ({ value }) =>
    new Intl.DateTimeFormat('es-ES', {
      hour12: true,
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(Number(value))),
};

const withTooltip = (className: string): GridColTypeDef => ({
  renderCell: (params: GridValueFormatterParams) => {
    return (
      <Tooltip
        interactive
        title={
          <Typography variant="subtitle1">
            <Linkify options={{ target: { url: '_blank' }, className }}>{params.value || ''}</Linkify>
          </Typography>
        }
      >
        <Typography noWrap>
          <Linkify options={{ target: { url: '_blank' } }}>{params.value || ''}</Linkify>
        </Typography>
      </Tooltip>
    );
  },
});

export const CaseNotes: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [selectedNote, setSelectedNote] = useState<CaseNote | null>(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const rows: GridRowsProp =
    props.notes.map((note) => ({
      id: note.id,
      col1: note.note,
      col2: note.updatedAt,
      col3: note.createdAt,
    })) ?? [];

  const noteWidth = matches ? { flex: 1 } : { width: 500 };

  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Nota', ...noteWidth, ...withTooltip(classes.tooltipLink) },
    { field: 'col2', headerName: 'Actualizado', width: 160, ...spDateTime },
    { field: 'col3', headerName: 'Creado', width: 160, ...spDateTime },
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        pageSize={50}
        onRowClick={(params): void => {
          const note = props.notes.find((note) => note.id === params.row.id);
          setSelectedNote(note ?? null);
        }}
      />
      <CaseNoteDialog
        note={selectedNote}
        caso={props.caso}
        open={selectedNote != null}
        handleClose={(msg) => setSelectedNote(null)}
      />
    </>
  );
};
