import React, { useEffect, useState } from 'react';
import { sortBy, omit } from 'lodash';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { gql, useQuery, useMutation, Reference, StoreObject, Cache } from '@apollo/client';
import { useForm, SubmitHandler, Controller, DefaultValues } from 'react-hook-form';

import { InvoiceInput } from '../graph-types/globalTypes';
import { AddInvoice, AddInvoiceVariables } from '../graph-types/AddInvoice';
import { UpdateInvoice, UpdateInvoiceVariables } from '../graph-types/UpdateInvoice';
import { DeleteInvoice, DeleteInvoiceVariables } from '../graph-types/DeleteInvoice';
import {
  AddInvoice_addInvoice_case as Case,
  AddInvoice_addInvoice as InvoiceWithCase,
} from '../graph-types/AddInvoice';
import { Case_case_invoices as InvoiceNoCase } from '../graph-types/Case';
import { FlatFees } from '../graph-types/FlatFees';
import { Employees } from '../graph-types/Employees';
import { PrefilledInvoiceNotes } from '../graph-types/PrefilledInvoiceNotes';
import { LegalCodes, LegalCodes_legalCodes as LegalCode, LegalCodesVariables } from '../graph-types/LegalCodes';
import { CaseAutocomplete } from './case-autocomplete';

type Invoice = InvoiceNoCase | InvoiceWithCase;

const GET_FLAT_FEES = gql`
  query FlatFees {
    flatFees {
      id
      name
      fee
      type
    }
  }
`;
const GET_EMPLOYEES = gql`
  query Employees {
    employees {
      id
      name
    }
  }
`;
const GET_PREFILLED_NOTES = gql`
  query PrefilledInvoiceNotes {
    prefilledInvoiceNotes {
      id
      note
    }
  }
`;

const GET_LEGAL_CODES = gql`
  query LegalCodes($type: String!) {
    legalCodes(type: $type) {
      id
      code
      description
    }
  }
`;

const ADD_INVOICE = gql`
  mutation AddInvoice($invoice: InvoiceInput!) {
    addInvoice(invoice: $invoice) {
      id
      case {
        id
        matter
        client {
          name
        }
        group {
          name
        }
      }
      dateOfEvent
      isExported
      note
      unit
      employee {
        id
        name
      }
      flatFee {
        id
        name
        fee
        type
      }
      taskCode {
        id
        code
        description
      }
      activityCode {
        id
        code
        description
      }
      expenseCode {
        id
        code
        description
      }
    }
  }
`;

const EDIT_INVOICE = gql`
  mutation UpdateInvoice($invoice: InvoiceInput!) {
    updateInvoice(invoice: $invoice) {
      id
      case {
        id
      }
      dateOfEvent
      isExported
      note
      unit
      employee {
        id
        name
      }
      flatFee {
        id
        name
        fee
        type
      }
      taskCode {
        id
        code
        description
      }
      activityCode {
        id
        code
        description
      }
      expenseCode {
        id
        code
        description
      }
    }
  }
`;

const DEL_INVOICE = gql`
  mutation DeleteInvoice($invoiceId: String!) {
    deleteInvoice(id: $invoiceId)
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: 'auto',
    },
  })
);

type FormValues = {
  case: Case | null;
  dateOfEvent: Date;
  isExported: boolean;
  note: string;
  unit: number;
  employeeId: string;
  flatFeeId: string;
  taskCode: LegalCode | null;
  activityCode: LegalCode | null;
  expenseCode: LegalCode | null;
};

const getInvoiceData = (id: string, data: FormValues): InvoiceInput => {
  return {
    id,
    caseId: data.case?.id || '',
    dateOfEvent: String(data.dateOfEvent.getTime()),
    isExported: data.isExported,
    note: data.note,
    unit: Number(data.unit),
    employeeId: data.employeeId,
    flatFeeId: data.flatFeeId,
    taskCode: data.taskCode ? omit(data.taskCode, '__typename') : null,
    activityCode: data.activityCode ? omit(data.activityCode, '__typename') : null,
    expenseCode: data.expenseCode ? omit(data.expenseCode, '__typename') : null,
  };
};

type TypeOfSubmit = 'Save' | 'Delete';

const useInvoiceForm = (invoice: Invoice | null, caso: Case | null, defaultValues: DefaultValues<FormValues>) => {
  const form = useForm<FormValues>({ defaultValues });

  const [deleteInvoice] = useMutation<DeleteInvoice, DeleteInvoiceVariables>(DEL_INVOICE, {
    update: (cache, { data }) => {
      const modifier: Cache.ModifyOptions = {
        fields: {
          invoices(existingInvoices = [], { readField }) {
            if (!data) {
              return existingInvoices;
            }

            return existingInvoices.filter(
              (invoiceRef: Reference) => data.deleteInvoice !== readField('id', invoiceRef)
            );
          },
        },
      };

      if (caso) {
        modifier.id = cache.identify((caso as unknown) as StoreObject);
      }

      cache.modify(modifier);
    },
  });

  const [addInvoice] = useMutation<AddInvoice, AddInvoiceVariables>(ADD_INVOICE, {
    update: (cache, { data }) => {
      if (caso) {
        cache.modify({
          id: cache.identify((caso as unknown) as StoreObject),
          fields: {
            invoices(existingInvoices = []) {
              if (!data) {
                return existingInvoices;
              }

              const invoiceData = getInvoiceData(data.addInvoice.id, form.getValues());
              const newInvoiceRef = cache.writeFragment({
                data: {
                  ...invoiceData,
                  __typename: 'Invoice',
                },
                fragment: gql`
                  fragment NewInvoice on Invoice {
                    id
                    dateOfEvent
                    isExported
                    note
                    unit
                    employee {
                      id
                    }
                    flatFee {
                      id
                    }
                    taskCode {
                      id
                    }
                    activityCode {
                      id
                    }
                    expenseCode {
                      id
                    }
                  }
                `,
              });
              return [...existingInvoices, newInvoiceRef];
            },
          },
        });
      } else {
        cache.modify({
          fields: {
            invoices(existingInvoices = []) {
              if (!data) {
                return existingInvoices;
              }

              const invoiceData = getInvoiceData(data.addInvoice.id, form.getValues());
              const newInvoiceRef = cache.writeFragment({
                data: {
                  ...invoiceData,
                  case: {
                    id: invoiceData.caseId,
                  },
                  __typename: 'Invoice',
                },
                fragment: gql`
                  fragment NewInvoice on Invoice {
                    id
                    case {
                      id
                    }
                    dateOfEvent
                    isExported
                    note
                    unit
                    employee {
                      id
                    }
                    flatFee {
                      id
                    }
                    taskCode {
                      id
                    }
                    activityCode {
                      id
                    }
                    expenseCode {
                      id
                    }
                  }
                `,
              });
              return [...existingInvoices, newInvoiceRef];
            },
          },
        });
      }
    },
  });

  const [editInvoice] = useMutation<UpdateInvoice, UpdateInvoiceVariables>(EDIT_INVOICE, {
    update: (cache, { data }) => {
      if (caso) {
        return;
      }

      cache.modify({
        fields: {
          invoices(existingInvoices = [], { readField }) {
            if (!data) {
              return existingInvoices;
            }

            // If we changed isExported it means its not part of this list
            if (data.updateInvoice.isExported === invoice?.isExported) {
              return existingInvoices;
            }

            return existingInvoices.filter(
              (invoiceRef: Reference) => data.updateInvoice.id !== readField('id', invoiceRef)
            );
          },
        },
      });
    },
  });

  const onSubmit = (typeOfSubmit: TypeOfSubmit, cb: () => void): SubmitHandler<FormValues> => async (data) => {
    switch (typeOfSubmit) {
      case 'Save': {
        if (invoice) {
          await editInvoice({
            variables: {
              invoice: getInvoiceData(invoice.id, data),
            },
          });
        } else {
          await addInvoice({
            variables: {
              invoice: getInvoiceData('', data),
            },
          });
        }
        break;
      }
      case 'Delete': {
        if (invoice) {
          await deleteInvoice({ variables: { invoiceId: invoice.id } });
        }
        break;
      }
    }

    cb();
  };

  return {
    onSubmit: (typeOfSubmit: TypeOfSubmit, cb: () => void) => form.handleSubmit(onSubmit(typeOfSubmit, cb)),
    ...form,
  };
};

const getCase = (invoice: Invoice | null, caso: Case | null): Case | null => {
  if (caso) {
    return caso;
  }

  if (!invoice) {
    return null;
  }

  if ('case' in invoice) {
    return invoice.case;
  }

  return null;
};

const getDefaultValues = (invoice: Invoice | null, caso: Case | null): DefaultValues<FormValues> => {
  const defaultValues: DefaultValues<FormValues> = {
    case: getCase(invoice, caso),
    dateOfEvent: invoice?.dateOfEvent ? new Date(Number(invoice.dateOfEvent)) : new Date(),
    isExported: invoice?.isExported ?? false,
    note: invoice?.note ?? '',
    unit: invoice?.unit ?? 0,
    employeeId: invoice?.employee.id ?? '1',
    flatFeeId: invoice?.flatFee.id ?? '',
    taskCode: invoice?.taskCode,
    activityCode: invoice?.activityCode,
    expenseCode: invoice?.expenseCode,
  };

  return defaultValues;
};

export const InvoiceDialog: React.FC<{
  open: boolean;
  handleClose: (msg: string | null, keepOpen: boolean) => void;
  invoice: Invoice | null;
  caso: Case | null;
}> = ({ open, handleClose, invoice, caso }) => {
  const classes = useStyles();
  const [isKeepOpen, setKeepOpen] = useState(false);

  const { data: flatFeeData } = useQuery<FlatFees>(GET_FLAT_FEES);
  const { data: employeeData } = useQuery<Employees>(GET_EMPLOYEES);
  const { data: prefilledNotesData } = useQuery<PrefilledInvoiceNotes>(GET_PREFILLED_NOTES);
  const { data: taskCodesData } = useQuery<LegalCodes, LegalCodesVariables>(GET_LEGAL_CODES, {
    variables: { type: 'task' },
  });
  const { data: activityCodesData } = useQuery<LegalCodes, LegalCodesVariables>(GET_LEGAL_CODES, {
    variables: { type: 'activity' },
  });
  const { data: expenseCodesData } = useQuery<LegalCodes, LegalCodesVariables>(GET_LEGAL_CODES, {
    variables: { type: 'expense' },
  });

  const { control, register, formState, onSubmit, clearErrors, setError, reset, setValue } = useInvoiceForm(
    invoice,
    caso,
    getDefaultValues(invoice, caso)
  );

  const { errors } = formState;

  useEffect(() => {
    reset(getDefaultValues(invoice, caso));
  }, [reset, invoice, caso]);

  const onClose = (): void => {
    handleClose(null, false);
    reset(getDefaultValues(null, caso));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
        <form
          onSubmit={onSubmit('Save', () => {
            handleClose('Facturación guardada!', isKeepOpen);
            setKeepOpen(false);
            reset(getDefaultValues(null, caso));
          })}
        >
          <DialogTitle id="form-dialog-title">Crear Facturación</DialogTitle>
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Controller
                  name="flatFeeId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl margin="dense" variant="outlined" fullWidth>
                      <InputLabel id="flatFee-label">Item</InputLabel>
                      <Select
                        labelId="flatFee-label"
                        id="flatFee"
                        value={field.value}
                        onChange={field.onChange}
                        label="Item"
                        error={!!errors.flatFeeId}
                      >
                        {sortBy(flatFeeData?.flatFees ?? [], 'name').map((flatFee) => (
                          <MenuItem key={flatFee.id} value={flatFee.id}>
                            {flatFee.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="dense"
                  id="unit"
                  label="Tiempo"
                  type="number"
                  variant="outlined"
                  inputProps={{ ...register('unit', { required: true }), step: '0.01' }}
                  error={!!errors.unit}
                />
              </Grid>
            </Grid>
            <Controller
              name="dateOfEvent"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { isDirty } }) => (
                <KeyboardDatePicker
                  fullWidth
                  margin="dense"
                  id="dateOfEvent"
                  label="Fecha de Evento"
                  inputVariant="outlined"
                  name="dateOfEvent"
                  format="dd/MM/yyyy"
                  InputLabelProps={{ shrink: true }}
                  onChange={(value) => {
                    value?.setHours(0, 0, 0, 0);
                    field.onChange(value);
                    clearErrors('dateOfEvent');
                  }}
                  value={field.value ?? null}
                  invalidDateMessage="Formato de fecha incorrecto"
                  error={(formState.isSubmitted || isDirty) && !!errors.dateOfEvent}
                  onError={(error) => {
                    if (Boolean(error) && (!errors.dateOfEvent || errors.dateOfEvent.type !== 'datepicker')) {
                      setError('dateOfEvent', { type: 'datepicker' });
                    }
                  }}
                />
              )}
            />
            {!caso && (
              <Controller
                name="case"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CaseAutocomplete
                    value={field.value ?? null}
                    onSelect={(value) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
            )}
            <Controller
              name="employeeId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl margin="dense" variant="outlined" fullWidth>
                  <InputLabel id="employee-label">Empleado</InputLabel>
                  <Select
                    labelId="employee-label"
                    id="employee"
                    value={field.value}
                    onChange={field.onChange}
                    label="Empleado"
                    error={!!errors.employeeId}
                  >
                    {(employeeData?.employees ?? []).map((employee) => (
                      <MenuItem key={employee.id} value={employee.id}>
                        {employee.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Controller
                  name="taskCode"
                  control={control}
                  render={({ field }) => (
                    <FormControl margin="dense" variant="outlined" fullWidth>
                      <InputLabel id="task-code-label">Task Code</InputLabel>
                      <Select
                        labelId="task-code-label"
                        id="task-code"
                        label="Task Code"
                        value={field.value?.id ?? ''}
                        onChange={(e) => {
                          const legalCode = taskCodesData?.legalCodes.find(
                            (legalCode) => legalCode.id === (e.target.value as string)
                          );
                          field.onChange(legalCode ?? null);
                        }}
                      >
                        {(taskCodesData?.legalCodes ?? []).map((legalCode) => (
                          <MenuItem key={legalCode.id} value={legalCode.id}>
                            {legalCode.code} - {legalCode.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="activityCode"
                  control={control}
                  render={({ field }) => (
                    <FormControl margin="dense" variant="outlined" fullWidth>
                      <InputLabel id="activity-code-label">Activity Code</InputLabel>
                      <Select
                        labelId="activity-code-label"
                        id="activity-code"
                        label="Activity Code"
                        value={field.value?.id ?? ''}
                        onChange={(e) => {
                          const legalCode = activityCodesData?.legalCodes.find(
                            (legalCode) => legalCode.id === (e.target.value as string)
                          );
                          field.onChange(legalCode ?? null);
                        }}
                      >
                        {(activityCodesData?.legalCodes ?? []).map((legalCode) => (
                          <MenuItem key={legalCode.id} value={legalCode.id}>
                            {legalCode.code} - {legalCode.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Controller
              name="expenseCode"
              control={control}
              render={({ field }) => (
                <FormControl margin="dense" variant="outlined" fullWidth>
                  <InputLabel id="expense-code-label">Expense Code</InputLabel>
                  <Select
                    labelId="expense-code-label"
                    id="expense-code"
                    label="Expense Code"
                    value={field.value?.id ?? ''}
                    onChange={(e) => {
                      const legalCode = expenseCodesData?.legalCodes.find(
                        (legalCode) => legalCode.id === (e.target.value as string)
                      );
                      field.onChange(legalCode ?? null);
                    }}
                  >
                    {(expenseCodesData?.legalCodes ?? []).map((legalCode) => (
                      <MenuItem key={legalCode.id} value={legalCode.id}>
                        {legalCode.code} - {legalCode.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <FormControl margin="dense" variant="outlined" fullWidth>
              <InputLabel id="prefilled-label">Nota Modelo</InputLabel>
              <Select
                labelId="prefilled-label"
                id="prefilled"
                label="Nota Modelo"
                value=""
                onChange={(e) => setValue('note', e.target.value as string)}
              >
                {(prefilledNotesData?.prefilledInvoiceNotes ?? []).map((prefilled) => (
                  <MenuItem key={prefilled.id} value={prefilled.note}>
                    {prefilled.note}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              multiline
              margin="dense"
              id="note"
              label="Nota"
              type="text"
              variant="outlined"
              inputProps={register('note', { required: true })}
              error={!!errors.note}
            />
            <Controller
              name="isExported"
              control={control}
              render={({ field }) => (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                    label="Archivado?"
                  />
                </FormGroup>
              )}
            />
          </DialogContent>
          <DialogActions>
            {invoice && (
              <Button
                classes={classes}
                onClick={() =>
                  onSubmit('Delete', () => {
                    handleClose('Facturación borrada!', false);
                    setKeepOpen(false);
                    reset(getDefaultValues(null, caso));
                  })()
                }
                color="secondary"
                disabled={formState.isSubmitting}
              >
                Borrar
              </Button>
            )}
            <Button onClick={onClose} color="primary" disabled={formState.isSubmitting}>
              Cerrar
            </Button>
            <Button
              type="submit"
              onClick={() => {
                setKeepOpen(true);
              }}
              color="primary"
              disabled={formState.isSubmitting}
            >
              Guardar y Añadir Otro
            </Button>
            <Button type="submit" color="primary" disabled={formState.isSubmitting}>
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};
