import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { gql, useLazyQuery } from '@apollo/client';

import { Clients, Clients_clients as Client } from '../graph-types/Clients';

const GET_CLIENTS = gql`
  query Clients {
    clients {
      id
      name
    }
  }
`;

export { Client };

type Props = {
  value: Client | null;
  onSelect: (option: Client | null) => void;
};

export const ClientAutocomplete: React.FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [getClients, { loading, data, called }] = useLazyQuery<Clients>(GET_CLIENTS);

  const options = data?.clients ?? [];

  useEffect(() => {
    if (open && !called) {
      getClients();
    }
  }, [open, called, getClients]);

  return (
    <Autocomplete
      id="clients-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, newValue) => {
        props.onSelect(newValue);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        return option.name;
      }}
      options={props.value ? [props.value, ...options] : options}
      loading={loading}
      value={props.value}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          margin="dense"
          label="Cliente"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
