import { gql } from '@apollo/client';

export const GET_CASES = gql`
  query Cases($isComplete: Boolean, $dateFilter: String) {
    cases(isComplete: $isComplete, dateFilter: $dateFilter) {
      id
      caseNumber
      matter
      isComplete

      group {
        id
        name
      }

      client {
        id
        name
      }
    }
  }
`;

export const GET_CASE = gql`
  query Case($caseId: String!) {
    case(caseId: $caseId) {
      id
      caseNumber
      matter
      typeOfRepresentationId
      tribunalId
      qbId
      qbUpdate

      primeraVistaDate
      segundaVistaDate
      terceraVistaDate
      juicioDate
      verdictDate
      referralDate
      seguimientoDate
      vencimientoDate
      radicacionDemandaDate
      diligenciamentoDate
      reconsideracionDate
      apelacionDate
      expiracionContestacionADemandaDate
      emplazadoDate
      conferenciaAntelacionJuicioDate
      conferenciaStatusDate
      deposicion
      vistaInformeManejoCaso
      levantarActa
      reunionAbogados
      reunionCliente
      completedDate
      ultimoReexamen
      lanzamiento
      ultimoPago
      contrato

      jobName
      asignadoA
      court
      citationRoom
      salon
      juez
      salaExt
      diligenciadoEn
      ultimaMocionEnRecord
      causeOfAction

      demandadoTelefono
      demandadoEmail
      demandadoDireccionPostal
      demandadoDireccionFisica

      otroAbogadoName
      otroAbogadoTelefono
      otroAbogadoEmail
      otroAbogadoDireccion
      nombreContacto2
      telefonoContacto2
      emailContacto2

      edificio
      apartamento
      incumplimiento
      mesesAdeudados
      rentaMensual
      deudaRenta
      deudaRentaNegativa
      deudaRecargo
      deudaTotal

      montoAdeudado
      interesesAcumulados
      penalidadesAcumuladas
      otrasCantidades

      isContestoDemanda
      isReconvencion
      isReplicaReconvencion
      isDescubrimientoNotificado
      isBillable
      isDesisted
      isProcessed
      isFiled
      isExpedienteEntregado
      isDoneInformeManejoCaso
      isComplete
      isInformetPT
      isEdicto
      isRebeldia
      isEjecucion

      isDescubrimientoDmnte
      isDescubrimientoDmnteContestado
      isDescubrimientoDmnda
      isDescubrimientoDmndaContestado

      typesOfCase

      group {
        id
        name
      }

      client {
        id
        name
        nombreContacto
        telefonoContacto
        emailContacto
        direccionFisicaContacto
        direccionPostalContacto
        nombreContactoCuentasAPagar
        telefonoContactoCuentasAPagar
        emailContactoCuentasAPagar
        qbId
        qbUpdate
      }

      administrator {
        id
        companyName
      }

      residential {
        id
        residential
      }

      causal {
        id
        causal
      }

      notes {
        id
        note
        createdAt
        updatedAt
      }

      correspondences {
        id
        recipient
        asunto
        item
        trackingNumber
        email
        dateAdded
        isArchived
        typeOfCorrespondenceId
      }

      invoices {
        id
        dateOfEvent
        isExported
        note
        unit
        qbId
        qbUpdate
        employee {
          id
          name
        }
        flatFee {
          id
          name
          fee
          type
        }
        taskCode {
          id
          code
          description
        }
        activityCode {
          id
          code
          description
        }
        expenseCode {
          id
          code
          description
        }
      }
    }
  }
`;

export const EDIT_CASE = gql`
  mutation UpdateCase($case: CaseInput!) {
    updateCase(case: $case) {
      id
      caseNumber
      matter
      typeOfRepresentationId
      tribunalId
      qbId
      qbUpdate

      primeraVistaDate
      segundaVistaDate
      terceraVistaDate
      juicioDate
      verdictDate
      referralDate
      seguimientoDate
      vencimientoDate
      radicacionDemandaDate
      diligenciamentoDate
      reconsideracionDate
      apelacionDate
      expiracionContestacionADemandaDate
      emplazadoDate
      conferenciaAntelacionJuicioDate
      conferenciaStatusDate
      deposicion
      vistaInformeManejoCaso
      levantarActa
      reunionAbogados
      reunionCliente
      completedDate
      ultimoReexamen
      lanzamiento
      ultimoPago
      contrato

      jobName
      asignadoA
      court
      citationRoom
      salon
      juez
      salaExt
      diligenciadoEn
      ultimaMocionEnRecord
      causeOfAction

      demandadoTelefono
      demandadoEmail
      demandadoDireccionPostal
      demandadoDireccionFisica

      otroAbogadoName
      otroAbogadoTelefono
      otroAbogadoEmail
      otroAbogadoDireccion
      nombreContacto2
      telefonoContacto2
      emailContacto2

      edificio
      apartamento
      incumplimiento
      mesesAdeudados
      rentaMensual
      deudaRenta
      deudaRentaNegativa
      deudaRecargo
      deudaTotal

      montoAdeudado
      interesesAcumulados
      penalidadesAcumuladas
      otrasCantidades

      isContestoDemanda
      isReconvencion
      isReplicaReconvencion
      isDescubrimientoNotificado
      isBillable
      isDesisted
      isProcessed
      isFiled
      isExpedienteEntregado
      isDoneInformeManejoCaso
      isComplete
      isInformetPT
      isEdicto
      isRebeldia
      isEjecucion

      isDescubrimientoDmnte
      isDescubrimientoDmnteContestado
      isDescubrimientoDmnda
      isDescubrimientoDmndaContestado

      typesOfCase

      group {
        id
        name
      }

      client {
        id
        name
        nombreContacto
        telefonoContacto
        emailContacto
        direccionFisicaContacto
        direccionPostalContacto
        nombreContactoCuentasAPagar
        telefonoContactoCuentasAPagar
        emailContactoCuentasAPagar
        qbId
        qbUpdate
      }

      administrator {
        id
        companyName
      }

      residential {
        id
        residential
      }

      causal {
        id
        causal
      }
    }
  }
`;

export const ADD_CASE = gql`
  mutation AddCase($case: CaseInput!) {
    addCase(case: $case) {
      id
      caseNumber
      matter
      typeOfRepresentationId
      tribunalId
      qbId
      qbUpdate

      primeraVistaDate
      segundaVistaDate
      terceraVistaDate
      juicioDate
      verdictDate
      referralDate
      seguimientoDate
      vencimientoDate
      radicacionDemandaDate
      diligenciamentoDate
      reconsideracionDate
      apelacionDate
      expiracionContestacionADemandaDate
      emplazadoDate
      conferenciaAntelacionJuicioDate
      conferenciaStatusDate
      deposicion
      vistaInformeManejoCaso
      levantarActa
      reunionAbogados
      reunionCliente
      completedDate
      ultimoReexamen
      lanzamiento
      ultimoPago
      contrato

      jobName
      asignadoA
      court
      citationRoom
      salon
      juez
      salaExt
      diligenciadoEn
      ultimaMocionEnRecord
      causeOfAction

      demandadoTelefono
      demandadoEmail
      demandadoDireccionPostal
      demandadoDireccionFisica

      otroAbogadoName
      otroAbogadoTelefono
      otroAbogadoEmail
      otroAbogadoDireccion
      nombreContacto2
      telefonoContacto2
      emailContacto2

      edificio
      apartamento
      incumplimiento
      mesesAdeudados
      rentaMensual
      deudaRenta
      deudaRentaNegativa
      deudaRecargo
      deudaTotal

      montoAdeudado
      interesesAcumulados
      penalidadesAcumuladas
      otrasCantidades

      isContestoDemanda
      isReconvencion
      isReplicaReconvencion
      isDescubrimientoNotificado
      isBillable
      isDesisted
      isProcessed
      isFiled
      isExpedienteEntregado
      isDoneInformeManejoCaso
      isComplete
      isInformetPT
      isEdicto
      isRebeldia
      isEjecucion

      isDescubrimientoDmnte
      isDescubrimientoDmnteContestado
      isDescubrimientoDmnda
      isDescubrimientoDmndaContestado

      typesOfCase

      group {
        id
        name
      }

      client {
        id
        name
        nombreContacto
        telefonoContacto
        emailContacto
        direccionFisicaContacto
        direccionPostalContacto
        nombreContactoCuentasAPagar
        telefonoContactoCuentasAPagar
        emailContactoCuentasAPagar
        qbId
        qbUpdate
      }

      administrator {
        id
        companyName
      }

      residential {
        id
        residential
      }

      causal {
        id
        causal
      }
    }
  }
`;

export const DEL_CASE = gql`
  mutation DeleteCase($id: String!) {
    deleteCase(id: $id)
  }
`;

export const DOWNLOAD_CASE = gql`
  query DownloadCase($ids: [String!]!) {
    downloadCase(ids: $ids)
  }
`;

export const DOWNLOAD_CASE_DEMANDA = gql`
  query DownloadCaseDemanda($ids: [String!]!) {
    downloadCaseDemanda(ids: $ids)
  }
`;
