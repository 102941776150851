import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { gql, useLazyQuery } from '@apollo/client';

import { Administrators, Administrators_administrators as Administrator } from '../graph-types/Administrators';

const GET_ADMINISTRATORS = gql`
  query Administrators {
    administrators {
      id
      companyName
    }
  }
`;

export { Administrator };

type Props = {
  value: Administrator | null;
  onSelect: (option: Administrator | null) => void;
};

export const AdministratorAutocomplete: React.FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [getAdministrators, { loading, data, called }] = useLazyQuery<Administrators>(GET_ADMINISTRATORS);

  const options = data?.administrators ?? [];

  useEffect(() => {
    if (open && !called) {
      getAdministrators();
    }
  }, [open, called, getAdministrators]);

  return (
    <Autocomplete
      id="administrators-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, newValue) => {
        props.onSelect(newValue);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        return option.companyName ?? '';
      }}
      options={props.value ? [props.value, ...options] : options}
      loading={loading}
      value={props.value}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          margin="dense"
          label="Administrator"
          variant="outlined"
          name="administrator-autocomplete"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
