const downloadXLSX = (label: string) => (data: any) => {
  const a = window.document.createElement('a');
  const hdr = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
  a.href = `${hdr}${data}`;
  a.download = `${label}-${Date.now()}.xlsx`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const createXLSX = (payload: any) =>
  import('excel-builder/src/excel-builder').then(({ createWorkbook, createFile }) => {
    const workbook = createWorkbook();
    const sheet = workbook.createWorksheet();

    sheet.setData(payload);
    workbook.addWorksheet(sheet);

    return createFile(workbook);
  });

export const downloadContent = (content: any, label = 'casos') => createXLSX(content).then(downloadXLSX(label));
