import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { useFormContext, Controller } from 'react-hook-form';

export const CaseDateForm: React.FC = () => {
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DateController name="referralDate" label="Referido" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateController name="seguimientoDate" label="Seguimiento" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateController name="reunionAbogados" label="Reunión con Abogados" withTime />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateController name="reunionCliente" label="Reunión con Cliente" withTime />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateController name="radicacionDemandaDate" label="Radicación de Demanda" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateController name="levantarActa" label="Levantar Acta" withTime />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateController name="vencimientoDate" label="Vencimiento" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateController name="diligenciamentoDate" label="Diligenciamento Expedido" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateController name="emplazadoDate" label="Emplazado" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateController name="expiracionContestacionADemandaDate" label="Expiración de Contestación a Demanda" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateController name="vistaInformeManejoCaso" label="Vista de Informe de Manejo de Caso" withTime />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateController name="conferenciaAntelacionJuicioDate" label="Conferencia Antelación a Juicio" withTime />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateController name="conferenciaStatusDate" label="Conferencia Status" withTime />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateController name="deposicion" label="Deposición" withTime />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateController name="primeraVistaDate" label="Primera Vista" withTime />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateController name="segundaVistaDate" label="Segunda Vista" withTime />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateController name="terceraVistaDate" label="Tercera Vista" withTime />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateController name="juicioDate" label="Juicio" withTime />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateController name="verdictDate" label="Sentencia" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateController name="reconsideracionDate" label="Reconsideración" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateController name="apelacionDate" label="Apelación" />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};

export const DateController: React.FC<{ name: string; label: string; withTime?: boolean }> = (props) => {
  const { control, formState, clearErrors, setError } = useFormContext();

  const { errors } = formState;

  if (props.withTime) {
    return (
      <Controller
        name={props.name}
        control={control}
        render={({ field, fieldState: { isDirty } }) => (
          <KeyboardDateTimePicker
            fullWidth
            margin="dense"
            id={props.name}
            label={props.label}
            inputVariant="outlined"
            name={props.name}
            format="dd/MM/yyyy hh:mm aaa"
            InputLabelProps={{ shrink: true }}
            onChange={(value) => {
              field.onChange(value);
              clearErrors(props.name);
            }}
            value={field.value ?? null}
            invalidDateMessage="Formato de fecha incorrecto"
            error={(formState.isSubmitted || isDirty) && !!errors[props.name]}
            onError={(error) => {
              if (Boolean(error) && (!errors[props.name] || errors[props.name].type !== 'datepicker')) {
                setError(props.name, { type: 'datepicker' });
              }
            }}
          />
        )}
      />
    );
  }

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field, fieldState: { isDirty } }) => (
        <KeyboardDatePicker
          fullWidth
          margin="dense"
          id={props.name}
          label={props.label}
          inputVariant="outlined"
          name={props.name}
          format="dd/MM/yyyy"
          InputLabelProps={{ shrink: true }}
          onChange={(value) => {
            value?.setHours(12, 0, 0, 0);
            field.onChange(value);
            clearErrors(props.name);
          }}
          value={field.value ?? null}
          invalidDateMessage="Formato de fecha incorrecto"
          error={(formState.isSubmitted || isDirty) && !!errors[props.name]}
          onError={(error) => {
            if (Boolean(error) && (!errors[props.name] || errors[props.name].type !== 'datepicker')) {
              setError(props.name, { type: 'datepicker' });
            }
          }}
        />
      )}
    />
  );
};
