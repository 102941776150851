import React from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import { useFormContext, useWatch, Controller } from 'react-hook-form';

import { ClientAutocomplete } from './client-autocomplete';
import { GroupAutocomplete } from './group-autocomplete';

import { tribunales, supportedCaseTypes, representamosItems } from '../data/case';

export const CaseGeneralForm: React.FC = () => {
  const { register, control } = useFormContext();
  const client = useWatch({
    control,
    name: 'client',
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="client"
            control={control}
            render={({ field }) => (
              <ClientAutocomplete
                value={field.value ?? null}
                onSelect={(value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl margin="dense" variant="outlined" fullWidth>
            <InputLabel id="representation-label">Representamos</InputLabel>
            <Controller
              name="typeOfRepresentationId"
              control={control}
              render={({ field }) => (
                <Select
                  onChange={field.onChange}
                  value={field.value}
                  label="Representamos"
                  labelId="representation-label"
                  id="representation-select"
                >
                  {representamosItems.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="group"
            control={control}
            render={({ field }) => (
              <GroupAutocomplete
                clientId={client?.id ?? ''}
                value={field.value ?? null}
                onSelect={(value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField inputProps={register('matter')} label="Asunto" margin="dense" variant="outlined" fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField inputProps={register('jobName')} label="Job Name" margin="dense" variant="outlined" fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={register('caseNumber')}
            label="Número de Caso"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={register('asignadoA')}
            label="Asignado a"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            inputProps={{ ...register('court'), readOnly: true }}
            label="Tribunal"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl margin="dense" variant="outlined" fullWidth>
            <InputLabel id="tribunal-type-label">Tipo de Tribunal</InputLabel>
            <Controller
              name="tribunalId"
              control={control}
              render={({ field }) => (
                <Select
                  onChange={field.onChange}
                  value={field.value}
                  label="Tipo de Tribunal"
                  labelId="tribunal-type-label"
                  id="tribunal-type-select"
                >
                  {tribunales.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField inputProps={register('citationRoom')} label="Sala" margin="dense" variant="outlined" fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField inputProps={register('salon')} label="Salón" margin="dense" variant="outlined" fullWidth />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField inputProps={register('juez')} label="Juez" margin="dense" variant="outlined" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={register('salaExt')}
            label="Extensión de Sala"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={register('diligenciadoEn')}
            label="Diligenciado en"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={register('ultimaMocionEnRecord')}
            label="Última Moción en Record"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl margin="dense" variant="outlined" fullWidth>
            <InputLabel id="types-case-label">Tipo de Caso</InputLabel>
            <Controller
              name="typesOfCase"
              control={control}
              render={({ field }) => (
                <Select
                  onChange={field.onChange}
                  value={field.value}
                  labelId="types-case-label"
                  label="Tipo de Caso"
                  id="types-case-select"
                  multiple
                  renderValue={(selected) => (
                    <>
                      {(selected as number[]).map((value) => (
                        <Chip key={value} label={supportedCaseTypes.find(({ id }) => id === value)?.name ?? ''} />
                      ))}
                    </>
                  )}
                >
                  {supportedCaseTypes.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            inputProps={register('causeOfAction')}
            label="Resumen"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isBillable"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Facturable?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isDesisted"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Desistido?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isProcessed"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Diligenciado?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isFiled"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Radicada?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isExpedienteEntregado"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Expediente entregado?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isDoneInformeManejoCaso"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Informe de Manejo de Caso?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isContestoDemanda"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Contestó Demanda?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isInformePT"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Informe PT?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isReconvencion"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Reconvención?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isReplicaReconvencion"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Réplica a Reconvención?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isComplete"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Completado?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isEdicto"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Edicto?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isRebeldia"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Rebeldía?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isEjecucion"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Ejecución?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isDescubrimientoNotificado"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Descubrimiento Notificado?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isDescubrimientoDmnte"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Descubrimiento Dmnte? "
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isDescubrimientoDmnteContestado"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Descubrimiento Dmnte Contestado?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isDescubrimientoDmnda"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Descubrimiento Dmnda?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Controller
                  name="isDescubrimientoDmndaContestado"
                  control={control}
                  render={({ field }) => <Switch color="primary" checked={field.value} onChange={field.onChange} />}
                />
              }
              label="Descubrimiento Dmnda Contestado?"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};
