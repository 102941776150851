import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { gql, useMutation, Reference, StoreObject } from '@apollo/client';
import { useForm, SubmitHandler, DefaultValues } from 'react-hook-form';

import { ADD_LEGALCODE, EDIT_LEGALCODE, DEL_LEGALCODE } from '../queries/resources';
import { Resources_resources_taskCodes as LegalCode } from '../graph-types/Resources';
import { LegalCodeInput } from '../graph-types/globalTypes';
import { AddLegalCode, AddLegalCodeVariables } from '../graph-types/AddLegalCode';
import { UpdateLegalCode, UpdateLegalCodeVariables } from '../graph-types/UpdateLegalCode';
import { DeleteLegalCode, DeleteLegalCodeVariables } from '../graph-types/DeleteLegalCode';

import { Resources_resources as Resources } from '../graph-types/Resources';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: 'auto',
    },
  })
);

type FormValues = {
  code: string;
  description: string;
};

const getLegalCodeData = (id: string, data: FormValues): LegalCodeInput => {
  return {
    id,
    code: data.code,
    description: data.description,
  };
};

type CodeType = 'task' | 'activity' | 'expense';

type TypeOfSubmit = 'Save' | 'Delete';

const getFieldOnType = (type: CodeType) => {
  switch (type) {
    case 'task':
      return 'taskCodes';
    case 'activity':
      return 'activityCodes';
    case 'expense':
      return 'expenseCodes';
  }
};

const useLegalCodeForm = (
  type: CodeType,
  legalCode: LegalCode | null,
  defaultValues: DefaultValues<FormValues>,
  resources: Resources
) => {
  const form = useForm<FormValues>({ defaultValues });
  const field = getFieldOnType(type);

  const [deleteLegalCode] = useMutation<DeleteLegalCode, DeleteLegalCodeVariables>(DEL_LEGALCODE, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((resources as unknown) as StoreObject),
        fields: {
          [field](existingLegalCodes = [], { readField }) {
            if (!data) {
              return existingLegalCodes;
            }

            return existingLegalCodes.filter(
              (legalCodeRef: Reference) => data.deleteLegalCode !== readField('id', legalCodeRef)
            );
          },
        },
      });
    },
  });

  const [addLegalCode] = useMutation<AddLegalCode, AddLegalCodeVariables>(ADD_LEGALCODE, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((resources as unknown) as StoreObject),
        fields: {
          [field](existingLegalCodes = []) {
            if (!data) {
              return existingLegalCodes;
            }

            const legalCodeData = getLegalCodeData(data.addLegalCode.id, form.getValues());
            const newLegalCodeRef = cache.writeFragment({
              data: {
                ...legalCodeData,
                __typename: 'LegalCode',
              },
              fragment: gql`
                fragment NewLegalCode on LegalCode {
                  id
                  code
                  description
                }
              `,
            });
            return [...existingLegalCodes, newLegalCodeRef];
          },
        },
      });
    },
  });

  const [editLegalCode] = useMutation<UpdateLegalCode, UpdateLegalCodeVariables>(EDIT_LEGALCODE);

  const onSubmit = (typeOfSubmit: TypeOfSubmit, cb: () => void): SubmitHandler<FormValues> => async (data) => {
    switch (typeOfSubmit) {
      case 'Save': {
        if (legalCode) {
          await editLegalCode({
            variables: {
              type,
              legalCode: getLegalCodeData(legalCode.id, data),
            },
          });
        } else {
          await addLegalCode({
            variables: {
              type,
              legalCode: getLegalCodeData('', data),
            },
          });
        }
        break;
      }
      case 'Delete': {
        if (legalCode) {
          await deleteLegalCode({
            variables: {
              type,
              legalCodeId: legalCode.id,
            },
          });
        }
        break;
      }
    }

    cb();
  };

  return {
    onSubmit: (typeOfSubmit: TypeOfSubmit, cb: () => void) => form.handleSubmit(onSubmit(typeOfSubmit, cb)),
    ...form,
  };
};

const getDefaultValues = (legalCode: LegalCode | null): DefaultValues<FormValues> => {
  const defaultValues: DefaultValues<FormValues> = {
    code: legalCode?.code ?? '',
    description: legalCode?.description ?? '',
  };

  return defaultValues;
};

export const LegalCodeDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  legalCode: LegalCode | null;
  resources: Resources;
  type: CodeType;
}> = ({ handleClose, legalCode, open, resources, type }) => {
  const classes = useStyles();

  const { register, formState, onSubmit, reset } = useLegalCodeForm(
    type,
    legalCode,
    getDefaultValues(legalCode),
    resources
  );

  const { errors } = formState;

  useEffect(() => {
    reset(getDefaultValues(legalCode));
  }, [reset, legalCode, open]);

  const onClose = (): void => {
    handleClose();
    reset(getDefaultValues(null));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <form
        onSubmit={onSubmit('Save', () => {
          handleClose();
        })}
      >
        <DialogTitle id="form-dialog-title">Crear {type} code</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            id="code"
            label="Código"
            type="text"
            variant="outlined"
            inputProps={register('code', { required: true })}
            error={!!errors.code}
          />
          <TextField
            fullWidth
            margin="dense"
            id="desc"
            label="Descripción"
            type="text"
            variant="outlined"
            inputProps={register('description', { required: true })}
            error={!!errors.description}
          />
        </DialogContent>
        <DialogActions>
          {legalCode && (
            <Button
              classes={classes}
              onClick={() =>
                onSubmit('Delete', () => {
                  handleClose();
                })()
              }
              color="secondary"
              disabled={formState.isSubmitting}
            >
              Borrar
            </Button>
          )}
          <Button onClick={onClose} color="primary" disabled={formState.isSubmitting}>
            Cerrar
          </Button>
          <Button type="submit" color="primary" disabled={formState.isSubmitting}>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
