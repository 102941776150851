import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { gql, useLazyQuery } from '@apollo/client';

import {
  Residentials,
  Residentials_residentials as Residential,
  ResidentialsVariables,
} from '../graph-types/Residentials';

const GET_RESIDENTIALS = gql`
  query Residentials($administrator: String!) {
    residentials(administrator: $administrator) {
      id
      residential
    }
  }
`;

export { Residential };

type Props = {
  administratorId: string;
  value: Residential | null;
  onSelect: (option: Residential | null) => void;
};

export const ResidentialAutocomplete: React.FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [getResidentials, { loading, data, called }] = useLazyQuery<Residentials, ResidentialsVariables>(
    GET_RESIDENTIALS,
    {
      variables: { administrator: props.administratorId },
    }
  );

  const options = data?.residentials ?? [];

  useEffect(() => {
    if (open && !called) {
      getResidentials();
    }
  }, [open, called, getResidentials]);

  return (
    <Autocomplete
      id="residentials-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, newValue) => {
        props.onSelect(newValue);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        return option.residential;
      }}
      options={props.value ? [props.value, ...options] : options}
      loading={loading}
      value={props.value}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          margin="dense"
          label="Residencial"
          variant="outlined"
          name="residential-autocomplete"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
