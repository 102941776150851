import { gql } from '@apollo/client';

export const GET_INVOICES = gql`
  query Invoices($archived: Boolean, $monthYear: String, $pageSize: Int, $cursor: String, $caseName: String) {
    invoices(
      archived: $archived
      monthYearDate: $monthYear
      pageSize: $pageSize
      cursor: $cursor
      caseName: $caseName
    ) {
      cursor
      summary {
        total
        horas
        gastos
      }
      rows {
        id
        dateOfEvent
        isExported
        note
        unit
        qbId
        qbUpdate
        employee {
          id
          name
        }
        flatFee {
          id
          name
          fee
          type
        }
        case {
          id
          matter
          group {
            name
          }
          client {
            name
          }
        }
        taskCode {
          id
          code
          description
        }
        activityCode {
          id
          code
          description
        }
        expenseCode {
          id
          code
          description
        }
      }
    }
  }
`;

export const GET_SUMMARIES = gql`
  query GetSummaries($year: String!) {
    summaries(year: $year) {
      month
      total
      horas
      gastos
    }
  }
`;

export const ARCHIVE_INVOICES = gql`
  mutation ArchiveInvoices($ids: [String!]!) {
    archiveInvoices(ids: $ids)
  }
`;

export const DOWNLOAD_INVOICES = gql`
  query DownloadInvoices($ids: [String!]!) {
    downloadInvoices(ids: $ids)
  }
`;
