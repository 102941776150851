import { gql } from '@apollo/client';

export const DASHBOARD = gql`
  query Dashboard($date: String!) {
    dashboard(date: $date) {
      today(date: $date) {
        fields {
          name
          date
        }
        caso {
          id
          caseNumber
          typesOfCase
          matter

          group {
            name
          }

          client {
            name
          }
        }
      }
      tomorrow(date: $date) {
        fields {
          name
          date
        }
        caso {
          id
          caseNumber
          typesOfCase
          matter

          group {
            name
          }

          client {
            name
          }
        }
      }
      nextWeek(date: $date) {
        fields {
          name
          date
        }
        caso {
          id
          caseNumber
          typesOfCase
          matter

          group {
            name
          }

          client {
            name
          }
        }
      }
      invoiceSummary(date: $date) {
        totalFee
        totalHours
        totalGastos
      }
      org {
        name
        logo
      }
    }
  }
`;

export const DASHBOARD_MONTH = gql`
  query DashboardMonth($date: String!) {
    dashboardMonth(date: $date) {
      fields {
        name
        date
      }
      caso {
        id
        caseNumber
        typesOfCase
        matter

        group {
          name
        }

        client {
          name
        }
      }
    }
  }
`;

export const NOTES = gql`
  query Notes($caseId: String!, $pageSize: Int!) {
    notes(caseId: $caseId, pageSize: $pageSize) {
      id
      note
    }
  }
`;
