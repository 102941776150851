import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from '@gatsbyjs/reach-router';
import { fade, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PeopleIcon from '@material-ui/icons/People';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import MoreIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLazyQuery } from '@apollo/client';

import { SEARCH_CASES } from '../queries/search';
import { SearchCases, SearchCasesVariables } from '../graph-types/SearchCases';
import { getCaseName } from '../data/case';

import { CaseDialog } from './case-dialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: '#2a2929',
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      cursor: 'pointer',
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    titleMobile: {
      cursor: 'pointer',
      display: 'block',
      minWidth: 30,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      margin: theme.spacing(1),
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);

export const Navbar: React.FC<{ user: Record<string, string> | null }> = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const [showCaseForm, setCaseForm] = useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const [caseName, setCaseName] = useState<string>('');
  const [getCases, { loading, data }] = useLazyQuery<SearchCases, SearchCasesVariables>(SEARCH_CASES);

  const debouncedSetCaseName = useCallback(debounce(setCaseName, 200), [setCaseName]);

  useEffect(() => {
    if (caseName.length > 2) {
      getCases({ variables: { caseName } });
    }
  }, [caseName, getCases]);

  const options = data?.searchCases ?? [];

  const sortedOptions = sortBy(options, (option) =>
    getCaseName(option.client.name, option.group?.name ?? null, option.matter)
  );

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileNavigate = (route: string): void => {
    setMobileMoreAnchorEl(null);
    navigate(route);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => setCaseForm(true)}>
        <IconButton color="primary">
          <AddBoxIcon />
        </IconButton>
        <p>Crear Caso</p>
      </MenuItem>
      <MenuItem onClick={() => mobileNavigate('/invoicing')}>
        <IconButton color="primary">
          <AccountBalanceIcon />
        </IconButton>
        <p>Facturación</p>
      </MenuItem>
      <MenuItem onClick={() => mobileNavigate('/cases')}>
        <IconButton color="primary">
          <PeopleIcon />
        </IconButton>
        <p>Clientes</p>
      </MenuItem>
      <MenuItem onClick={() => mobileNavigate('/correspondences')}>
        <IconButton color="primary">
          <AllInboxIcon />
        </IconButton>
        <p>Registro</p>
      </MenuItem>
      <MenuItem onClick={() => mobileNavigate('/resources')}>
        <IconButton color="primary">
          <SettingsIcon />
        </IconButton>
        <p>Recursos</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap onClick={() => navigate('/')}>
            JURISOPUS.
          </Typography>
          <Typography className={classes.titleMobile} variant="h6" noWrap onClick={() => navigate('/')}>
            JO.
          </Typography>
          {props.user && (
            <>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <Autocomplete
                  id="cases-autocomplete"
                  fullWidth
                  onChange={(event, newValue) => {
                    if (newValue) {
                      navigate(`/cases/${newValue.id}`);
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    debouncedSetCaseName(newInputValue);
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) =>
                    getCaseName(option.client.name, option.group?.name ?? null, option.matter)
                  }
                  filterOptions={(options, state) => options}
                  options={sortedOptions}
                  loading={loading}
                  renderInput={(params) => (
                    <InputBase
                      ref={params.InputProps.ref}
                      placeholder="Búsqueda…"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={params.inputProps}
                    />
                  )}
                />
              </div>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <IconButton color="inherit" onClick={() => setCaseForm(true)}>
                  <AddBoxIcon />
                </IconButton>
                <IconButton color="inherit" onClick={() => navigate('/invoicing')}>
                  <AccountBalanceIcon />
                </IconButton>
                <IconButton color="inherit" onClick={() => navigate('/cases')}>
                  <PeopleIcon />
                </IconButton>
                <IconButton color="inherit" onClick={() => navigate('/correspondences')}>
                  <AllInboxIcon />
                </IconButton>
                <IconButton color="inherit" onClick={() => navigate('/resources')}>
                  <SettingsIcon />
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton aria-controls={mobileMenuId} onClick={handleMobileMenuOpen} color="inherit">
                  <MoreIcon />
                </IconButton>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <CaseDialog open={showCaseForm} handleClose={() => setCaseForm(false)} />
    </div>
  );
};
