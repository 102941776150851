import { gql } from '@apollo/client';

export const SEARCH_CASES = gql`
  query SearchCases($caseName: String!) {
    searchCases(caseName: $caseName) {
      id
      matter
      client {
        name
      }
      group {
        name
      }
    }
  }
`;
