import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { useFormContext } from 'react-hook-form';

export const CaseDemandadoForm: React.FC = () => {
  const { register } = useFormContext();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={register('demandadoTelefono')}
            label="Teléfono"
            margin="dense"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={register('demandadoEmail')}
            label="E-mail"
            margin="dense"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={register('demandadoDireccionPostal')}
            label="Dirección Postal"
            margin="dense"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={register('demandadoDireccionFisica')}
            label="Dirección Fisica"
            margin="dense"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
          />
        </Grid>
      </Grid>
    </>
  );
};
