import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useForm, SubmitHandler, DefaultValues } from 'react-hook-form';

import { Case_case as Case } from '../graph-types/Case';
import { UpdateCase, UpdateCaseVariables } from '../graph-types/UpdateCase';
import { DeleteCase, DeleteCaseVariables } from '../graph-types/DeleteCase';
import { CaseInput } from '../graph-types/globalTypes';
import { EDIT_CASE, DEL_CASE } from '../queries/case';
import { Client } from '../components/client-autocomplete';
import { Group } from '../components/group-autocomplete';
import { Administrator } from '../components/administrator-autocomplete';
import { Residential } from '../components/residential-autocomplete';
import { Causal } from '../components/causal-autocomplete';
import { getDateInUTC } from '../utils/dates';

type FormValues = {
  id: string;
  caseNumber: string | null;
  matter: string;
  typeOfRepresentationId: number;
  tribunalId: number | null;
  primeraVistaDate: Date | null;
  segundaVistaDate: Date | null;
  terceraVistaDate: Date | null;
  juicioDate: Date | null;
  verdictDate: Date | null;
  referralDate: Date | null;
  seguimientoDate: Date | null;
  vencimientoDate: Date | null;
  radicacionDemandaDate: Date | null;
  diligenciamentoDate: Date | null;
  reconsideracionDate: Date | null;
  apelacionDate: Date | null;
  expiracionContestacionADemandaDate: Date | null;
  emplazadoDate: Date | null;
  conferenciaAntelacionJuicioDate: Date | null;
  conferenciaStatusDate: Date | null;
  deposicion: Date | null;
  vistaInformeManejoCaso: Date | null;
  levantarActa: Date | null;
  reunionAbogados: Date | null;
  reunionCliente: Date | null;
  completedDate: Date | null;
  ultimoReexamen: Date | null;
  lanzamiento: Date | null;
  ultimoPago: Date | null;
  contrato: Date | null;
  jobName: string | null;
  asignadoA: string | null;
  court: string | null;
  citationRoom: string | null;
  salon: string | null;
  juez: string | null;
  salaExt: string | null;
  diligenciadoEn: string | null;
  ultimaMocionEnRecord: string | null;
  causeOfAction: string | null;
  demandadoTelefono: string | null;
  demandadoEmail: string | null;
  demandadoDireccionPostal: string | null;
  demandadoDireccionFisica: string | null;
  otroAbogadoName: string | null;
  otroAbogadoTelefono: string | null;
  otroAbogadoEmail: string | null;
  otroAbogadoDireccion: string | null;
  nombreContacto2: string | null;
  telefonoContacto2: string | null;
  emailContacto2: string | null;
  edificio: string | null;
  apartamento: string | null;
  incumplimiento: string | null;
  mesesAdeudados: number | null;
  rentaMensual: number | null;
  deudaRenta: number | null;
  deudaRentaNegativa: number | null;
  deudaRecargo: number | null;
  deudaTotal: number | null;
  montoAdeudado: number | null;
  interesesAcumulados: number | null;
  penalidadesAcumuladas: number | null;
  otrasCantidades: number | null;
  typesOfCase: number[];
  isContestoDemanda: boolean;
  isReconvencion: boolean;
  isReplicaReconvencion: boolean;
  isDescubrimientoNotificado: boolean;
  isBillable: boolean;
  isDesisted: boolean;
  isProcessed: boolean;
  isFiled: boolean;
  isExpedienteEntregado: boolean;
  isDoneInformeManejoCaso: boolean;
  isInformetPT: boolean;
  isEdicto: boolean;
  isRebeldia: boolean;
  isEjecucion: boolean;
  isComplete: boolean;
  isDescubrimientoDmnte: boolean;
  isDescubrimientoDmnteContestado: boolean;
  isDescubrimientoDmnda: boolean;
  isDescubrimientoDmndaContestado: boolean;
  group: Group | null;
  client: Client;
  administrator: Administrator | null;
  residential: Residential | null;
  causal: Causal | null;
};

const getCaseInput = (caso: Case, data: FormValues): CaseInput => {
  return {
    id: data.id,
    caseNumber: data.caseNumber,
    matter: data.matter,
    typeOfRepresentationId: data.typeOfRepresentationId,
    tribunalId: data.tribunalId,
    primeraVistaDate: data.primeraVistaDate?.getTime().toString() ?? null,
    segundaVistaDate: data.segundaVistaDate?.getTime().toString() ?? null,
    terceraVistaDate: data.terceraVistaDate?.getTime().toString() ?? null,
    juicioDate: data.juicioDate?.getTime().toString() ?? null,
    verdictDate: data.verdictDate?.getTime().toString() ?? null,
    referralDate: data.referralDate?.getTime().toString() ?? null,
    seguimientoDate: data.seguimientoDate?.getTime().toString() ?? null,
    vencimientoDate: data.vencimientoDate?.getTime().toString() ?? null,
    radicacionDemandaDate: data.radicacionDemandaDate?.getTime().toString() ?? null,
    diligenciamentoDate: data.diligenciamentoDate?.getTime().toString() ?? null,
    reconsideracionDate: data.reconsideracionDate?.getTime().toString() ?? null,
    apelacionDate: data.apelacionDate?.getTime().toString() ?? null,
    expiracionContestacionADemandaDate: data.expiracionContestacionADemandaDate?.getTime().toString() ?? null,
    emplazadoDate: data.emplazadoDate?.getTime().toString() ?? null,
    conferenciaAntelacionJuicioDate: data.conferenciaAntelacionJuicioDate?.getTime().toString() ?? null,
    conferenciaStatusDate: data.conferenciaStatusDate?.getTime().toString() ?? null,
    deposicion: data.deposicion?.getTime().toString() ?? null,
    vistaInformeManejoCaso: data.vistaInformeManejoCaso?.getTime().toString() ?? null,
    levantarActa: data.levantarActa?.getTime().toString() ?? null,
    reunionAbogados: data.reunionAbogados?.getTime().toString() ?? null,
    reunionCliente: data.reunionCliente?.getTime().toString() ?? null,
    completedDate: data.isComplete && !caso.isComplete ? new Date().getTime().toString() : null,
    ultimoReexamen: data.ultimoReexamen?.getTime().toString() ?? null,
    lanzamiento: data.lanzamiento?.getTime().toString() ?? null,
    ultimoPago: data.ultimoPago?.getTime().toString() ?? null,
    contrato: data.contrato?.getTime().toString() ?? null,
    jobName: data.jobName,
    asignadoA: data.asignadoA,
    court: data.court,
    citationRoom: data.citationRoom,
    salon: data.salon,
    juez: data.juez,
    salaExt: data.salaExt,
    diligenciadoEn: data.diligenciadoEn,
    ultimaMocionEnRecord: data.ultimaMocionEnRecord,
    causeOfAction: data.causeOfAction,
    demandadoTelefono: data.demandadoTelefono,
    demandadoEmail: data.demandadoEmail,
    demandadoDireccionPostal: data.demandadoDireccionPostal,
    demandadoDireccionFisica: data.demandadoDireccionFisica,
    otroAbogadoName: data.otroAbogadoName,
    otroAbogadoTelefono: data.otroAbogadoTelefono,
    otroAbogadoEmail: data.otroAbogadoEmail,
    otroAbogadoDireccion: data.otroAbogadoDireccion,
    nombreContacto2: data.nombreContacto2,
    telefonoContacto2: data.telefonoContacto2,
    emailContacto2: data.emailContacto2,
    edificio: data.edificio,
    apartamento: data.apartamento,
    incumplimiento: data.incumplimiento,
    mesesAdeudados: data.mesesAdeudados ? Number(data.mesesAdeudados) : null,
    rentaMensual: data.rentaMensual ? Number(data.rentaMensual) : null,
    deudaRenta: data.deudaRenta ? Number(data.deudaRenta) : null,
    deudaRentaNegativa: data.deudaRentaNegativa ? Number(data.deudaRentaNegativa) : null,
    deudaRecargo: data.deudaRecargo ? Number(data.deudaRecargo) : null,
    deudaTotal: data.deudaTotal ? Number(data.deudaTotal) : null,
    montoAdeudado: data.montoAdeudado ? Number(data.montoAdeudado) : null,
    interesesAcumulados: data.interesesAcumulados ? Number(data.interesesAcumulados) : null,
    penalidadesAcumuladas: data.penalidadesAcumuladas ? Number(data.penalidadesAcumuladas) : null,
    otrasCantidades: data.otrasCantidades ? Number(data.otrasCantidades) : null,
    isContestoDemanda: data.isContestoDemanda,
    isReconvencion: data.isReconvencion,
    isReplicaReconvencion: data.isReplicaReconvencion,
    isDescubrimientoNotificado: data.isDescubrimientoNotificado,
    isBillable: data.isBillable,
    isDesisted: data.isDesisted,
    isProcessed: data.isProcessed,
    isFiled: data.isFiled,
    isExpedienteEntregado: data.isExpedienteEntregado,
    isDoneInformeManejoCaso: data.isDoneInformeManejoCaso,
    isComplete: data.isComplete,
    isInformetPT: data.isInformetPT,
    isEdicto: data.isEdicto,
    isRebeldia: data.isRebeldia,
    isEjecucion: data.isEjecucion,
    isDescubrimientoDmnte: data.isDescubrimientoDmnte,
    isDescubrimientoDmnteContestado: data.isDescubrimientoDmnteContestado,
    isDescubrimientoDmnda: data.isDescubrimientoDmnda,
    isDescubrimientoDmndaContestado: data.isDescubrimientoDmndaContestado,
    typesOfCase: data.typesOfCase,
    clientId: data.client.id,
    groupId: data.group?.id ?? null,
    administratorId: data.administrator?.id ?? null,
    residentialId: data.residential?.id ?? null,
    causalId: data.causal?.id ?? null,
  };
};

const getDefaultValues = (caso: Case | null | undefined): DefaultValues<FormValues> => {
  const defaultValues: DefaultValues<FormValues> = {
    id: caso?.id ?? '',
    caseNumber: caso?.caseNumber ?? '',
    matter: caso?.matter ?? '',
    typeOfRepresentationId: caso?.typeOfRepresentationId ?? -1,
    tribunalId: caso?.tribunalId ?? -1,
    primeraVistaDate: caso?.primeraVistaDate ? new Date(Number(caso.primeraVistaDate)) : null,
    segundaVistaDate: caso?.segundaVistaDate ? new Date(Number(caso.segundaVistaDate)) : null,
    terceraVistaDate: caso?.terceraVistaDate ? new Date(Number(caso.terceraVistaDate)) : null,
    juicioDate: caso?.juicioDate ? new Date(Number(caso.juicioDate)) : null,
    verdictDate: caso?.verdictDate ? getDateInUTC(caso.verdictDate) : null,
    referralDate: caso?.referralDate ? getDateInUTC(caso.referralDate) : null,
    seguimientoDate: caso?.seguimientoDate ? getDateInUTC(caso.seguimientoDate) : null,
    vencimientoDate: caso?.vencimientoDate ? getDateInUTC(caso.vencimientoDate) : null,
    radicacionDemandaDate: caso?.radicacionDemandaDate ? getDateInUTC(caso.radicacionDemandaDate) : null,
    diligenciamentoDate: caso?.diligenciamentoDate ? getDateInUTC(caso.diligenciamentoDate) : null,
    reconsideracionDate: caso?.reconsideracionDate ? getDateInUTC(caso.reconsideracionDate) : null,
    apelacionDate: caso?.apelacionDate ? getDateInUTC(caso.apelacionDate) : null,
    expiracionContestacionADemandaDate: caso?.expiracionContestacionADemandaDate
      ? getDateInUTC(caso.expiracionContestacionADemandaDate)
      : null,
    emplazadoDate: caso?.emplazadoDate ? getDateInUTC(caso.emplazadoDate) : null,
    conferenciaAntelacionJuicioDate: caso?.conferenciaAntelacionJuicioDate
      ? new Date(Number(caso.conferenciaAntelacionJuicioDate))
      : null,
    conferenciaStatusDate: caso?.conferenciaStatusDate ? new Date(Number(caso.conferenciaStatusDate)) : null,
    deposicion: caso?.deposicion ? new Date(Number(caso.deposicion)) : null,
    vistaInformeManejoCaso: caso?.vistaInformeManejoCaso ? new Date(Number(caso.vistaInformeManejoCaso)) : null,
    levantarActa: caso?.levantarActa ? new Date(Number(caso.levantarActa)) : null,
    reunionAbogados: caso?.reunionAbogados ? new Date(Number(caso.reunionAbogados)) : null,
    reunionCliente: caso?.reunionCliente ? new Date(Number(caso.reunionCliente)) : null,
    completedDate: caso?.completedDate ? new Date(Number(caso.completedDate)) : null,
    ultimoReexamen: caso?.ultimoReexamen ? new Date(Number(caso.ultimoReexamen)) : null,
    lanzamiento: caso?.lanzamiento ? new Date(Number(caso.lanzamiento)) : null,
    ultimoPago: caso?.ultimoPago ? getDateInUTC(caso.ultimoPago) : null,
    contrato: caso?.contrato ? getDateInUTC(caso.contrato) : null,
    jobName: caso?.jobName ?? '',
    asignadoA: caso?.asignadoA ?? '',
    court: caso?.court ?? '',
    citationRoom: caso?.citationRoom ?? '',
    salon: caso?.salon ?? '',
    juez: caso?.juez ?? '',
    salaExt: caso?.salaExt ?? '',
    diligenciadoEn: caso?.diligenciadoEn ?? '',
    ultimaMocionEnRecord: caso?.ultimaMocionEnRecord ?? '',
    causeOfAction: caso?.causeOfAction ?? '',
    demandadoTelefono: caso?.demandadoTelefono ?? '',
    demandadoEmail: caso?.demandadoEmail ?? '',
    demandadoDireccionPostal: caso?.demandadoDireccionPostal ?? '',
    demandadoDireccionFisica: caso?.demandadoDireccionFisica ?? '',
    otroAbogadoName: caso?.otroAbogadoName ?? '',
    otroAbogadoTelefono: caso?.otroAbogadoTelefono ?? '',
    otroAbogadoEmail: caso?.otroAbogadoEmail ?? '',
    otroAbogadoDireccion: caso?.otroAbogadoDireccion ?? '',
    nombreContacto2: caso?.nombreContacto2 ?? '',
    telefonoContacto2: caso?.telefonoContacto2 ?? '',
    emailContacto2: caso?.emailContacto2 ?? '',
    edificio: caso?.edificio ?? '',
    apartamento: caso?.apartamento ?? '',
    incumplimiento: caso?.incumplimiento ?? '',
    mesesAdeudados: caso?.mesesAdeudados ?? null,
    rentaMensual: caso?.rentaMensual ?? null,
    deudaRenta: caso?.deudaRenta ?? null,
    deudaRentaNegativa: caso?.deudaRentaNegativa ?? null,
    deudaRecargo: caso?.deudaRecargo ?? null,
    deudaTotal: caso?.deudaTotal ?? null,
    montoAdeudado: caso?.montoAdeudado ?? null,
    interesesAcumulados: caso?.interesesAcumulados ?? null,
    penalidadesAcumuladas: caso?.penalidadesAcumuladas ?? null,
    otrasCantidades: caso?.otrasCantidades ?? null,
    typesOfCase: caso?.typesOfCase ?? [],
    isContestoDemanda: caso?.isContestoDemanda ?? false,
    isReconvencion: caso?.isReconvencion ?? false,
    isReplicaReconvencion: caso?.isReplicaReconvencion ?? false,
    isDescubrimientoNotificado: caso?.isDescubrimientoNotificado ?? false,
    isBillable: caso?.isBillable ?? false,
    isDesisted: caso?.isDesisted ?? false,
    isProcessed: caso?.isProcessed ?? false,
    isFiled: caso?.isFiled ?? false,
    isExpedienteEntregado: caso?.isExpedienteEntregado ?? false,
    isDoneInformeManejoCaso: caso?.isDoneInformeManejoCaso ?? false,
    isComplete: caso?.isComplete ?? false,
    isInformetPT: caso?.isInformetPT ?? false,
    isEdicto: caso?.isEdicto ?? false,
    isRebeldia: caso?.isRebeldia ?? false,
    isEjecucion: caso?.isEjecucion ?? false,
    isDescubrimientoDmnte: caso?.isDescubrimientoDmnte ?? false,
    isDescubrimientoDmnteContestado: caso?.isDescubrimientoDmnteContestado ?? false,
    isDescubrimientoDmnda: caso?.isDescubrimientoDmnda ?? false,
    isDescubrimientoDmndaContestado: caso?.isDescubrimientoDmndaContestado ?? false,
    group: caso?.group ?? null,
    client: caso?.client,
    administrator: caso?.administrator,
    residential: caso?.residential,
    causal: caso?.causal,
  };

  return defaultValues;
};

type TypeOfSubmit = 'Save' | 'Delete';

export const useCaseForm = (caso: Case | null | undefined) => {
  const form = useForm<FormValues>({ defaultValues: getDefaultValues(caso) });

  useEffect(() => {
    form.reset(getDefaultValues(caso));
  }, [caso]);

  const [editCase] = useMutation<UpdateCase, UpdateCaseVariables>(EDIT_CASE);
  const [deleteCase] = useMutation<DeleteCase, DeleteCaseVariables>(DEL_CASE);

  const onSubmit = (typeOfSubmit: TypeOfSubmit, cb: () => void): SubmitHandler<FormValues> => async (data) => {
    switch (typeOfSubmit) {
      case 'Save': {
        if (caso) {
          await editCase({
            variables: {
              case: getCaseInput(caso, data),
            },
          });
        }
        break;
      }
      case 'Delete': {
        if (caso) {
          await deleteCase({ variables: { id: caso.id } });
        }
        break;
      }
    }

    cb();
  };

  return {
    onSubmit: (typeOfSubmit: TypeOfSubmit, cb: (error?: any) => void) => {
      return form.handleSubmit(onSubmit(typeOfSubmit, cb), (e) => cb(e));
    },
    ...form,
  };
};
