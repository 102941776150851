import React, { useEffect } from 'react';
import { useNavigate } from '@gatsbyjs/reach-router';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { useMutation } from '@apollo/client';
import { useForm, SubmitHandler, DefaultValues, Controller } from 'react-hook-form';

import { ClientAutocomplete } from './client-autocomplete';
import { GroupAutocomplete } from './group-autocomplete';

import { supportedCaseTypes, representamosItems } from '../data/case';
import { CaseInput } from '../graph-types/globalTypes';
import { AddCase, AddCase_addCase as Case, AddCaseVariables } from '../graph-types/AddCase';
import { Client } from '../components/client-autocomplete';
import { Group } from '../components/group-autocomplete';
import { ADD_CASE } from '../queries/case';

type FormValues = {
  client: Client;
  group: Group | null;
  matter: string;
  typeOfRepresentationId: number;
  causeOfAction: string;
  typesOfCase: number[];
};

const getCaseInput = (id: string, data: FormValues): CaseInput => {
  return {
    id,
    clientId: data.client.id,
    groupId: data.group?.id ?? null,
    matter: data.matter,
    typeOfRepresentationId: data.typeOfRepresentationId,
    causeOfAction: data.causeOfAction,
    typesOfCase: data.typesOfCase,
    isContestoDemanda: false,
    isReconvencion: false,
    isReplicaReconvencion: false,
    isDescubrimientoNotificado: false,
    isBillable: false,
    isDesisted: false,
    isProcessed: false,
    isFiled: false,
    isExpedienteEntregado: false,
    isDoneInformeManejoCaso: false,
    isComplete: false,
    isInformetPT: false,
    isEdicto: false,
    isRebeldia: false,
    isEjecucion: false,
    isDescubrimientoDmnte: false,
    isDescubrimientoDmnteContestado: false,
    isDescubrimientoDmnda: false,
    isDescubrimientoDmndaContestado: false,
  };
};

const useCaseForm = (defaultValues: DefaultValues<FormValues>) => {
  const form = useForm<FormValues>({ defaultValues });

  const [addCase] = useMutation<AddCase, AddCaseVariables>(ADD_CASE);

  const onSubmit = (cb: (caso: Case | undefined) => void): SubmitHandler<FormValues> => async (data) => {
    const result = await addCase({
      variables: {
        case: getCaseInput('', data),
      },
    });
    cb(result.data?.addCase);
  };

  return {
    onSubmit: (cb: (caso: Case | undefined) => void) => form.handleSubmit(onSubmit(cb)),
    ...form,
  };
};

const getDefaultValues = (): DefaultValues<FormValues> => {
  const defaultValues: DefaultValues<FormValues> = {
    client: undefined,
    group: null,
    matter: '',
    typeOfRepresentationId: -1,
    causeOfAction: '',
    typesOfCase: [],
  };

  return defaultValues;
};

export const CaseDialog: React.FC<{
  open: boolean;
  handleClose: (msg: string | null) => void;
}> = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const {
    register,
    formState,
    onSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useCaseForm(getDefaultValues());

  useEffect(() => {
    if (!open) {
      reset(getDefaultValues());
    }
  }, [reset, open]);

  const onClose = (): void => {
    handleClose(null);
  };

  const client = watch('client');

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <form
        onSubmit={onSubmit((caso) => {
          if (caso) {
            handleClose('Caso guardado!');
            reset(getDefaultValues());
            navigate(`/cases/${caso.id}`);
          }
        })}
      >
        <DialogTitle id="form-dialog-title">Crear Caso</DialogTitle>
        <DialogContent>
          <Controller
            name="client"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ClientAutocomplete
                value={field.value ?? null}
                onSelect={(value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
          <FormControl margin="dense" variant="outlined" fullWidth>
            <InputLabel id="representation-label">Representamos</InputLabel>
            <Controller
              name="typeOfRepresentationId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  onChange={field.onChange}
                  value={field.value}
                  label="Representamos"
                  labelId="representation-label"
                  id="representation-select"
                >
                  {representamosItems.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <Controller
            name="group"
            control={control}
            render={({ field }) => (
              <GroupAutocomplete
                clientId={client?.id ?? ''}
                value={field.value ?? null}
                onSelect={(value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
          <TextField
            inputProps={register('matter', { required: true })}
            label="Asunto"
            margin="dense"
            variant="outlined"
            fullWidth
          />
          <FormControl margin="dense" variant="outlined" fullWidth>
            <InputLabel id="types-case-label">Tipo de Caso</InputLabel>
            <Controller
              name="typesOfCase"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  onChange={field.onChange}
                  value={field.value}
                  labelId="types-case-label"
                  label="Tipo de Caso"
                  id="types-case-select"
                  multiple
                  renderValue={(selected) => (
                    <>
                      {(selected as number[]).map((value) => (
                        <Chip key={value} label={supportedCaseTypes.find(({ id }) => id === value)?.name ?? ''} />
                      ))}
                    </>
                  )}
                >
                  {supportedCaseTypes.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <TextField
            inputProps={register('causeOfAction')}
            label="Causal / Asunto"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" disabled={formState.isSubmitting}>
            Cerrar
          </Button>
          <Button type="submit" color="primary" disabled={formState.isSubmitting}>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
