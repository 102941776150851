import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { DataGrid, GridRowsProp, GridColTypeDef, GridColDef, GridCellParams } from '@material-ui/data-grid';

import { CORR_TYPES, getValueOfType } from '../data/correspondence';
import { Case_case as Case, Case_case_correspondences as Correspondence } from '../graph-types/Case';
import { CorrespondenceDialog } from './correspondence-dialog';

type Props = {
  caso: Case;
  correspondences: Correspondence[];
};

const withTooltip: GridColTypeDef = {
  renderCell: (params: GridCellParams) => {
    return (
      <Tooltip interactive title={<Typography variant="subtitle1">{params.value || ''}</Typography>}>
        <Typography noWrap>{params.value}</Typography>
      </Tooltip>
    );
  },
};

const spDate: GridColTypeDef = {
  type: 'string',
  valueFormatter: ({ value }) => new Intl.DateTimeFormat('es-ES').format(new Date(Number(value))),
};

export const CaseCorrespondences: React.FC<Props> = (props) => {
  const [selectedCorrespondence, setSelectedCorrespondence] = useState<Correspondence | null>(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const rows: GridRowsProp = props.correspondences.map((corr) => ({
    id: corr.id,
    col1: corr.dateAdded,
    col2: corr.recipient,
    col3: corr.item,
    col4: corr.asunto,
    col5: corr.typeOfCorrespondenceId ? CORR_TYPES[corr.typeOfCorrespondenceId] : '',
    col6: getValueOfType(corr),
  }));

  const corrWidth = matches ? { flex: 1 } : { width: 300 };

  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Fecha de Envío', width: 140, ...spDate },
    { field: 'col2', headerName: 'Dirigido a', width: 175, ...withTooltip },
    { field: 'col3', headerName: 'Correspondencia', ...corrWidth, ...withTooltip },
    { field: 'col4', headerName: 'Otro Asunto', width: 140, ...withTooltip },
    { field: 'col5', headerName: 'Tipo', width: 100, ...withTooltip },
    { field: 'col6', headerName: 'Rastreo/Email', width: 150, ...withTooltip },
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        onRowClick={(params): void => {
          const correspondence = props.correspondences.find((correspondence) => correspondence.id === params.row.id);
          setSelectedCorrespondence(correspondence ?? null);
        }}
      />
      <CorrespondenceDialog
        correspondence={selectedCorrespondence}
        caso={props.caso}
        open={selectedCorrespondence != null}
        handleClose={(msg, keepOpen) => {
          if (!keepOpen) {
            setSelectedCorrespondence(null);
          }
        }}
      />
    </>
  );
};
