import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { RouteComponentProps, useMatch, useNavigate } from '@gatsbyjs/reach-router';
import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { ResidentialDialog } from './residential-dialog';
import { AdministratorDialog } from './administrator-dialog';
import { CausalDialog } from './causal-dialog';
import { FlatFeeDialog } from './flat-fee-dialog';
import { PrefilledNoteDialog } from './prefilled-note-dialog';
import { LegalCodeDialog } from './legal-code-dialog';

import { GET_RESOURCES } from '../queries/resources';
import {
  Resources_resources_administrators as Administrator,
  Resources_resources_residentials as Residential,
  Resources_resources_causales as Causal,
  Resources_resources_flatFees as FlatFee,
  Resources_resources_prefilledNotes as PrefilledNote,
  Resources_resources_taskCodes as LegalCode,
  Resources as ResourcesQuery,
} from '../graph-types/Resources';

type Props = RouteComponentProps;

type TabValues =
  | 'residential'
  | 'administrator'
  | 'causal'
  | 'prefilledNotes'
  | 'flatFees'
  | 'taskCodes'
  | 'activityCodes'
  | 'expenseCodes';

type Dialogs =
  | 'residential'
  | 'administrator'
  | 'causal'
  | 'prefilledNote'
  | 'flatFee'
  | 'taskCode'
  | 'activityCode'
  | 'expenseCode'
  | 'none';

const useTabValue = (): TabValues => {
  const match = useMatch('/resources/:section');
  if (!match) {
    return 'residential';
  }

  return match.section as TabValues;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: theme.spacing(1),
    },
    panel: {
      padding: 0,
    },
    tabs: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    paper: {
      marginBottom: theme.spacing(12),
      padding: theme.spacing(1),
    },
    speedDial: {
      position: 'fixed',
      '&.MuiSpeedDial-directionUp': {
        bottom: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
  })
);

export const Resources: React.FC<Props> = (props) => {
  const section = useTabValue();
  const navigate = useNavigate();
  const classes = useStyles();

  const [actionsOpen, setActionsOpen] = useState(false);
  const [dialog, setDialog] = useState<Dialogs>('none');

  const [selectedResidential, setResidential] = useState<Residential | null>(null);
  const [selectedAdministrator, setAdministrator] = useState<Administrator | null>(null);
  const [selectedCausal, setCausal] = useState<Causal | null>(null);
  const [selectedFlatFee, setFlatFee] = useState<FlatFee | null>(null);
  const [selectedPrefilledNote, setPrefilledNote] = useState<PrefilledNote | null>(null);
  const [selectedLegalCode, setLegalCode] = useState<LegalCode | null>(null);

  const { loading, data } = useQuery<ResourcesQuery>(GET_RESOURCES);

  if (loading) {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <SpeedDial
        className={classes.speedDial}
        ariaLabel="Recursos"
        icon={<SpeedDialIcon />}
        open={actionsOpen}
        onClose={(event, reason) => {
          if (reason === 'toggle') {
            setActionsOpen(false);
          }
        }}
        onOpen={(event, reason) => {
          if (reason === 'toggle') {
            setActionsOpen(true);
          }
        }}
        direction={'up'}
      >
        <SpeedDialAction
          key={'residencial'}
          icon={<HomeWorkIcon />}
          tooltipTitle={'Crear Residencial'}
          onClick={() => setDialog('residential')}
        />
        <SpeedDialAction
          key={'admin'}
          icon={<SupervisorAccountIcon />}
          tooltipTitle={'Crear Administrador'}
          onClick={() => setDialog('administrator')}
        />
        <SpeedDialAction
          key={'causal'}
          icon={<AssignmentTurnedInIcon />}
          tooltipTitle={'Crear Causal'}
          onClick={() => setDialog('causal')}
        />
        <SpeedDialAction
          key={'item'}
          icon={<AttachMoneyIcon />}
          tooltipTitle={'Crear Item'}
          onClick={() => setDialog('flatFee')}
        />
        <SpeedDialAction
          key={'note'}
          icon={<NoteAddIcon />}
          tooltipTitle={'Crear Nota'}
          onClick={() => setDialog('prefilledNote')}
        />
        <SpeedDialAction
          key={'taskCode'}
          icon={'TC'}
          tooltipTitle={'Crear Task Code'}
          onClick={() => setDialog('taskCode')}
        />
        <SpeedDialAction
          key={'activityCode'}
          icon={'AC'}
          tooltipTitle={'Crear Activity Code'}
          onClick={() => setDialog('activityCode')}
        />
        <SpeedDialAction
          key={'expenseCode'}
          icon={'EC'}
          tooltipTitle={'Crear Expense Code'}
          onClick={() => setDialog('expenseCode')}
        />
      </SpeedDial>
      <Paper square elevation={1}>
        <Tabs
          classes={{ root: classes.tabs }}
          value={section}
          variant="scrollable"
          onChange={(e, value) => navigate(`/resources/${value}`, { replace: true })}
        >
          <Tab label="Residenciales" value="residential" />
          <Tab label="Administradores" value="administrator" />
          <Tab label="Causales" value="causal" />
          <Tab label="Items" value="flatFees" />
          <Tab label="Notas Modelo (Fact.)" value="prefilledNotes" />
          <Tab label="Task Code" value="taskCodes" />
          <Tab label="Activity Code" value="activityCodes" />
          <Tab label="Expense Code" value="expenseCodes" />
        </Tabs>
      </Paper>
      <Paper square className={classes.paper} elevation={1}>
        <TabContext value={section}>
          <TabPanel className={classes.panel} value="residential">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Nombre de Administrador</TableCell>
                    <TableCell>Agente</TableCell>
                    <TableCell>Teléfono</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.resources.residentials.map((residencial) => (
                    <TableRow
                      key={residencial.id}
                      onClick={() => {
                        setResidential(residencial);
                        setDialog('residential');
                      }}
                    >
                      <TableCell>{residencial.residential}</TableCell>
                      <TableCell>{residencial.administrator.companyName}</TableCell>
                      <TableCell>{residencial.nombreAdministrador}</TableCell>
                      <TableCell>{residencial.telContacto1}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel className={classes.panel} value="administrator">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Agente</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.resources.administrators.map((admin) => (
                    <TableRow
                      key={admin.id}
                      onClick={() => {
                        setAdministrator(admin);
                        setDialog('administrator');
                      }}
                    >
                      <TableCell>{admin.companyName}</TableCell>
                      <TableCell>{admin.agente}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel className={classes.panel} value="causal">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Causal</TableCell>
                    <TableCell>Iniciales</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.resources.causales.map((causal) => (
                    <TableRow
                      key={causal.id}
                      onClick={() => {
                        setCausal(causal);
                        setDialog('causal');
                      }}
                    >
                      <TableCell>{causal.causal}</TableCell>
                      <TableCell>{causal.initials}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel className={classes.panel} value="flatFees">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Tipo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.resources.flatFees.map((item) => (
                    <TableRow
                      key={item.id}
                      onClick={() => {
                        setFlatFee(item);
                        setDialog('flatFee');
                      }}
                    >
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.fee}</TableCell>
                      <TableCell>{item.type === 1 ? 'Horas' : 'Gastos'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel className={classes.panel} value="prefilledNotes">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nota</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.resources.prefilledNotes.map((note) => (
                    <TableRow
                      key={note.id}
                      onClick={() => {
                        setPrefilledNote(note);
                        setDialog('prefilledNote');
                      }}
                    >
                      <TableCell>{note.note}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel className={classes.panel} value="taskCodes">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Descripción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.resources.taskCodes.map((taskCode) => (
                    <TableRow
                      key={taskCode.id}
                      onClick={() => {
                        setLegalCode(taskCode);
                        setDialog('taskCode');
                      }}
                    >
                      <TableCell>{taskCode.code}</TableCell>
                      <TableCell>{taskCode.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel className={classes.panel} value="activityCodes">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Descripción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.resources.activityCodes.map((activityCode) => (
                    <TableRow
                      key={activityCode.id}
                      onClick={() => {
                        setLegalCode(activityCode);
                        setDialog('activityCode');
                      }}
                    >
                      <TableCell>{activityCode.code}</TableCell>
                      <TableCell>{activityCode.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel className={classes.panel} value="expenseCodes">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Descripción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.resources.expenseCodes.map((expenseCode) => (
                    <TableRow
                      key={expenseCode.id}
                      onClick={() => {
                        setLegalCode(expenseCode);
                        setDialog('expenseCode');
                      }}
                    >
                      <TableCell>{expenseCode.code}</TableCell>
                      <TableCell>{expenseCode.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </TabContext>
      </Paper>
      <ResidentialDialog
        open={dialog === 'residential'}
        handleClose={() => {
          setDialog('none');
          setResidential(null);
        }}
        residential={selectedResidential}
        resources={data.resources}
      />
      <AdministratorDialog
        open={dialog === 'administrator'}
        handleClose={() => {
          setDialog('none');
          setAdministrator(null);
        }}
        administrator={selectedAdministrator}
        resources={data.resources}
      />
      <CausalDialog
        open={dialog === 'causal'}
        handleClose={() => {
          setDialog('none');
          setCausal(null);
        }}
        causal={selectedCausal}
        resources={data.resources}
      />
      <FlatFeeDialog
        open={dialog === 'flatFee'}
        handleClose={() => {
          setDialog('none');
          setFlatFee(null);
        }}
        flatFee={selectedFlatFee}
        resources={data.resources}
      />
      <PrefilledNoteDialog
        open={dialog === 'prefilledNote'}
        handleClose={() => {
          setDialog('none');
          setPrefilledNote(null);
        }}
        prefilledNote={selectedPrefilledNote}
        resources={data.resources}
      />
      <LegalCodeDialog
        open={dialog === 'taskCode'}
        type="task"
        handleClose={() => {
          setDialog('none');
          setLegalCode(null);
        }}
        legalCode={selectedLegalCode}
        resources={data.resources}
      />
      <LegalCodeDialog
        open={dialog === 'activityCode'}
        type="activity"
        handleClose={() => {
          setDialog('none');
          setLegalCode(null);
        }}
        legalCode={selectedLegalCode}
        resources={data.resources}
      />
      <LegalCodeDialog
        open={dialog === 'expenseCode'}
        type="expense"
        handleClose={() => {
          setDialog('none');
          setLegalCode(null);
        }}
        legalCode={selectedLegalCode}
        resources={data.resources}
      />
    </>
  );
};
