import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLazyQuery } from '@apollo/client';

import { SEARCH_CASES } from '../queries/search';
import { SearchCases, SearchCases_searchCases as Case, SearchCasesVariables } from '../graph-types/SearchCases';
import { getCaseName } from '../data/case';

export { Case };

type Props = {
  value: Case | null;
  onSelect: (option: Case | null) => void;
};

export const CaseAutocomplete: React.FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [caseName, setCaseName] = useState<string>('');
  const [getCases, { loading, data }] = useLazyQuery<SearchCases, SearchCasesVariables>(SEARCH_CASES, {
    variables: { caseName },
  });

  const debouncedSetCaseName = useCallback(debounce(setCaseName, 200), [setCaseName]);

  const options = data?.searchCases ?? [];

  useEffect(() => {
    if (open && caseName.length > 2) {
      getCases();
    }
  }, [open, caseName, getCases]);

  return (
    <Autocomplete
      id="cases-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, newValue) => {
        props.onSelect(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        debouncedSetCaseName(newInputValue);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => getCaseName(option.client.name, option.group?.name ?? null, option.matter)}
      options={props.value ? [props.value, ...options] : options}
      loading={loading}
      value={props.value}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          margin="dense"
          label="Caso"
          variant="outlined"
          name="case-autocomplete"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
