import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app/components/app';
import { registerSW, showRefreshUI } from './sw-register';

const registeredSW = (window.location.host.includes('localhost') || window.location.host.includes('127.0.0.1')) ? 
  new Promise(() => {
    //
  }) : registerSW();

const getConfig = () => {
  if (window.location.host.includes('localhost') || window.location.host.includes('githubpreview.dev')) {
    return Promise.resolve({
      apiKey: 'AIzaSyAotdsMoX6VPA2hi4MGYh7tkAUjELqgYUE',
      appId: '1:1031950417701:web:d491d7dc27c67bac',
      authDomain: 'jurisopus.firebaseapp.com',
      databaseURL: 'https://jurisopus.firebaseio.com',
      messagingSenderId: '1031950417701',
      projectId: 'jurisopus',
      storageBucket: 'jurisopus.appspot.com',
    });
  }

  return fetch('/__/firebase/init.json').then((response) => response.json());
};

const fetchCurrentUser = () =>
  new Promise((resolve) => {
    const onAuth = window.firebase.auth().onAuthStateChanged((user) => {
      onAuth();
      resolve(user);
    });
  });

const getCurrentUser = () =>
  Promise.resolve(window.firebase.auth().currentUser)
    .then((user) => user || fetchCurrentUser())
    .then((user) => {
      if (!user) {
        return null;
      }

      const db = window.firebase.firestore();

      return db
        .collection('users')
        .doc(user.uid)
        .get()
        .then((userDoc) => {
          const data = userDoc.data();
          return {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            role: data.role,
            org: data.org,
          };
        });
    });

getConfig()
  .then((firebaseConfig) => {
    window.firebase.initializeApp(firebaseConfig);
    window.firebase.auth();

    return getCurrentUser();
  })
  .then((user) => {
    ReactDOM.render(<App user={user} />, document.getElementById('root'));
    registeredSW.then(showRefreshUI);
  })
  .catch((e) => {
    console.log(e.message);
  });
