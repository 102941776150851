import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { gql, useMutation, Reference, StoreObject } from '@apollo/client';
import { useForm, SubmitHandler, DefaultValues } from 'react-hook-form';

import { ADD_PREFILLEDNOTE, EDIT_PREFILLEDNOTE, DEL_PREFILLEDNOTE } from '../queries/resources';
import { Resources_resources_prefilledNotes as PrefilledNote } from '../graph-types/Resources';
import { PrefilledNoteInput } from '../graph-types/globalTypes';
import { AddPrefilledNote, AddPrefilledNoteVariables } from '../graph-types/AddPrefilledNote';
import { UpdatePrefilledNote, UpdatePrefilledNoteVariables } from '../graph-types/UpdatePrefilledNote';
import { DeletePrefilledNote, DeletePrefilledNoteVariables } from '../graph-types/DeletePrefilledNote';

import { Resources_resources as Resources } from '../graph-types/Resources';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: 'auto',
    },
  })
);

type FormValues = {
  note: string;
};

const getPrefilledNoteData = (id: string, data: FormValues): PrefilledNoteInput => {
  return {
    id,
    note: data.note,
  };
};

type TypeOfSubmit = 'Save' | 'Delete';

const usePrefilledNoteForm = (
  prefilledNote: PrefilledNote | null,
  defaultValues: DefaultValues<FormValues>,
  resources: Resources
) => {
  const form = useForm<FormValues>({ defaultValues });

  const [deletePrefilledNote] = useMutation<DeletePrefilledNote, DeletePrefilledNoteVariables>(DEL_PREFILLEDNOTE, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((resources as unknown) as StoreObject),
        fields: {
          prefilledNotes(existingPrefilledNotes = [], { readField }) {
            if (!data) {
              return existingPrefilledNotes;
            }

            return existingPrefilledNotes.filter(
              (prefilledNoteRef: Reference) => data.deletePrefilledNote !== readField('id', prefilledNoteRef)
            );
          },
        },
      });
    },
  });

  const [addPrefilledNote] = useMutation<AddPrefilledNote, AddPrefilledNoteVariables>(ADD_PREFILLEDNOTE, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((resources as unknown) as StoreObject),
        fields: {
          prefilledNotes(existingPrefilledNotes = []) {
            if (!data) {
              return existingPrefilledNotes;
            }

            const prefilledNoteData = getPrefilledNoteData(data.addPrefilledNote.id, form.getValues());
            const newPrefilledNoteRef = cache.writeFragment({
              data: {
                ...prefilledNoteData,
                __typename: 'PrefilledInvoiceNotes',
              },
              fragment: gql`
                fragment NewPrefilledNote on PrefilledInvoiceNotes {
                  id
                  note
                }
              `,
            });
            return [...existingPrefilledNotes, newPrefilledNoteRef];
          },
        },
      });
    },
  });

  const [editPrefilledNote] = useMutation<UpdatePrefilledNote, UpdatePrefilledNoteVariables>(EDIT_PREFILLEDNOTE);

  const onSubmit = (typeOfSubmit: TypeOfSubmit, cb: () => void): SubmitHandler<FormValues> => async (data) => {
    switch (typeOfSubmit) {
      case 'Save': {
        if (prefilledNote) {
          await editPrefilledNote({
            variables: {
              prefilledNote: getPrefilledNoteData(prefilledNote.id, data),
            },
          });
        } else {
          await addPrefilledNote({
            variables: {
              prefilledNote: getPrefilledNoteData('', data),
            },
          });
        }
        break;
      }
      case 'Delete': {
        if (prefilledNote) {
          await deletePrefilledNote({ variables: { prefilledNoteId: prefilledNote.id } });
        }
        break;
      }
    }

    cb();
  };

  return {
    onSubmit: (typeOfSubmit: TypeOfSubmit, cb: () => void) => form.handleSubmit(onSubmit(typeOfSubmit, cb)),
    ...form,
  };
};

const getDefaultValues = (prefilledNote: PrefilledNote | null): DefaultValues<FormValues> => {
  const defaultValues: DefaultValues<FormValues> = {
    note: prefilledNote?.note ?? '',
  };

  return defaultValues;
};

export const PrefilledNoteDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  prefilledNote: PrefilledNote | null;
  resources: Resources;
}> = ({ handleClose, prefilledNote, open, resources }) => {
  const classes = useStyles();

  const { register, formState, onSubmit, reset } = usePrefilledNoteForm(
    prefilledNote,
    getDefaultValues(prefilledNote),
    resources
  );

  const { errors } = formState;

  useEffect(() => {
    reset(getDefaultValues(prefilledNote));
  }, [reset, prefilledNote, open]);

  const onClose = (): void => {
    handleClose();
    reset(getDefaultValues(null));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <form
        onSubmit={onSubmit('Save', () => {
          handleClose();
        })}
      >
        <DialogTitle id="form-dialog-title">Crear Nota Modelo</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            multiline
            margin="dense"
            id="prefilledNote"
            label="Nota"
            type="text"
            variant="outlined"
            inputProps={register('note', { required: true })}
            error={!!errors.note}
          />
        </DialogContent>
        <DialogActions>
          {prefilledNote && (
            <Button
              classes={classes}
              onClick={() =>
                onSubmit('Delete', () => {
                  handleClose();
                })()
              }
              color="secondary"
              disabled={formState.isSubmitting}
            >
              Borrar
            </Button>
          )}
          <Button onClick={onClose} color="primary" disabled={formState.isSubmitting}>
            Cerrar
          </Button>
          <Button type="submit" color="primary" disabled={formState.isSubmitting}>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
