import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps, useNavigate } from '@gatsbyjs/reach-router';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import { DataGrid, GridRowsProp, GridColDef, GridColTypeDef, GridCellParams } from '@material-ui/data-grid';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { gql, useQuery, useMutation, Reference } from '@apollo/client';

import {
  Correspondences_correspondences_rows as Correspondence,
  Correspondences as CorrespondencesQuery,
  CorrespondencesVariables,
} from '../graph-types/Correspondences';
import { ArchiveCorrespondences, ArchiveCorrespondencesVariables } from '../graph-types/ArchiveCorrespondences';
import { CORR_TYPES, getValueOfType } from '../data/correspondence';
import { getCaseName } from '../data/case';
import { CorrespondenceDialog } from './correspondence-dialog';

const GET_CORRS = gql`
  query Correspondences($archived: Boolean, $cursor: String) {
    correspondences(archived: $archived, cursor: $cursor) {
      cursor
      rows {
        id
        recipient
        asunto
        item
        trackingNumber
        email
        dateAdded
        isArchived
        typeOfCorrespondenceId
        case {
          id
          matter
          group {
            name
          }
          client {
            name
          }
        }
      }
    }
  }
`;

const ARCH_CORRS = gql`
  mutation ArchiveCorrespondences($correspondenceIds: [String!]!) {
    archiveCorrespondences(ids: $correspondenceIds)
  }
`;

const spDate: GridColTypeDef = {
  type: 'string',
  valueFormatter: ({ value }) => new Intl.DateTimeFormat('es-ES').format(new Date(Number(value))),
};

const withTooltip: GridColTypeDef = {
  renderCell: (params: GridCellParams) => {
    return (
      <Tooltip interactive title={<Typography variant="subtitle1">{params.value || ''}</Typography>}>
        <Typography noWrap>{params.value}</Typography>
      </Tooltip>
    );
  },
};

const Alert: React.FC<AlertProps> = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export const Correspondences: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState<{ status: 'closed' } | { status: 'open'; msg: string }>({
    status: 'closed',
  });
  const [activeCorrespondence, setActiveCorrespondence] = useState<Correspondence | null>(null);
  const [isArchived, setIsArchived] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [isDone, setIsDone] = useState<boolean>(false);

  // Each page will correspond to the cursor it needed to load it
  // For example, page 0, first page, doesnt use any cursor so its null
  // Page 1, will have cursor provided from page 0, and so forth
  // Last page will have a null cursor but wont be stored since paging will be done
  const pageStateRef = useRef<{ [page: number]: string | null }>({ 0: null });
  const selectedCorrespondencesRef = useRef<Set<string>>(new Set());

  const currentCursor = pageStateRef.current ? pageStateRef.current[page] : null;

  const { loading, error, data, refetch } = useQuery<CorrespondencesQuery, CorrespondencesVariables>(GET_CORRS, {
    variables: {
      archived: isArchived,
      cursor: currentCursor,
    },
    onCompleted: (data) => {
      const cursor = data.correspondences?.cursor;
      const hasOnePage = page === 0 && cursor == null;
      const reachedLastPage = hasOnePage || (page > 0 && cursor == null);

      if (!isDone) {
        setIsDone(reachedLastPage);
        setTotal(total + (data.correspondences?.rows?.length ?? 0));
      }
    },
  });

  const rowCount = isDone ? total : Number.MAX_VALUE;

  const [archive, archiveStatus] = useMutation<ArchiveCorrespondences, ArchiveCorrespondencesVariables>(ARCH_CORRS, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          correspondences(existingCorrs = [], { readField }) {
            if (!data) {
              return existingCorrs;
            }

            return existingCorrs.filter(
              (corrRef: Reference) => !data.archiveCorrespondences.includes(readField('id', corrRef) ?? '')
            );
          },
        },
      });
    },
  });

  useEffect(() => {
    // Reset when switching filter
    pageStateRef.current = { 0: null };
    setIsDone(false);
    setPage(0);
    setTotal(0);

    // Fetch
    refetch();
  }, [refetch, isArchived]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (msg: string | null, keepOpen: boolean) => {
    setActiveCorrespondence(null);
    setOpen(keepOpen);

    if (msg) {
      setSuccessOpen({ status: 'open', msg });
    }
  };

  const handleSnackbarClose = () => {
    setSuccessOpen({ status: 'closed' });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsArchived(event.target.checked);
  };

  const rows: GridRowsProp =
    data?.correspondences?.rows.map((corr) => ({
      id: corr.id,
      col1: corr.dateAdded,
      col2: corr.recipient,
      col3: corr.item,
      col4: corr.case ? getCaseName(corr.case.client.name, corr.case.group?.name || null, corr.case.matter) : '',
      col5: corr.asunto,
      col6: corr.typeOfCorrespondenceId ? CORR_TYPES[corr.typeOfCorrespondenceId] : '',
      col7: getValueOfType(corr),
    })) ?? [];

  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Fecha de Envío', width: 140, ...spDate },
    { field: 'col2', headerName: 'Dirigido a', width: 175, ...withTooltip },
    { field: 'col3', headerName: 'Correspondencia', width: 300, ...withTooltip },
    { field: 'col4', headerName: 'Caso Asociado', width: 300, ...withTooltip },
    { field: 'col5', headerName: 'Otro Asunto', width: 140, ...withTooltip },
    { field: 'col6', headerName: 'Tipo', width: 100, ...withTooltip },
    { field: 'col7', headerName: 'Rastreo/Email', width: 150, ...withTooltip },
  ];

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Registro de Correspondencia
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary" disableElevation onClick={handleClickOpen}>
            Crear
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={isArchived}
            onClick={() =>
              archive({
                variables: {
                  correspondenceIds: Array.from(selectedCorrespondencesRef.current),
                },
              })
            }
          >
            Archivar
          </Button>
        </Grid>
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={<Switch name="isArchived" color="primary" checked={isArchived} onChange={handleSwitchChange} />}
              label="Archivado?"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <DataGrid
        componentsProps={{
          pagination: {
            labelDisplayedRows: (display: { from: number; to: number; count: number }) =>
              `${display.from}-${display.to} de ${
                display.count === Number.MAX_VALUE ? 'muchos' : String(display.count)
              } `,
          },
        }}
        rows={rows}
        columns={columns}
        loading={loading}
        error={error}
        pageSize={50}
        page={page}
        rowsPerPageOptions={[50]}
        rowCount={rowCount}
        checkboxSelection
        autoHeight
        disableSelectionOnClick
        hideFooterRowCount={true}
        paginationMode="server"
        onPageChange={(newPage) => {
          if (!pageStateRef.current[newPage.page]) {
            pageStateRef.current[newPage.page] = data?.correspondences?.cursor ?? null;
          }

          setPage(newPage.page);
        }}
        onCellClick={(params, e) => {
          if (params.colIndex === 4) {
            const corr = data?.correspondences?.rows.find((corr) => corr.id === params.row.id);
            if (corr && corr.case) {
              e.preventDefault();
              e.stopPropagation();
              navigate(`/cases/${corr.case.id}`);
            }
          }
        }}
        onSelectionModelChange={(params) => {
          selectedCorrespondencesRef.current = new Set(params.selectionModel.map((id) => String(id)));
        }}
        onRowSelected={(params) => {
          if (params.isSelected) {
            selectedCorrespondencesRef.current.add(String(params.data.id));
          } else {
            selectedCorrespondencesRef.current.delete(String(params.data.id));
          }
        }}
        onRowClick={(params): void => {
          const corr = data?.correspondences?.rows.find((corr) => corr.id === params.row.id);
          if (corr) {
            setActiveCorrespondence(corr);
            setOpen(true);
          }
        }}
      />
      <CorrespondenceDialog caso={null} open={open} handleClose={handleClose} correspondence={activeCorrespondence} />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={successOpen.status === 'open'}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {successOpen.status === 'open' && successOpen.msg}
        </Alert>
      </Snackbar>
      <Backdrop open={archiveStatus.loading} />
    </>
  );
};
