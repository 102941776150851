import { Case_case_correspondences as Correspondence } from '../graph-types/Case';

export const CORR_TYPES: Readonly<Record<string, string>> = {
  1: 'Correo Regular',
  2: 'Priority',
  3: 'Email',
  4: 'Mensajero',
  5: 'A la Mano',
  6: 'SUMAC',
  7: 'Certificado con Acuse',
  8: 'DACO',
};

export const getValueOfType = (correspondence: Correspondence): string | null => {
  switch (correspondence.typeOfCorrespondenceId) {
    case 2:
      return correspondence.trackingNumber;
    case 7:
      return correspondence.trackingNumber;
    case 3:
      return correspondence.email;
    default:
      return 'n/a';
  }
};

export const getTracking = (correspondence: Correspondence): string | null => {
  switch (correspondence.typeOfCorrespondenceId) {
    case 2:
      return correspondence.trackingNumber;
    case 7:
      return correspondence.trackingNumber;
    case 3:
      return correspondence.email;
    default:
      return null;
  }
};
