import { gql } from '@apollo/client';

export const GET_RESOURCES = gql`
  query Resources {
    resources {
      id
      residentials {
        id
        administrator {
          id
          companyName
        }
        residential
        zipcode
        tribunal
        telContacto2
        telContacto1
        nombreAdministrador
        municipio
        email
        direccionPostalResidencial
        direccionFisicaResidencial
      }
      administrators {
        id
        companyName
        agente
        postalAddress1
        postalAddress2
        town
        country
        zipcode
      }
      causales {
        id
        initials
        causal
      }
      prefilledNotes {
        id
        note
      }
      flatFees {
        id
        name
        fee
        type
      }
      taskCodes {
        id
        code
        description
      }
      activityCodes {
        id
        code
        description
      }
      expenseCodes {
        id
        code
        description
      }
    }
  }
`;

export const ADD_ADMINISTRATOR = gql`
  mutation AddAdministrator($administrator: AdministratorInput!) {
    addAdministrator(administrator: $administrator) {
      id
    }
  }
`;

export const EDIT_ADMINISTRATOR = gql`
  mutation UpdateAdministrator($administrator: AdministratorInput!) {
    updateAdministrator(administrator: $administrator) {
      id
      companyName
      agente
      postalAddress1
      postalAddress2
      town
      country
      zipcode
    }
  }
`;

export const DEL_ADMINISTRATOR = gql`
  mutation DeleteAdministrator($administratorId: String!) {
    deleteAdministrator(id: $administratorId)
  }
`;

export const ADD_RESIDENTIAL = gql`
  mutation AddResidential($residential: ResidentialInput!) {
    addResidential(residential: $residential) {
      id
    }
  }
`;

export const EDIT_RESIDENTIAL = gql`
  mutation UpdateResidential($residential: ResidentialInput!) {
    updateResidential(residential: $residential) {
      id
      administrator {
        id
      }
      residential
      zipcode
      tribunal
      telContacto2
      telContacto1
      nombreAdministrador
      municipio
      email
      direccionPostalResidencial
      direccionFisicaResidencial
    }
  }
`;

export const DEL_RESIDENTIAL = gql`
  mutation DeleteResidential($residentialId: String!) {
    deleteResidential(id: $residentialId)
  }
`;

export const ADD_CAUSAL = gql`
  mutation AddCausal($causal: CausalInput!) {
    addCausal(causal: $causal) {
      id
    }
  }
`;

export const EDIT_CAUSAL = gql`
  mutation UpdateCausal($causal: CausalInput!) {
    updateCausal(causal: $causal) {
      id
      initials
      causal
    }
  }
`;

export const DEL_CAUSAL = gql`
  mutation DeleteCausal($causalId: String!) {
    deleteCausal(id: $causalId)
  }
`;

export const ADD_PREFILLEDNOTE = gql`
  mutation AddPrefilledNote($prefilledNote: PrefilledNoteInput!) {
    addPrefilledNote(prefilledNote: $prefilledNote) {
      id
    }
  }
`;

export const EDIT_PREFILLEDNOTE = gql`
  mutation UpdatePrefilledNote($prefilledNote: PrefilledNoteInput!) {
    updatePrefilledNote(prefilledNote: $prefilledNote) {
      id
      note
    }
  }
`;

export const DEL_PREFILLEDNOTE = gql`
  mutation DeletePrefilledNote($prefilledNoteId: String!) {
    deletePrefilledNote(id: $prefilledNoteId)
  }
`;

export const ADD_FLATFEE = gql`
  mutation AddFlatFee($flatFee: FlatFeeInput!) {
    addFlatFee(flatFee: $flatFee) {
      id
    }
  }
`;

export const EDIT_FLATFEE = gql`
  mutation UpdateFlatFee($flatFee: FlatFeeInput!) {
    updateFlatFee(flatFee: $flatFee) {
      id
      name
      fee
      type
    }
  }
`;

export const DEL_FLATFEE = gql`
  mutation DeleteFlatFee($flatFeeId: String!) {
    deleteFlatFee(id: $flatFeeId)
  }
`;

export const ADD_LEGALCODE = gql`
  mutation AddLegalCode($type: String!, $legalCode: LegalCodeInput!) {
    addLegalCode(type: $type, legalCode: $legalCode) {
      id
    }
  }
`;

export const EDIT_LEGALCODE = gql`
  mutation UpdateLegalCode($type: String!, $legalCode: LegalCodeInput!) {
    updateLegalCode(type: $type, legalCode: $legalCode) {
      id
      code
      description
    }
  }
`;

export const DEL_LEGALCODE = gql`
  mutation DeleteLegalCode($type: String!, $legalCodeId: String!) {
    deleteLegalCode(type: $type, id: $legalCodeId)
  }
`;
