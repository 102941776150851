import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { gql, useMutation, Reference, StoreObject, Cache } from '@apollo/client';
import { useForm, SubmitHandler, DefaultValues } from 'react-hook-form';

import { GroupInput } from '../graph-types/globalTypes';
import { AddGroup, AddGroupVariables, AddGroup_addGroup as Group } from '../graph-types/AddGroup';
import { UpdateGroup, UpdateGroupVariables } from '../graph-types/UpdateGroup';
import { DeleteGroup, DeleteGroupVariables } from '../graph-types/DeleteGroup';
import { ClientCases_clientCases as Client } from '../graph-types/ClientCases';

const ADD_GROUP = gql`
  mutation AddGroup($group: GroupInput!) {
    addGroup(group: $group) {
      id
      name
    }
  }
`;

const EDIT_GROUP = gql`
  mutation UpdateGroup($group: GroupInput!) {
    updateGroup(group: $group) {
      id
      name
    }
  }
`;

const DEL_GROUP = gql`
  mutation DeleteGroup($groupId: String!) {
    deleteGroup(id: $groupId)
  }
`;

type FormValues = {
  name: string;
};

const getGroupInput = (id: string, client: Client, data: FormValues): GroupInput => {
  return {
    id,
    clientId: client.id,
    ...data,
  };
};

type TypeOfSubmit = 'Save' | 'Delete';

const useGroupForm = (group: Group | null, client: Client, defaultValues: DefaultValues<FormValues>) => {
  const form = useForm<FormValues>({ defaultValues });

  const [deleteGroup] = useMutation<DeleteGroup, DeleteGroupVariables>(DEL_GROUP, {
    update: (cache, { data }) => {
      const modifier: Cache.ModifyOptions = {
        fields: {
          groups(existingGroups = [], { readField }) {
            if (!data) {
              return existingGroups;
            }

            return existingGroups.filter((groupRef: Reference) => data.deleteGroup !== readField('id', groupRef));
          },
        },
      };

      cache.modify(modifier);
    },
  });

  const [addGroup] = useMutation<AddGroup, AddGroupVariables>(ADD_GROUP, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((client as unknown) as StoreObject),
        fields: {
          groups(existingGroups = []) {
            if (!data) {
              return existingGroups;
            }

            const groupData = getGroupInput(data.addGroup.id, client, form.getValues());
            const newGroupRef = cache.writeFragment({
              data: {
                ...groupData,
                __typename: 'Group',
              },
              fragment: gql`
                fragment NewGroup on Group {
                  id
                  name
                }
              `,
            });
            return [...existingGroups, newGroupRef];
          },
        },
      });
    },
  });

  const [editGroup] = useMutation<UpdateGroup, UpdateGroupVariables>(EDIT_GROUP);

  const onSubmit = (
    typeOfSubmit: TypeOfSubmit,
    success: () => void,
    error: (e: unknown) => void
  ): SubmitHandler<FormValues> => async (data) => {
    try {
      switch (typeOfSubmit) {
        case 'Save': {
          if (group) {
            await editGroup({
              variables: {
                group: getGroupInput(group.id, client, data),
              },
            });
          } else {
            await addGroup({
              variables: {
                group: getGroupInput('', client, data),
              },
            });
          }
          break;
        }
        case 'Delete': {
          if (group) {
            await deleteGroup({ variables: { groupId: group.id } });
          }
          break;
        }
      }
      success();
    } catch (e) {
      error(e);
    }
  };

  return {
    onSubmit: (typeOfSubmit: TypeOfSubmit, success: () => void, error: (e: unknown) => void) =>
      form.handleSubmit(onSubmit(typeOfSubmit, success, error)),
    ...form,
  };
};

const getDefaultValues = (group: Group | null): DefaultValues<FormValues> => {
  const defaultValues: DefaultValues<FormValues> = {
    name: group?.name ?? '',
  };

  return defaultValues;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: 'auto',
    },
  })
);

export const GroupDialog: React.FC<{
  open: boolean;
  handleClose: (msg: string | null) => void;
  group: Group | null;
  client: Client;
}> = ({ open, handleClose, group, client }) => {
  const classes = useStyles();

  const { register, formState, onSubmit, reset } = useGroupForm(group, client, getDefaultValues(group));

  const { errors } = formState;

  useEffect(() => {
    reset(getDefaultValues(group));
  }, [reset, group]);

  const onClose = (): void => {
    handleClose(null);
    reset(getDefaultValues(null));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <form
        onSubmit={onSubmit(
          'Save',
          () => {
            handleClose('Grupo guardado!');
            reset(getDefaultValues(null));
          },
          (e) => {
            console.log(e);
          }
        )}
      >
        <DialogTitle id="form-dialog-title">Grupo para: {client.name}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="Nombre de Grupo"
            type="text"
            variant="outlined"
            inputProps={register('name', { required: true })}
            error={!!errors.name}
          />
        </DialogContent>
        <DialogActions>
          {group && (
            <Button
              classes={classes}
              onClick={() =>
                onSubmit(
                  'Delete',
                  () => {
                    handleClose('Grupo borrado!');
                  },
                  (e) => {
                    console.log(e);
                  }
                )()
              }
              color="secondary"
              disabled={formState.isSubmitting}
            >
              Borrar
            </Button>
          )}
          <Button onClick={onClose} color="primary" disabled={formState.isSubmitting}>
            Cerrar
          </Button>
          <Button type="submit" color="primary" disabled={formState.isSubmitting}>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
