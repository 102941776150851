import { Case_case as Case } from '../graph-types/Case';

export const getCaseName = (client: string, group: string | null | undefined, matter: string): string => {
  if (client && group) {
    return `${client}: ${group} Re: ${matter}`;
  }

  if (client) {
    return `${client} Re: ${matter}`;
  }

  return '';
};

export const tribunales = [
  { id: 1, name: 'Municipal' },
  { id: 2, name: 'Superior' },
  { id: 3, name: 'Apelativo' },
  { id: 4, name: 'Supremo' },
  { id: 5, name: 'DACO' },
  { id: 6, name: 'OMA' },
  { id: 7, name: 'Junta Reglamentadora' },
  { id: 8, name: 'Otro' },
];
export const representamosItems = [
  { id: 1, name: 'Demandado' },
  { id: 2, name: 'Demandante' },
  { id: 3, name: 'Querellado' },
  { id: 4, name: 'Querellante' },
  { id: 5, name: 'Otro' },
];

export const supportedCaseTypes = [
  { id: 1, name: 'Desahucios' },
  { id: 2, name: 'Laboral' },
  { id: 3, name: 'Daños y Perjuicios' },
  { id: 4, name: 'Cobro de Dinero' },
  { id: 6, name: 'DACO' },
  { id: 7, name: 'Notaria' },
  { id: 5, name: 'Otro' },
];

type DateField = { name: string; withTime: boolean };
export const dateFields: Record<string, DateField> = {
  verdictDate: { name: 'Sentencia', withTime: false },
  referralDate: { name: 'Referido', withTime: false },
  seguimientoDate: { name: 'Seguimiento', withTime: false },
  vencimientoDate: { name: 'Vencimiento', withTime: false },
  radicacionDemandaDate: { name: 'Radicación de Demanda', withTime: false },
  diligenciamentoDate: { name: 'Diligenciamento Expedido', withTime: false },
  reconsideracionDate: { name: 'Reconsideración', withTime: false },
  apelacionDate: { name: 'Primera Vista', withTime: false },
  expiracionContestacionADemandaDate: { name: 'Expiración de Contestación a Demanda', withTime: false },
  emplazadoDate: { name: 'Emplazado', withTime: false },
  completedDate: { name: 'Completado', withTime: false },
  ultimoReexamen: { name: 'Último Re-examen', withTime: false },
  lanzamiento: { name: 'Lanzamiento', withTime: false },
  juicioDate: { name: 'Juicio', withTime: true },
  primeraVistaDate: { name: 'Primera Vista', withTime: true },
  segundaVistaDate: { name: 'Segunda Vista', withTime: true },
  terceraVistaDate: { name: 'Tercera Vista', withTime: true },
  conferenciaAntelacionJuicioDate: { name: 'Conferencia Antelación a Juicio', withTime: true },
  conferenciaStatusDate: { name: 'Conferencia Status', withTime: true },
  deposicion: { name: 'Deposición', withTime: true },
  vistaInformeManejoCaso: { name: 'Vista de Informe de Manejo de Caso', withTime: true },
  levantarActa: { name: 'Levantar Acta', withTime: true },
  reunionAbogados: { name: 'Reunión con Abogados', withTime: true },
  reunionCliente: { name: 'Reunión con Cliente', withTime: true },
};

export const hasEvictionCaseType = (caso: Case): boolean => caso.typesOfCase.some((id) => id === 1);

export const hasCobroCaseType = (caso: Case): boolean => caso.typesOfCase.some((id) => id === 4);

export const isCaseForDemandante = (caso: Case): boolean => caso.typeOfRepresentationId === 2;
