import React, { useMemo, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';
import Container from '@material-ui/core/Container';
import { Router, LocationProvider } from '@gatsbyjs/reach-router';
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache, concat } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import 'date-fns';
import type firebase from 'firebase';

import { Navbar } from './navbar';
import { Login } from './login';
import { Dashboard } from './dashboard';
import { Correspondences } from './correspondences';
import { Case } from './case';
import { Cases } from './cases';
import { Invoices } from './invoices';
import { Resources } from './resources';
import { Profile } from './profile';

declare global {
  interface Window {
    firebase: typeof firebase;
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerRoot: {
      marginTop: theme.spacing(1) + 64,
    },
  })
);

const fetchCurrentUser = () =>
  new Promise<firebase.User | null>((resolve) => {
    const onAuth = window.firebase.auth().onAuthStateChanged((user) => {
      onAuth();
      resolve(user);
    });
  });

const getCurrentUser = () =>
  Promise.resolve(window.firebase.auth().currentUser).then((user) => user || fetchCurrentUser());

const createApolloClient = () => {
  const httpLink = new HttpLink({ uri: '/graphql' });

  const authMiddleware = setContext(async () => {
    const user = await getCurrentUser();

    if (!user) {
      return Promise.reject('no user');
    }

    return {
      headers: {
        authorization: `Bearer ${await user.getIdToken()}`,
      },
    };
  });

  return new ApolloClient({
    connectToDevTools: process.env.NODE_ENV !== 'production',
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache(),
  });
};

type User = {
  uid: string;
  displayName: string;
  email: string;
  role: string;
  org: string;
};

export const App: React.FC<{ user: User | null }> = (props) => {
  const [user, setUser] = useState(props.user);
  const isAdmin = user?.role === 'admin';
  const classes = useStyles();
  const theme = useMemo(
    () =>
      createMuiTheme(
        {
          typography: {
            fontFamily: ['Lato', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          },
          palette: {
            type: 'light',
            primary: { main: '#54828a' },
            secondary: { main: '#01adff' },
            info: { main: '#7da4ab' },
          },
        },
        esES
      ),
    []
  );

  const app = () => (
    <Router>
      <Dashboard path="/" isAdmin={isAdmin} />
      <Correspondences path="correspondences" />
      <Invoices path="invoicing" isAdmin={isAdmin} />
      <Cases path="cases" isAdmin={isAdmin} />
      <Case path="cases/:caseId/*" isAdmin={isAdmin} />
      <Resources path="resources/*" />
      <Profile path="profile" user={user} onLogout={() => setUser(null)} />
    </Router>
  );

  const main = user ? app() : <Login onLogin={(user) => setUser(user)} />;

  return (
    <React.StrictMode>
      <ApolloProvider client={createApolloClient()}>
        <CssBaseline>
          <ThemeProvider theme={theme}>
            <LocationProvider>
              <Navbar user={user} />
            </LocationProvider>
            <Container classes={{ root: classes.containerRoot }}>{main}</Container>
          </ThemeProvider>
        </CssBaseline>
      </ApolloProvider>
    </React.StrictMode>
  );
};
