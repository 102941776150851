import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import LaunchIcon from '@material-ui/icons/Launch';
import { DataGrid, GridRowsProp, GridColTypeDef, GridColDef, GridCellParams } from '@material-ui/data-grid';
import { Case_case as Case, Case_case_invoices as Invoice } from '../graph-types/Case';
import { InvoiceDialog } from './invoice-dialog';
import { getDateInUTC } from '../utils/dates';

type Props = {
  caso: Case;
  invoices: Invoice[];
};

const withTooltip: GridColTypeDef = {
  renderCell: (params: GridCellParams) => {
    return (
      <Tooltip interactive title={<Typography variant="subtitle1">{params.value || ''}</Typography>}>
        <Typography noWrap>{params.value}</Typography>
      </Tooltip>
    );
  },
};

const stopPropagation = (event: React.SyntheticEvent) => event.stopPropagation();
const launcher: GridColTypeDef = {
  renderCell: ({ value }) =>
    value ? (
      <Link
        onClick={stopPropagation}
        href={`https://app.qbo.intuit.com/app/timeactivity?t=s&id=${value}`}
        target="__blank"
      >
        <LaunchIcon />
      </Link>
    ) : (
      <></>
    ),
};

const spDate: GridColTypeDef = {
  type: 'string',
  valueFormatter: ({ value }) => (value ? new Intl.DateTimeFormat('es-ES').format(getDateInUTC(value as string)) : ''),
};

const bool: GridColTypeDef = {
  valueFormatter: ({ value }) => (value ? 'Sí' : 'No'),
};

export const CaseInvoices: React.FC<Props> = (props) => {
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const rows: GridRowsProp = props.invoices.map((inv) => ({
    id: inv.id,
    col1: inv.dateOfEvent,
    col2: inv.unit,
    col3: inv.flatFee.name,
    col4: inv.note,
    col5: inv.isExported,
    col6: inv.qbId,
    col7: inv.qbUpdate,
  }));

  const noteWidth = matches ? { flex: 1 } : { width: 500 };

  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Fecha de Evento', width: 140, ...spDate },
    { field: 'col2', headerName: 'Tiempo', width: 100 },
    { field: 'col3', headerName: 'Item', width: 125 },
    { field: 'col4', headerName: 'Nota', ...noteWidth, ...withTooltip },
    { field: 'col5', headerName: 'Archivado', width: 110, ...bool },
    { field: 'col6', headerName: 'QBO', width: 100, ...launcher },
    { field: 'col7', headerName: 'Actualizado QBO', width: 140, ...spDate },
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        onRowClick={(params): void => {
          const invoice = props.invoices.find((invoice) => invoice.id === params.row.id);
          setSelectedInvoice(invoice ?? null);
        }}
      />
      <InvoiceDialog
        invoice={selectedInvoice}
        caso={props.caso}
        open={selectedInvoice != null}
        handleClose={(msg) => setSelectedInvoice(null)}
      />
    </>
  );
};
