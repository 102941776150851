import React, { useEffect, useState } from 'react';
import { range, chunk } from 'lodash';
import clsx from 'clsx';
import Linkify from 'linkifyjs/react';
import { RouteComponentProps, Link as RouteLink } from '@gatsbyjs/reach-router';
import { useQuery, useLazyQuery } from '@apollo/client';
import { addDays, subDays, addMonths, subMonths, startOfMonth, endOfMonth, getDaysInMonth, format } from 'date-fns';
import es from 'date-fns/locale/es';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { getCaseName, supportedCaseTypes, dateFields } from '../data/case';
import {
  Dashboard as DashboardQuery,
  Dashboard_dashboard_today as DashboardCase,
  DashboardVariables,
} from '../graph-types/Dashboard';
import { DashboardMonth as DashboardMonthQuery, DashboardMonthVariables } from '../graph-types/DashboardMonth';
import { Notes as NotesQuery, NotesVariables } from '../graph-types/Notes';
import { DASHBOARD, DASHBOARD_MONTH, NOTES } from '../queries/dashboard';

type Props = RouteComponentProps<{ isAdmin: boolean }>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
      '&:last-child': {
        paddingBottom: theme.spacing(1),
      },
    },
    logo: {
      width: 50,
      height: 57,
      marginRight: theme.spacing(1),
    },
    dayTable: {
      marginBottom: theme.spacing(1),
    },
    sectionHeader: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.info.main,
    },
    sectionHeaderFont: {
      fontWeight: 'bolder',
      color: 'black',
    },
    tableHeader: {
      fontWeight: 'bolder',
    },
    tableDayPadding: {
      fontStyle: 'italic',
      color: theme.palette.text.secondary,
    },
    tableDay: {
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      fontWeight: 'bolder',
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    tableCell: {
      verticalAlign: 'top',
      padding: theme.spacing(2, 0),
      margin: theme.spacing(0, 2),
      minWidth: 100,
    },
    tableCaseCell: {
      padding: theme.spacing(1),
    },
    tableLoading: {
      position: 'absolute',
      zIndex: theme.zIndex.drawer - 1,
    },
    invoiceTotal: {
      height: theme.spacing(5),
      marginBottom: theme.spacing(1),
      color: theme.palette.success.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    drawerContainer: {
      backgroundColor: '#f3f5f6',
    },
    drawer: {
      width: 300,
    },
  })
);

const DayTable: React.FC<{ cases: DashboardCase[]; onSelect: (caso: DashboardCase) => void }> = (props) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} classes={{ root: classes.dayTable }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Partes</TableCell>
            <TableCell>Número de Caso</TableCell>
            <TableCell>Tipo de Caso</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.cases.length === 0 && (
            <TableRow>
              <TableCell align="center" colSpan={3}>
                Ningún caso
              </TableCell>
            </TableRow>
          )}
          {props.cases.map((dashboardCase) => (
            <TableRow key={dashboardCase.caso.id}>
              <TableCell>
                <Link
                  href="#"
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    props.onSelect(dashboardCase);
                  }}
                >
                  {getCaseName(
                    dashboardCase.caso.client.name,
                    dashboardCase.caso.group?.name,
                    dashboardCase.caso.matter
                  )}
                </Link>
              </TableCell>
              <TableCell>{dashboardCase.caso.caseNumber}</TableCell>
              <TableCell>
                {dashboardCase.caso.typesOfCase
                  .map((id) => supportedCaseTypes.find((tipo) => tipo.id === id)?.name ?? '')
                  .join(', ')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type DayMonth = { day: number; padding: boolean };
const daysInMonths = (today: Date, firstDay: Date, lastDay: Date): DayMonth[][] => {
  const prevMonth = subMonths(today, 1);
  const paddingDays = range(firstDay.getDay())
    .map((i) => {
      const prevLastDay = endOfMonth(prevMonth);
      return { day: subDays(prevLastDay, i).getDate(), padding: true };
    })
    .reverse();

  const days = range(getDaysInMonth(today)).map((i) => {
    return { day: i + 1, padding: false };
  });

  const nextMonth = addMonths(today, 1);
  const paddingEndDays = range(6 - lastDay.getDay()).map((i) => {
    const nextFirstDay = startOfMonth(nextMonth);
    return { day: addDays(nextFirstDay, i).getDate(), padding: true };
  });

  return chunk([...paddingDays, ...days, ...paddingEndDays], 7);
};

// To avoid double fetch
const today = new Date();
today.setHours(0, 0, 0);

export const Dashboard: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [month, setMonth] = useState(today);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCase, setSelectedCase] = useState<[DashboardCase, Date, boolean] | null>();

  const firstDay = startOfMonth(month);
  const lastDay = endOfMonth(month);

  const { data, loading } = useQuery<DashboardQuery, DashboardVariables>(DASHBOARD, {
    variables: { date: today.getTime().toString() },
  });

  const [loadMonth, monthData] = useLazyQuery<DashboardMonthQuery, DashboardMonthVariables>(DASHBOARD_MONTH);
  const [loadNotes, notesData] = useLazyQuery<NotesQuery, NotesVariables>(NOTES);

  const onSelectCase = (dashboardCase: DashboardCase, date: Date): void => {
    setSelectedCase([dashboardCase, date, false]);
    setDrawerOpen(true);
    loadNotes({ variables: { caseId: dashboardCase.caso.id, pageSize: 5 } });
  };

  useEffect(() => {
    if (!loading) {
      loadMonth({ variables: { date: month.getTime().toString() } });
    }
  }, [loading, month, loadMonth]);

  if (loading) {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (!data) {
    return null;
  }

  const { dashboard } = data;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent classes={{ root: classes.title }}>
              <img className={classes.logo} src={dashboard.org.logo} />
              <Typography variant="h4" component="h2">
                {dashboard.org.name}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {props.isAdmin && (
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Total facturado:
                </Typography>
                <Typography variant="h5" component="h2">
                  {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                    dashboard.invoiceSummary.totalFee
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} sm={props.isAdmin ? 4 : 6}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total horas facturadas:
              </Typography>
              <Typography variant="h5" component="h2">
                {new Intl.NumberFormat('en-US').format(dashboard.invoiceSummary.totalHours)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={props.isAdmin ? 4 : 6}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total gastos facturados:
              </Typography>
              <Typography variant="h5" component="h2">
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                  dashboard.invoiceSummary.totalGastos
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper classes={{ root: classes.sectionHeader }}>
            <Typography classes={{ root: classes.sectionHeaderFont }} variant="h5">
              Hoy
            </Typography>
          </Paper>
          <DayTable
            cases={dashboard.today}
            onSelect={(caso) => {
              onSelectCase(caso, today);
            }}
          />
          <Paper classes={{ root: classes.sectionHeader }}>
            <Typography classes={{ root: classes.sectionHeaderFont }} variant="h5">
              Mañana
            </Typography>
          </Paper>
          <DayTable
            cases={dashboard.tomorrow}
            onSelect={(caso) => {
              onSelectCase(caso, addDays(today, 1));
            }}
          />
          <Paper classes={{ root: classes.sectionHeader }}>
            <Typography classes={{ root: classes.sectionHeaderFont }} variant="h5">
              Próximos Siete Días
            </Typography>
          </Paper>
          <TableContainer component={Paper} classes={{ root: classes.dayTable }}>
            <Table>
              <TableHead>
                <TableRow>
                  {range(7).map((i) => (
                    <TableCell key={i} align="center" classes={{ root: classes.tableHeader }}>
                      {format(addDays(today, i + 2), 'd LLL', { locale: es })}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {range(7).map((i) => (
                    <TableCell key={i} align="center" className={classes.tableCell}>
                      {dashboard.nextWeek[i].length === 0 && 'Ningún caso'}
                      {dashboard.nextWeek[i].map((dashboardCase) => (
                        <div key={dashboardCase.caso.id} className={classes.tableCaseCell}>
                          <Link
                            href="#"
                            onClick={(e: React.SyntheticEvent) => {
                              e.preventDefault();
                              const dayOf = addDays(today, i + 2);
                              onSelectCase(dashboardCase, dayOf);
                            }}
                          >
                            {getCaseName(
                              dashboardCase.caso.client.name,
                              dashboardCase.caso.group?.name,
                              dashboardCase.caso.matter
                            )}
                          </Link>
                        </div>
                      ))}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Paper classes={{ root: classes.sectionHeader }}>
            <Typography classes={{ root: classes.sectionHeaderFont }} variant="h5">
              <Box display="flex" alignItems="center">
                <ArrowBackIcon
                  onClick={() => {
                    const newMonth = subMonths(month, 1);
                    setMonth(newMonth);
                    loadMonth({ variables: { date: newMonth.getTime().toString() } });
                  }}
                />
                {format(firstDay, 'MMMM yyyy', { locale: es })}
                <ArrowForwardIcon
                  onClick={() => {
                    const newMonth = addMonths(month, 1);
                    setMonth(newMonth);
                    loadMonth({ variables: { date: newMonth.getTime().toString() } });
                  }}
                />
              </Box>
            </Typography>
          </Paper>
          <TableContainer style={{ position: 'relative' }} component={Paper} classes={{ root: classes.dayTable }}>
            <Backdrop classes={{ root: classes.tableLoading }} open={monthData.loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableHeader }} align="center">
                    Domingo
                  </TableCell>
                  <TableCell classes={{ root: classes.tableHeader }} align="center">
                    Lunes
                  </TableCell>
                  <TableCell classes={{ root: classes.tableHeader }} align="center">
                    Martes
                  </TableCell>
                  <TableCell classes={{ root: classes.tableHeader }} align="center">
                    Miércoles
                  </TableCell>
                  <TableCell classes={{ root: classes.tableHeader }} align="center">
                    Jueves
                  </TableCell>
                  <TableCell classes={{ root: classes.tableHeader }} align="center">
                    Viernes
                  </TableCell>
                  <TableCell classes={{ root: classes.tableHeader }} align="center">
                    Sábado
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {daysInMonths(month, firstDay, lastDay).map((row, i) => (
                  <TableRow key={i}>
                    {row.map(({ day, padding }) => {
                      if (padding) {
                        return (
                          <TableCell classes={{ root: classes.tableCell }} align="center" key={day}>
                            <div className={clsx(classes.tableDay, classes.tableDayPadding)}>{day}</div>
                          </TableCell>
                        );
                      }

                      const monthDays = monthData.data?.dashboardMonth || [];
                      return (
                        <TableCell key={day} align="center" classes={{ root: classes.tableCell }}>
                          <div className={classes.tableDay}>
                            {format(addDays(firstDay, day - 1), 'd LLL', { locale: es })}
                          </div>
                          {monthDays[day - 1]?.length === 0 && 'Ningún caso'}
                          {monthDays[day - 1]?.map((dashboardCase) => (
                            <div key={dashboardCase.caso.id} className={classes.tableCaseCell}>
                              <Link
                                href="#"
                                onClick={(e: React.SyntheticEvent) => {
                                  e.preventDefault();
                                  const dayOf = addDays(firstDay, day - 1);
                                  onSelectCase(dashboardCase, dayOf);
                                }}
                              >
                                {getCaseName(
                                  dashboardCase.caso.client.name,
                                  dashboardCase.caso.group?.name,
                                  dashboardCase.caso.matter
                                )}
                              </Link>
                            </div>
                          ))}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Drawer
        classes={{ paper: classes.drawerContainer }}
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        {selectedCase && (
          <Box p={2} className={classes.drawer}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  <Link to={`cases/${selectedCase[0].caso.id}`} component={RouteLink}>
                    {getCaseName(
                      selectedCase[0].caso.client.name,
                      selectedCase[0].caso.group?.name,
                      selectedCase[0].caso.matter
                    )}
                  </Link>
                  <Divider />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Para fecha: {format(selectedCase[1], "d 'de' MMMM 'de' yyyy", { locale: es })}
                    </Typography>
                    <List dense>
                      {selectedCase[0].fields.map((field, i) => (
                        <ListItem key={i} disableGutters>
                          <ListItemText
                            primary={`${dateFields[field.name].name}${
                              dateFields[field.name].withTime
                                ? format(Number(field.date), " '@' hh:mm aaa", { locale: es })
                                : ''
                            } `}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Notas Recientes
                    </Typography>
                    <List dense>
                      {notesData.data?.notes.map((note) => (
                        <ListItem key={note.id} disableGutters>
                          <ListItemText
                            primary={<Linkify options={{ target: { url: '_blank' } }}>{note.note}</Linkify>}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
      </Drawer>
    </>
  );
};
