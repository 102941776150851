import React, { useState } from 'react';
import { RouteComponentProps, useMatch, useNavigate } from '@gatsbyjs/reach-router';
import { useQuery, useLazyQuery } from '@apollo/client';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Divider from '@material-ui/core/Divider';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MailIcon from '@material-ui/icons/Mail';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FormProvider } from 'react-hook-form';

import { useCaseForm } from '../hooks/use-case-form';

import { CaseGeneralForm } from './case-general-form';
import { CaseDemandadoForm } from './case-demandado-form';
import { CaseDateForm } from './case-date-form';
import { CaseEvictionForm } from './case-eviction-form';
import { CaseCobroForm } from './case-cobro-form';
import { CaseContactForm } from './case-contact-form';
import { CaseNotes } from './case-notes';
import { CaseCorrespondences } from './case-correspondences';
import { CaseInvoices } from './case-invoices';

import { CaseNoteDialog } from './case-note-dialog';
import { InvoiceDialog } from './invoice-dialog';
import { CorrespondenceDialog } from './correspondence-dialog';

import { getCaseName, hasCobroCaseType, hasEvictionCaseType, isCaseForDemandante } from '../data/case';
import { Case as CaseQuery, CaseVariables } from '../graph-types/Case';
import { DownloadCase, DownloadCaseVariables } from '../graph-types/DownloadCase';
import { DownloadCaseDemanda, DownloadCaseDemandaVariables } from '../graph-types/DownloadCaseDemanda';
import { GET_CASE, DOWNLOAD_CASE, DOWNLOAD_CASE_DEMANDA } from '../queries/case';
import { downloadContent } from '../utils/downloads';

type Props = RouteComponentProps<{
  caseId: string;
}> & {
  isAdmin: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: theme.spacing(1),
    },
    panel: {
      padding: 0,
    },
    tabs: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    topCard: {
      height: theme.spacing(5),
      marginBottom: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    invoiceTotal: {
      height: theme.spacing(5),
      marginBottom: theme.spacing(1),
      color: theme.palette.success.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      marginBottom: theme.spacing(12),
      padding: theme.spacing(1),
    },
    speedDial: {
      position: 'fixed',
      '&.MuiSpeedDial-directionUp': {
        bottom: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
  })
);

type Dialogs = 'note' | 'invoice' | 'correspondence' | 'none';

type TabValues =
  | 'general'
  | 'demandado'
  | 'dates'
  | 'eviction'
  | 'cobro'
  | 'contact'
  | 'notes'
  | 'correspondence'
  | 'invoices';

const useTabValue = (): TabValues => {
  const match = useMatch('/cases/:caseId/:section');
  if (!match) {
    return 'general';
  }

  return match.section as TabValues;
};

export const Case: React.FC<Props> = (props) => {
  const caseId = props.caseId ?? '';
  const section = useTabValue();
  const navigate = useNavigate();

  const { loading, data } = useQuery<CaseQuery, CaseVariables>(GET_CASE, {
    variables: { caseId },
  });
  const [downloadCase] = useLazyQuery<DownloadCase, DownloadCaseVariables>(DOWNLOAD_CASE, {
    fetchPolicy: 'no-cache',
    variables: { ids: [caseId] },
    onCompleted: (data) => {
      downloadContent(data.downloadCase);
    },
  });
  const [downloadCaseDemanda] = useLazyQuery<DownloadCaseDemanda, DownloadCaseDemandaVariables>(DOWNLOAD_CASE_DEMANDA, {
    fetchPolicy: 'no-cache',
    variables: { ids: [caseId] },
    onCompleted: (data) => {
      downloadContent(data.downloadCaseDemanda);
    },
  });

  const classes = useStyles();

  const { ...formMethods } = useCaseForm(data?.case);

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);
  const [notification, setNotification] = useState<{ status: 'success' | 'error' | 'closed'; msg: string }>({
    status: 'closed',
    msg: '',
  });

  const [dialog, setDialog] = useState<Dialogs>('none');

  if (loading) {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  const caso = data?.case;

  if (!caso) {
    return (
      <Typography variant="h5" gutterBottom>
        No existe!
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {getCaseName(caso.client.name, caso.group?.name || null, caso.matter)}
      </Typography>
      <Divider classes={{ root: classes.divider }} />
      <SpeedDial
        className={classes.speedDial}
        ariaLabel="Case Actions"
        icon={<SpeedDialIcon />}
        open={actionsOpen}
        onClose={(event, reason) => {
          if (reason === 'toggle') {
            setActionsOpen(false);
          }
        }}
        onOpen={(event, reason) => {
          if (reason === 'toggle') {
            setActionsOpen(true);
          }
        }}
        direction={'up'}
      >
        <SpeedDialAction
          key={'save'}
          icon={<SaveIcon />}
          tooltipTitle={'Guardar'}
          onClick={() =>
            formMethods.onSubmit('Save', (e) => {
              if (e) {
                setNotification({
                  status: 'error',
                  msg: 'Problema guardando caso',
                });
              } else {
                setNotification({
                  status: 'success',
                  msg: 'Caso guardado!',
                });
              }
            })()
          }
        />
        {props.isAdmin && (
          <SpeedDialAction
            key={'delete'}
            icon={<DeleteIcon />}
            tooltipTitle={'Borrar'}
            onClick={() => setDeleteConfirm(true)}
          />
        )}
        <SpeedDialAction
          key={'download'}
          icon={<CloudDownloadIcon />}
          tooltipTitle={'Bajar'}
          onClick={() => downloadCase()}
        />
        <SpeedDialAction
          key={'demanda'}
          icon={<AssignmentReturnedIcon />}
          tooltipTitle={'Demanda'}
          onClick={() => downloadCaseDemanda()}
        />
        <SpeedDialAction
          key={'invoice'}
          icon={<AttachMoneyIcon />}
          tooltipTitle={'Facturación'}
          onClick={() => setDialog('invoice')}
        />
        <SpeedDialAction key={'note'} icon={<NoteAddIcon />} tooltipTitle={'Nota'} onClick={() => setDialog('note')} />
        <SpeedDialAction
          key={'correspondence'}
          icon={<MailIcon />}
          tooltipTitle={'Correspondencia'}
          onClick={() => setDialog('correspondence')}
        />
      </SpeedDial>
      {(caso.isComplete || props.isAdmin) && (
        <Grid container spacing={4}>
          <>
            {caso.isComplete && caso.completedDate && (
              <Grid item xs={12} sm={6} md={4}>
                <Paper variant="outlined" classes={{ root: classes.topCard }}>
                  <Typography variant="h6">
                    {'Completado: '}
                    {new Intl.DateTimeFormat('es-ES').format(new Date(Number(caso.completedDate)))}
                  </Typography>
                </Paper>
              </Grid>
            )}
            {props.isAdmin && (
              <Grid item xs={12} sm={6} md={4}>
                <Paper variant="outlined" classes={{ root: classes.invoiceTotal }}>
                  <Typography variant="h5">
                    {'Total facturado: '}
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                      caso.invoices.reduce((total, invoice) => total + invoice.unit * invoice.flatFee.fee, 0)
                    )}
                    {}
                  </Typography>
                </Paper>
              </Grid>
            )}
            {caso.qbId && (
              <Grid item xs={12} sm={6} md={4}>
                <Paper variant="outlined" classes={{ root: classes.topCard }}>
                  <Typography variant="h6">
                    <Link href={`https://app.qbo.intuit.com/app/customerdetail?nameId=${caso.qbId}`} target="__blank">
                      QuickBooks Online
                    </Link>
                  </Typography>
                </Paper>
              </Grid>
            )}
            {caso.qbId && caso.qbUpdate && (
              <Grid item xs={12} sm={6} md={4}>
                <Paper variant="outlined" classes={{ root: classes.topCard }}>
                  <Typography variant="h6">
                    {'Actualizado en QBO: '}
                    {new Intl.DateTimeFormat('es-ES').format(new Date(Number(caso.qbUpdate)))}
                  </Typography>
                </Paper>
              </Grid>
            )}
          </>
        </Grid>
      )}
      <Paper square elevation={1}>
        <Tabs
          classes={{ root: classes.tabs }}
          value={section}
          variant="scrollable"
          onChange={(e, value) => navigate(`/cases/${props.caseId}/${value}`, { replace: true })}
        >
          <Tab label="General" value="general" />
          {isCaseForDemandante(caso) && <Tab label="Demandado" value="demandado" />}
          <Tab label="Fechas" value="dates" />
          {hasEvictionCaseType(caso) && <Tab label="Desahucio" value="eviction" />}
          {hasCobroCaseType(caso) && <Tab label="Cobro" value="cobro" />}
          <Tab label="Contacto" value="contact" />
          <Tab label="Notas" value="notes" />
          <Tab label="Correspondencia" value="correspondence" />
          <Tab label="Facturación" value="invoices" />
        </Tabs>
      </Paper>
      <FormProvider {...formMethods}>
        <Paper square className={classes.paper} elevation={1}>
          <TabContext value={section}>
            <TabPanel className={classes.panel} value="general">
              <CaseGeneralForm />
            </TabPanel>
            <TabPanel className={classes.panel} value="demandado">
              <CaseDemandadoForm />
            </TabPanel>
            <TabPanel className={classes.panel} value="dates">
              <CaseDateForm />
            </TabPanel>
            <TabPanel className={classes.panel} value="eviction">
              <CaseEvictionForm />
            </TabPanel>
            <TabPanel className={classes.panel} value="cobro">
              <CaseCobroForm />
            </TabPanel>
            <TabPanel className={classes.panel} value="contact">
              <CaseContactForm caso={caso} client={caso.client} />
            </TabPanel>
            <TabPanel className={classes.panel} value="notes">
              <CaseNotes caso={caso} notes={caso.notes} />
            </TabPanel>
            <TabPanel className={classes.panel} value="correspondence">
              <CaseCorrespondences caso={caso} correspondences={caso.correspondences} />
            </TabPanel>
            <TabPanel className={classes.panel} value="invoices">
              <CaseInvoices caso={caso} invoices={caso.invoices} />
            </TabPanel>
          </TabContext>
        </Paper>
      </FormProvider>
      <CaseNoteDialog note={null} caso={caso} open={dialog === 'note'} handleClose={(msg) => setDialog('none')} />
      <CorrespondenceDialog
        correspondence={null}
        caso={caso}
        open={dialog === 'correspondence'}
        handleClose={(msg, keepOpen) => {
          if (!keepOpen) {
            setDialog('none');
          }
        }}
      />
      <InvoiceDialog
        invoice={null}
        caso={caso}
        open={dialog === 'invoice'}
        handleClose={(msg, keepOpen) => {
          if (!keepOpen) {
            setDialog('none');
          }
        }}
      />
      <Dialog
        open={deleteConfirm}
        onClose={() => setDeleteConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirmar'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">¿Desea borrar el caso?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirm(false)} color="primary" autoFocus>
            No
          </Button>
          <Button
            onClick={() => {
              formMethods.onSubmit('Delete', (e) => {
                if (e) {
                  setNotification({
                    status: 'error',
                    msg: 'Problema borrando caso',
                  });
                } else {
                  window.location.href = '/';
                }
              })();
            }}
            color="primary"
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={notification.status === 'success'}
        autoHideDuration={2000}
        onClose={() => setNotification({ status: 'closed', msg: notification.msg })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setNotification({ status: 'closed', msg: notification.msg })}
          severity="success"
        >
          {notification.msg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={notification.status === 'error'}
        autoHideDuration={2000}
        onClose={() => setNotification({ status: 'closed', msg: notification.msg })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setNotification({ status: 'closed', msg: notification.msg })}
          severity="error"
        >
          {notification.msg}
        </Alert>
      </Snackbar>
    </>
  );
};
