import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { gql, useMutation, Reference, StoreObject } from '@apollo/client';
import { useForm, SubmitHandler, DefaultValues } from 'react-hook-form';

import { CaseNoteInput } from '../graph-types/globalTypes';
import { Case_case as Case, Case_case_notes as CaseNote } from '../graph-types/Case';
import { AddNote, AddNoteVariables } from '../graph-types/AddNote';
import { UpdateNote, UpdateNoteVariables } from '../graph-types/UpdateNote';
import { DeleteNote, DeleteNoteVariables } from '../graph-types/DeleteNote';

const ADD_NOTE = gql`
  mutation AddNote($note: CaseNoteInput!) {
    addCaseNote(caseNote: $note) {
      id
      note
      createdAt
      updatedAt
    }
  }
`;

const EDIT_NOTE = gql`
  mutation UpdateNote($note: CaseNoteInput!) {
    updateCaseNote(caseNote: $note) {
      id
      note
      createdAt
      updatedAt
    }
  }
`;

const DEL_NOTE = gql`
  mutation DeleteNote($note: String!) {
    deleteCaseNote(id: $note)
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: 'auto',
    },
  })
);

type FormValues = {
  note: string;
};

const getCaseNoteInput = (id: string, caseId: string, update: Date, create: Date, data: FormValues): CaseNoteInput => {
  return {
    id,
    note: data.note,
    caseId,
    updatedAt: String(update?.getTime() ?? ''),
    createdAt: String(create?.getTime() ?? ''),
  };
};

type TypeOfSubmit = 'Save' | 'Delete';

const useNoteForm = (note: CaseNote | null, caso: Case, defaultValues: DefaultValues<FormValues>) => {
  const form = useForm<FormValues>({ defaultValues });

  const [deleteNote] = useMutation<DeleteNote, DeleteNoteVariables>(DEL_NOTE, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((caso as unknown) as StoreObject),
        fields: {
          notes(existingNotes = [], { readField }) {
            if (!data) {
              return existingNotes;
            }

            return existingNotes.filter((noteRef: Reference) => data.deleteCaseNote !== readField('id', noteRef));
          },
        },
      });
    },
  });

  const [addNote] = useMutation<AddNote, AddNoteVariables>(ADD_NOTE, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify((caso as unknown) as StoreObject),
        fields: {
          notes(existingNotes = []) {
            if (!data) {
              return existingNotes;
            }

            const noteData = getCaseNoteInput(
              data.addCaseNote.id,
              caso.id,
              new Date(Number(data.addCaseNote.updatedAt)),
              new Date(Number(data.addCaseNote.createdAt)),
              form.getValues()
            );
            const newNoteRef = cache.writeFragment({
              data: {
                ...noteData,
                __typename: 'CaseNote',
              },
              fragment: gql`
                fragment NewNote on CaseNote {
                  id
                  note
                  updatedAt
                  createdAt
                }
              `,
            });
            return [...existingNotes, newNoteRef];
          },
        },
      });
    },
  });

  const [editNote] = useMutation<UpdateNote, UpdateNoteVariables>(EDIT_NOTE);

  const onSubmit = (typeOfSubmit: TypeOfSubmit, cb: () => void): SubmitHandler<FormValues> => async (data) => {
    switch (typeOfSubmit) {
      case 'Save': {
        if (note) {
          await editNote({
            variables: {
              note: getCaseNoteInput(note.id, caso.id, new Date(), new Date(Number(note.createdAt)), data),
            },
          });
        } else {
          await addNote({
            variables: {
              note: getCaseNoteInput('', caso.id, new Date(), new Date(), data),
            },
          });
        }
        break;
      }
      case 'Delete': {
        if (note) {
          await deleteNote({ variables: { note: note.id } });
        }
        break;
      }
    }

    cb();
  };

  return {
    onSubmit: (typeOfSubmit: TypeOfSubmit, cb: () => void) => form.handleSubmit(onSubmit(typeOfSubmit, cb)),
    ...form,
  };
};

const getDefaultValues = (note: CaseNote | null): DefaultValues<FormValues> => {
  const defaultValues: DefaultValues<FormValues> = {
    note: note?.note ?? '',
  };

  return defaultValues;
};

export const CaseNoteDialog: React.FC<{
  open: boolean;
  handleClose: (msg: string | null) => void;
  caso: Case;
  note: CaseNote | null;
}> = ({ open, handleClose, note, caso }) => {
  const classes = useStyles();

  const { register, formState, onSubmit, reset } = useNoteForm(note, caso, getDefaultValues(note));

  const { errors } = formState;

  useEffect(() => {
    reset(getDefaultValues(note));
  }, [reset, note]);

  const onClose = (): void => {
    handleClose(null);
    reset(getDefaultValues(null));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <form
        onSubmit={onSubmit('Save', () => {
          handleClose('Nota guardada!');
          reset(getDefaultValues(null));
        })}
      >
        <DialogTitle id="form-dialog-title">Crear Nota</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            multiline
            margin="dense"
            label="Nota"
            type="text"
            variant="outlined"
            inputProps={register('note', { required: true })}
            error={!!errors.note}
          />
        </DialogContent>
        <DialogActions>
          {note && (
            <Button
              classes={classes}
              onClick={() =>
                onSubmit('Delete', () => {
                  handleClose('Nota borrada!');
                  reset(getDefaultValues(null));
                })()
              }
              color="secondary"
              disabled={formState.isSubmitting}
            >
              Borrar
            </Button>
          )}
          <Button onClick={onClose} color="primary" disabled={formState.isSubmitting}>
            Cerrar
          </Button>
          <Button type="submit" color="primary" disabled={formState.isSubmitting}>
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
