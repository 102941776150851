import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { gql, useMutation, Reference, StoreObject, Cache } from '@apollo/client';
import { useForm, SubmitHandler, Controller, DefaultValues } from 'react-hook-form';

import { CorrespondenceInput } from '../graph-types/globalTypes';
import { AddCorrespondence, AddCorrespondenceVariables } from '../graph-types/AddCorrespondence';
import { UpdateCorrespondence, UpdateCorrespondenceVariables } from '../graph-types/UpdateCorrespondence';
import { DeleteCorrespondence, DeleteCorrespondenceVariables } from '../graph-types/DeleteCorrespondence';
import {
  Correspondences_correspondences_rows_case as Case,
  Correspondences_correspondences_rows as CorrespondenceWithCase,
} from '../graph-types/Correspondences';
import { CORR_TYPES, getTracking } from '../data/correspondence';
import { Case_case_correspondences as CorrespondenceNoCase } from '../graph-types/Case';
import { CaseAutocomplete } from './case-autocomplete';

type Correspondence = CorrespondenceNoCase | CorrespondenceWithCase;

const ADD_CORR = gql`
  mutation AddCorrespondence($correspondence: CorrespondenceInput!) {
    addCorrespondence(correspondence: $correspondence) {
      id
    }
  }
`;

const EDIT_CORR = gql`
  mutation UpdateCorrespondence($correspondence: CorrespondenceInput!) {
    updateCorrespondence(correspondence: $correspondence) {
      id
      recipient
      asunto
      item
      trackingNumber
      email
      dateAdded
      isArchived
      typeOfCorrespondenceId
      case {
        id
      }
    }
  }
`;

const DEL_CORR = gql`
  mutation DeleteCorrespondence($correspondenceId: String!) {
    deleteCorrespondence(id: $correspondenceId)
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: 'auto',
    },
  })
);

type FormValues = {
  recipient: string;
  dateAdded: Date;
  case: Case | null;
  asunto: string;
  item: string;
  typeOfCorrespondenceId: string;
  tracking: string;
  isArchived: boolean;
};

const getCorrData = (id: string, data: FormValues): CorrespondenceInput => {
  const type = Number(data.typeOfCorrespondenceId);
  const trackingNumber = [2, 7].includes(type) ? data.tracking : null;
  const email = 3 === type ? data.tracking : null;
  return {
    id,
    recipient: data.recipient,
    asunto: data.asunto,
    item: data.item,
    trackingNumber,
    email,
    dateAdded: String(data.dateAdded.getTime()),
    caseId: data.case?.id,
    typeOfCorrespondenceId: type,
    isArchived: data.isArchived,
  };
};

type TypeOfSubmit = 'Save' | 'Delete';

const useCorrForm = (
  correspondence: Correspondence | null,
  caso: Case | null,
  defaultValues: DefaultValues<FormValues>
) => {
  const form = useForm<FormValues>({ defaultValues });

  const [deleteCorr] = useMutation<DeleteCorrespondence, DeleteCorrespondenceVariables>(DEL_CORR, {
    update: (cache, { data }) => {
      const modifier: Cache.ModifyOptions = {
        fields: {
          correspondences(existingCorrs = [], { readField }) {
            console.log({ data, existingCorrs });
            if (!data) {
              return existingCorrs;
            }

            if (existingCorrs.rows) {
              return existingCorrs.rows.filter(
                (corrRef: Reference) => data.deleteCorrespondence !== readField('id', corrRef)
              );
            }

            return existingCorrs.filter((corrRef: Reference) => data.deleteCorrespondence !== readField('id', corrRef));
          },
        },
      };

      if (caso) {
        modifier.id = cache.identify((caso as unknown) as StoreObject);
      }

      cache.modify(modifier);
    },
  });

  const [addCorr] = useMutation<AddCorrespondence, AddCorrespondenceVariables>(ADD_CORR, {
    update: (cache, { data }) => {
      if (caso) {
        cache.modify({
          id: cache.identify((caso as unknown) as StoreObject),
          fields: {
            correspondences(existingCorrs = []) {
              if (!data) {
                return existingCorrs;
              }

              const corrData = getCorrData(data.addCorrespondence.id, form.getValues());
              const newCorrRef = cache.writeFragment({
                data: {
                  ...corrData,
                  __typename: 'Correspondence',
                },
                fragment: gql`
                  fragment NewCorr on Correspondence {
                    id
                    recipient
                    asunto
                    item
                    trackingNumber
                    email
                    dateAdded
                    isArchived
                    typeOfCorrespondenceId
                  }
                `,
              });
              return [...existingCorrs, newCorrRef];
            },
          },
        });
      } else {
        cache.modify({
          fields: {
            correspondences(existingCorrs = []) {
              if (!data) {
                return existingCorrs;
              }

              const corrData = getCorrData(data.addCorrespondence.id, form.getValues());
              const newCorrRef = cache.writeFragment({
                data: {
                  ...corrData,
                  case: {
                    id: corrData.caseId,
                  },
                  __typename: 'Correspondence',
                },
                fragment: gql`
                  fragment NewCorr on Correspondence {
                    id
                    recipient
                    asunto
                    item
                    trackingNumber
                    email
                    dateAdded
                    isArchived
                    typeOfCorrespondenceId
                    case {
                      id
                    }
                  }
                `,
              });
              return [...existingCorrs, newCorrRef];
            },
          },
        });
      }
    },
  });

  const [editCorr] = useMutation<UpdateCorrespondence, UpdateCorrespondenceVariables>(EDIT_CORR, {
    update: (cache, { data }) => {
      if (caso) {
        return;
      }

      cache.modify({
        fields: {
          correspondences(existingCorrs = [], { readField }) {
            if (!data) {
              return existingCorrs;
            }

            // If we changed isArchived it means its not part of this list
            if (data.updateCorrespondence.isArchived === correspondence?.isArchived) {
              return existingCorrs;
            }

            return existingCorrs.filter(
              (corrRef: Reference) => data.updateCorrespondence.id !== readField('id', corrRef)
            );
          },
        },
      });
    },
  });

  const onSubmit = (typeOfSubmit: TypeOfSubmit, cb: () => void): SubmitHandler<FormValues> => async (data) => {
    switch (typeOfSubmit) {
      case 'Save': {
        if (correspondence) {
          await editCorr({
            variables: {
              correspondence: getCorrData(correspondence.id, data),
            },
          });
        } else {
          await addCorr({
            variables: {
              correspondence: getCorrData('', data),
            },
          });
        }
        break;
      }
      case 'Delete': {
        if (correspondence) {
          await deleteCorr({ variables: { correspondenceId: correspondence.id } });
        }
        break;
      }
    }

    cb();
  };

  return {
    onSubmit: (typeOfSubmit: TypeOfSubmit, cb: () => void) => form.handleSubmit(onSubmit(typeOfSubmit, cb)),
    ...form,
  };
};

const getCase = (correspondence: Correspondence | null, caso: Case | null): Case | null => {
  if (caso) {
    return caso;
  }

  if (!correspondence) {
    return null;
  }

  if ('case' in correspondence) {
    return correspondence.case;
  }

  return null;
};

const getDefaultValues = (correspondence: Correspondence | null, caso: Case | null): DefaultValues<FormValues> => {
  const type = String(correspondence?.typeOfCorrespondenceId ?? '');

  const defaultValues: DefaultValues<FormValues> = {
    recipient: correspondence?.recipient ?? '',
    dateAdded: correspondence?.dateAdded ? new Date(Number(correspondence.dateAdded)) : new Date(),
    case: getCase(correspondence, caso),
    asunto: correspondence?.asunto ?? '',
    item: correspondence?.item ?? '',
    typeOfCorrespondenceId: type ?? '',
    tracking: correspondence ? getTracking(correspondence) ?? '' : '',
    isArchived: correspondence?.isArchived ?? false,
  };

  return defaultValues;
};

export const CorrespondenceDialog: React.FC<{
  open: boolean;
  handleClose: (msg: string | null, keepOpen: boolean) => void;
  correspondence: Correspondence | null;
  caso: Case | null;
}> = ({ open, handleClose, correspondence, caso }) => {
  const classes = useStyles();
  const [isKeepOpen, setKeepOpen] = useState(false);
  const type = String(correspondence?.typeOfCorrespondenceId ?? '');

  const { watch, control, register, formState, onSubmit, clearErrors, setError, reset } = useCorrForm(
    correspondence,
    caso,
    getDefaultValues(correspondence, caso)
  );

  const { errors } = formState;

  const watchCorrType = watch('typeOfCorrespondenceId', type);

  useEffect(() => {
    reset(getDefaultValues(correspondence, caso));
  }, [reset, correspondence, caso]);

  const onClose = (): void => {
    handleClose(null, false);
    reset(getDefaultValues(null, caso));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
        <form
          onSubmit={onSubmit('Save', () => {
            handleClose('Correspondencia guardada!', isKeepOpen);
            setKeepOpen(false);
          })}
        >
          <DialogTitle id="form-dialog-title">Crear Correspondencia</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="dirigido"
              label="Dirigido a"
              type="text"
              variant="outlined"
              inputProps={register('recipient', { required: true })}
              error={!!errors.recipient}
            />
            <Controller
              name="dateAdded"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { isDirty } }) => (
                <KeyboardDatePicker
                  fullWidth
                  margin="dense"
                  id="dateAdded"
                  label="Fecha de Envío"
                  inputVariant="outlined"
                  name="dateAdded"
                  format="dd/MM/yyyy"
                  InputLabelProps={{ shrink: true }}
                  onChange={(value) => {
                    field.onChange(value);
                    clearErrors('dateAdded');
                  }}
                  value={field.value ?? null}
                  invalidDateMessage="Formato de fecha incorrecto"
                  error={(formState.isSubmitted || isDirty) && !!errors.dateAdded}
                  onError={(error) => {
                    if (Boolean(error) && (!errors.dateAdded || errors.dateAdded.type !== 'datepicker')) {
                      setError('dateAdded', { type: 'datepicker' });
                    }
                  }}
                />
              )}
            />
            <TextField
              fullWidth
              margin="dense"
              id="asunto"
              label="Otro Asunto"
              type="text"
              variant="outlined"
              inputProps={register('asunto')}
              error={!!errors.asunto}
            />
            {!caso && (
              <Controller
                name="case"
                control={control}
                render={({ field }) => (
                  <CaseAutocomplete
                    value={field.value ?? null}
                    onSelect={(value) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
            )}
            <TextField
              fullWidth
              margin="dense"
              id="item"
              label="Correspondencia"
              type="text"
              variant="outlined"
              inputProps={register('item', { required: true })}
              error={!!errors.item}
            />
            <Controller
              name="typeOfCorrespondenceId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl margin="dense" variant="outlined" fullWidth>
                  <InputLabel id="typeCorr-label">Tipo</InputLabel>
                  <Select
                    labelId="typeCorr-label"
                    id="typeCorr"
                    value={field.value}
                    onChange={field.onChange}
                    label="Tipo"
                    error={!!errors.typeOfCorrespondenceId}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {Object.keys(CORR_TYPES).map((typeId) => (
                      <MenuItem key={typeId} value={typeId}>
                        {CORR_TYPES[typeId]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {['2', '7'].includes(watchCorrType) && (
              <TextField
                fullWidth
                margin="dense"
                id="tracking"
                label="Número de Rastreo"
                type="string"
                variant="outlined"
                name="tracking"
                inputProps={register('tracking')}
                error={!!errors.tracking}
              />
            )}
            {watchCorrType === '3' && (
              <TextField
                fullWidth
                margin="dense"
                id="tracking"
                label="Email"
                type="string"
                variant="outlined"
                inputProps={register('tracking')}
                error={!!errors.tracking}
              />
            )}
            <Controller
              name="isArchived"
              control={control}
              render={({ field }) => (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                    label="Archivado?"
                  />
                </FormGroup>
              )}
            />
          </DialogContent>
          <DialogActions>
            {correspondence && (
              <Button
                classes={classes}
                onClick={() =>
                  onSubmit('Delete', () => {
                    handleClose('Correspondencia borrada!', false);
                    setKeepOpen(false);
                  })()
                }
                color="secondary"
                disabled={formState.isSubmitting}
              >
                Borrar
              </Button>
            )}
            <Button onClick={onClose} color="primary" disabled={formState.isSubmitting}>
              Cerrar
            </Button>
            <Button
              type="submit"
              onClick={() => {
                setKeepOpen(true);
              }}
              color="primary"
              disabled={formState.isSubmitting}
            >
              Guardar y Añadir Otro
            </Button>
            <Button type="submit" color="primary" disabled={formState.isSubmitting}>
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};
