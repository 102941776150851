import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { RouteComponentProps, useMatch, useNavigate } from '@gatsbyjs/reach-router';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { gql, useQuery } from '@apollo/client';

const GET_PROFILE = gql`
  query Proile {
    profile {
      email
      role
    }
  }
`;

type Props = RouteComponentProps<{ 
  onLogout: () => void;
  user: Record<string, string> ;
}>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: theme.spacing(1),
    },
    panel: {
      padding: 0,
    },
    paper: {
      marginBottom: theme.spacing(12),
      padding: theme.spacing(1),
    },
  })
);

export const Profile: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  // if (loading) {
  //   return (
  //     <Grid container direction="row" justify="center" alignItems="center">
  //       <Grid item>
  //         <CircularProgress />
  //       </Grid>
  //     </Grid>
  //   );
  // }

  // if (!data) {
  //   return null;
  // }

  const handleSignOut = async () => {
    await window.firebase.auth().signOut();
    props.onLogout?.();
  }

  return (
    <>
      <Paper square className={classes.paper} elevation={1}>
        {props.user.email}
          <Button variant="contained" color="primary" disableElevation onClick={handleSignOut}>
            Sign Out
          </Button>

      </Paper>
    </>
  );
};
