import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { useForm, SubmitHandler, Controller, DefaultValues } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

type FormValues = {
  email: string;
  password: string;
};

const login = (email: string, password: string) => {
  return new Promise((resolve, reject) => {
    const db = window.firebase.firestore();
    const onAuth = window.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        onAuth();
        db.collection('users')
          .doc(user.uid)
          .get()
          .then((userDoc) => {
            const data = userDoc.data();
            resolve({
              uid: user.uid,
              displayName: user.displayName,
              email: user.email,
              role: data?.role,
              org: data?.org,
            });
          })
          .catch((e) => reject(e));
      }
    });

    window.firebase
      .auth()
      .setPersistence(window.firebase.auth.Auth.Persistence.LOCAL)
      .then(() => window.firebase.auth().signInWithEmailAndPassword(email, password))
      .catch((e) => {
        onAuth();
        return Promise.reject(e);
      });
  });
};

export const Login: React.FC<{ onLogin: (user: any) => void }> = (props) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { email: '', password: '' } });

  const onSubmit = async (data: FormValues) => {
    const user = await login(data.email, data.password);
    props.onLogin(user);
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={6}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar sesión
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              inputProps={register('email', { required: true })}
              error={!!errors.email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              inputProps={register('password', { required: true })}
              error={!!errors.password}
            />
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Entrar
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};
