import React, { useState } from 'react';
import { flatMap } from 'lodash';
import { gql } from '@apollo/client';
import { RouteComponentProps, useNavigate } from '@gatsbyjs/reach-router';
import { useQuery, useLazyQuery } from '@apollo/client';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { ClientDialog } from './client-dialog';
import { GroupDialog } from './group-dialog';
import { downloadContent } from '../utils/downloads';

import { DOWNLOAD_CASE, DOWNLOAD_CASE_DEMANDA } from '../queries/case';
import {
  ClientCases,
  ClientCases_clientCases as Client,
  ClientCases_clientCases_case as Case,
  ClientCases_clientCases_group as Group,
  ClientCasesVariables,
} from '../graph-types/ClientCases';
import { DownloadCase, DownloadCaseVariables } from '../graph-types/DownloadCase';
import { DownloadCaseDemanda, DownloadCaseDemandaVariables } from '../graph-types/DownloadCaseDemanda';

type Props = RouteComponentProps & {
  isAdmin: boolean;
};

const GET_CLIENT_CASE = gql`
  query ClientCases($isComplete: Boolean!) {
    clientCases(isComplete: $isComplete) {
      id
      name
      nombreContacto
      telefonoContacto
      emailContacto
      direccionFisicaContacto
      direccionPostalContacto
      nombreContactoCuentasAPagar
      telefonoContactoCuentasAPagar
      emailContactoCuentasAPagar

      group {
        id
        name
      }

      case(isComplete: $isComplete) {
        id
        caseNumber
        matter
        group {
          id
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(10),
    },
    pointer: {
      cursor: 'pointer',
    },
    clientHeader: {
      paddingLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.info.main,
      color: 'black',
    },
    list: {
      padding: 0,
      backgroundColor: theme.palette.background.paper,
    },
    groupText: {
      fontWeight: 'bold',
    },
    group: {
      backgroundColor: theme.palette.background.default,
    },
    rowInset: {
      paddingLeft: theme.spacing(2),
    },
    emptyRow: {
      textAlign: 'center',
      fontStyle: 'italic',
    },
    speedDial: {
      position: 'fixed',
      '&.MuiSpeedDial-directionUp': {
        bottom: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
  })
);

const CaseRow: React.FC<{
  caso: Case;
  nested: boolean;
  checked: string[];
  handleToggle: (id: string) => () => void;
}> = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <ListItem key={props.caso.id} button onClick={props.handleToggle(props.caso.id)}>
      <ListItemIcon classes={{ root: props.nested ? classes.rowInset : '' }}>
        <Checkbox edge="start" disableRipple checked={props.checked.includes(props.caso.id)} />
      </ListItemIcon>
      <ListItemText classes={{ inset: classes.rowInset }} inset={props.nested} primary={props.caso.matter} />
      <ListItemSecondaryAction
        onClick={() => {
          navigate(`/cases/${props.caso.id}`);
        }}
      >
        <IconButton edge="end">
          <LaunchIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const CasesList: React.FC<{
  casos: Case[];
  groups: Group[];
  checked: string[];
  handleToggle: (id: string) => () => void;
  onGroupClick: (group: Group) => void;
}> = (props) => {
  const classes = useStyles();
  const casosWithGroup = props.casos.filter((caso) => !!caso.group?.id);
  const casosWithoutGroup = props.casos.filter((caso) => !caso.group?.id);
  const [openedGroups, setOpenedGroups] = useState<string[]>([]);

  const groups = props.groups.map((group) => {
    const open = openedGroups.includes(group.name);
    const casos = casosWithGroup.filter((caso) => caso.group?.id === group.id);

    const groupName = (
      <Box display="flex">
        <Box display="inline">{group.name}</Box>
        <Box className={classes.pointer} display="flex" alignItems="center" ml={1}>
          <EditIcon
            fontSize="small"
            onClick={(e) => {
              e.stopPropagation();
              props.onGroupClick(group);
            }}
          />
        </Box>
      </Box>
    );
    return (
      <React.Fragment key={group.name}>
        <ListItem
          button
          classes={{ root: classes.group }}
          onClick={() => {
            if (open) {
              setOpenedGroups(openedGroups.filter((key) => key !== group.name));
            } else {
              setOpenedGroups(openedGroups.concat([group.name]));
            }
          }}
        >
          <ListItemText primaryTypographyProps={{ classes: { root: classes.groupText } }} primary={groupName} />
          {casos.length}
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List classes={{ root: classes.list }}>
            {casos.map((caso) => (
              <CaseRow key={caso.id} caso={caso} nested checked={props.checked} handleToggle={props.handleToggle} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  });

  const casos = casosWithoutGroup.map((caso) => (
    <CaseRow key={caso.id} caso={caso} nested={false} checked={props.checked} handleToggle={props.handleToggle} />
  ));

  if (props.casos.length === 0) {
    return (
      <ListItem button>
        <ListItemText classes={{ root: classes.emptyRow }} primary="Ningún Caso" />
      </ListItem>
    );
  }

  return (
    <List classes={{ root: classes.list }}>
      {groups}
      {casos}
    </List>
  );
};

export const Cases: React.FC<Props> = () => {
  const classes = useStyles();
  const [isCompleted, setIsCompleted] = useState(false);
  const [groupFormOpen, setGroupForm] = useState(false);
  const [activeGroup, setActiveGroup] = useState<[Client, Group | null] | null>(null);
  const [clientFormOpen, setClientForm] = useState(false);
  const [activeClient, setActiveClient] = useState<Client | null>(null);
  const [filterClientName, setClientName] = useState('');
  const [checked, setChecked] = useState<string[]>([]);
  const [actionsOpen, setActionsOpen] = useState(false);
  const [notification, setNotification] = useState<{ status: 'success' | 'error' | 'closed'; msg: string }>({
    status: 'closed',
    msg: '',
  });

  const { loading, data } = useQuery<ClientCases, ClientCasesVariables>(GET_CLIENT_CASE, {
    variables: { isComplete: isCompleted },
  });

  const [downloadCase] = useLazyQuery<DownloadCase, DownloadCaseVariables>(DOWNLOAD_CASE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      downloadContent(data.downloadCase);
    },
  });
  const [downloadCaseDemanda] = useLazyQuery<DownloadCaseDemanda, DownloadCaseDemandaVariables>(DOWNLOAD_CASE_DEMANDA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      downloadContent(data.downloadCaseDemanda);
    },
  });

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const clients = data?.clientCases ?? [];

  const filteredClients = clients.filter(({ name }) => new RegExp(filterClientName, 'i').test(name));

  const toggleAll = () => {
    setChecked(flatMap(filteredClients, (client) => client.case.map((caso) => caso.id)));
  };

  const untoggleAll = () => {
    setChecked([]);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3" gutterBottom>
        Clientes & Casos
      </Typography>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <ButtonGroup variant="contained" color="primary">
            <Button variant="contained" color="primary" disableElevation onClick={toggleAll}>
              <CheckBoxIcon />
            </Button>
            <Button variant="contained" color="primary" disableElevation onClick={untoggleAll}>
              <CheckBoxOutlineBlankIcon />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item></Grid>
        <Grid item>
          <FormGroup row>
            <TextField
              margin="dense"
              label="Filtrar Cliente"
              type="text"
              variant="outlined"
              value={filterClientName}
              onChange={(e) => {
                setChecked([]);
                setClientName(e.target.value);
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  name="isCompleted"
                  color="primary"
                  checked={isCompleted}
                  onChange={(event) => setIsCompleted(event.target.checked)}
                />
              }
              label="Completado?"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <SpeedDial
        className={classes.speedDial}
        ariaLabel="Cases Actions"
        icon={<SpeedDialIcon />}
        open={actionsOpen}
        onClose={(event, reason) => {
          if (reason === 'toggle') {
            setActionsOpen(false);
          }
        }}
        onOpen={(event, reason) => {
          if (reason === 'toggle') {
            setActionsOpen(true);
          }
        }}
        direction={'up'}
      >
        <SpeedDialAction
          key={'download'}
          icon={<CloudDownloadIcon />}
          tooltipTitle={'Bajar'}
          onClick={() => downloadCase({ variables: { ids: checked } })}
        />
        <SpeedDialAction
          key={'demanda'}
          icon={<AssignmentReturnedIcon />}
          tooltipTitle={'Demanda'}
          onClick={() => downloadCaseDemanda({ variables: { ids: checked } })}
        />
        <SpeedDialAction
          key={'client'}
          icon={<PersonAddIcon />}
          tooltipTitle={'Crear Cliente'}
          onClick={() => {
            setClientForm(true);
          }}
        />
      </SpeedDial>
      {loading && (
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!loading &&
        filteredClients.map((client) => (
          <React.Fragment key={client.id}>
            <Typography variant="h6" classes={{ root: classes.clientHeader }}>
              <Box display="flex" alignItems="center">
                <Box className={classes.pointer}>{client.name}</Box>
                <Box className={classes.pointer} display="flex" alignItems="center" ml={1}>
                  <EditIcon
                    fontSize="small"
                    onClick={() => {
                      setActiveClient(client);
                      setClientForm(true);
                    }}
                  />
                </Box>
                <Box className={classes.pointer} display="flex" alignItems="center" ml="auto" mr={1}>
                  <AddCircleIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveGroup([client, null]);
                      setGroupForm(true);
                    }}
                  />
                </Box>
              </Box>
            </Typography>
            <CasesList
              casos={client.case}
              groups={client.group}
              checked={checked}
              handleToggle={handleToggle}
              onGroupClick={(group) => {
                setActiveGroup([client, group]);
                setGroupForm(true);
              }}
            />
          </React.Fragment>
        ))}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={notification.status === 'success'}
        autoHideDuration={2000}
        onClose={() => setNotification({ status: 'closed', msg: notification.msg })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setNotification({ status: 'closed', msg: notification.msg })}
          severity="success"
        >
          {notification.msg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={notification.status === 'error'}
        autoHideDuration={2000}
        onClose={() => setNotification({ status: 'closed', msg: notification.msg })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setNotification({ status: 'closed', msg: notification.msg })}
          severity="error"
        >
          {notification.msg}
        </Alert>
      </Snackbar>
      <ClientDialog
        open={clientFormOpen}
        client={activeClient}
        caso={null}
        handleClose={() => {
          setActiveClient(null);
          setClientForm(false);
        }}
      />
      {activeGroup && (
        <GroupDialog
          open={groupFormOpen}
          group={activeGroup[1]}
          client={activeGroup[0]}
          handleClose={() => {
            setActiveClient(null);
            setActiveGroup(null);
            setGroupForm(false);
          }}
        />
      )}
    </div>
  );
};
