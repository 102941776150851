import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import EditIcon from '@material-ui/icons/Edit';
import { useFormContext } from 'react-hook-form';

import { Case_case as Case, Case_case_client as Client } from '../graph-types/Case';
import { ClientDialog } from './client-dialog';

type Props = {
  caso: Case;
  client: Client;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: theme.spacing(1),
    },
    clientLabel: {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    pointer: {
      cursor: 'pointer',
    },
  })
);

export const CaseContactForm: React.FC<Props> = (props) => {
  const { register } = useFormContext();
  const classes = useStyles();

  const [clientFormOpen, setClientForm] = useState(false);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" classes={{ root: classes.clientLabel }}>
            Cliente
          </Typography>
          <Typography variant="h5">
            {props.client.name}
            <Box className={classes.pointer} display="inline" ml={1}>
              <EditIcon
                fontSize="small"
                onClick={() => {
                  setClientForm(true);
                }}
              />
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" classes={{ root: classes.clientLabel }}>
            Contacto
          </Typography>
          {props.client.nombreContacto}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" classes={{ root: classes.clientLabel }}>
            Teléfono Contacto
          </Typography>
          {props.client.telefonoContacto}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" classes={{ root: classes.clientLabel }}>
            Email Contacto
          </Typography>
          {props.client.emailContacto}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" classes={{ root: classes.clientLabel }}>
            Dirección Fisica Contacto
          </Typography>
          {props.client.direccionFisicaContacto}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" classes={{ root: classes.clientLabel }}>
            Dirección Postal Contacto
          </Typography>
          {props.client.direccionPostalContacto}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" classes={{ root: classes.clientLabel }}>
            Contacto de Cuentas a Pagar
          </Typography>
          {props.client.nombreContactoCuentasAPagar}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" classes={{ root: classes.clientLabel }}>
            Teléfono
          </Typography>
          {props.client.telefonoContactoCuentasAPagar}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" classes={{ root: classes.clientLabel }}>
            Email
          </Typography>
          {props.client.emailContactoCuentasAPagar}
        </Grid>
        {props.client.qbId && (
          <Grid item xs={12} sm={props.client.qbUpdate ? 6 : 12}>
            <Typography variant="subtitle1" classes={{ root: classes.clientLabel }}>
              <Link href={`https://app.qbo.intuit.com/app/customerdetail?nameId=${props.client.qbId}`} target="__blank">
                QuickBooks Online
              </Link>
            </Typography>
          </Grid>
        )}
        {props.client.qbId && props.client.qbUpdate && (
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" classes={{ root: classes.clientLabel }}>
              Actualizado en QBO:
            </Typography>
            {new Intl.DateTimeFormat('es-ES').format(new Date(Number(props.client.qbUpdate)))}
          </Grid>
        )}
        <Grid item xs={12} sm={4}>
          <TextField
            inputProps={register('nombreContacto2')}
            label="Segundo Contacto"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            inputProps={register('telefonoContacto2')}
            label="Teléfono Segundo Contacto"
            margin="dense"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            inputProps={register('emailContacto2')}
            label="Email Segundo Contacto"
            margin="dense"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            inputProps={register('otroAbogadoName')}
            label="Abogado Otra Parte"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            inputProps={register('otroAbogadoTelefono')}
            label="Abogado Otra Parte Teléfono"
            margin="dense"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            inputProps={register('otroAbogadoEmail')}
            label="Abogado Otra Parte Email"
            margin="dense"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={register('otroAbogadoDireccion')}
            label="Abogado Otra Parte Dirección"
            margin="dense"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
          />
        </Grid>
      </Grid>
      <ClientDialog
        open={clientFormOpen}
        caso={props.caso}
        client={props.client}
        handleClose={() => {
          setClientForm(false);
        }}
      />
    </>
  );
};
